import { Decimal } from 'decimal.js';
import { dateUtils } from './dateUtils';

/**
 * Helper to safely parse interest rate
 * @param rate Interest rate as string or number
 * @returns Decimal representation of interest rate
 */
const parseInterestRate = (rate: string | number): Decimal => {
  try {
    const parsed = typeof rate === 'string' ? parseFloat(rate) : rate;
    if (isNaN(parsed)) {
      return new Decimal(0);
    }
    return new Decimal(parsed);
  } catch (error) {
    // Silently handle the error and return 0
    return new Decimal(0);
  }
};

/**
 * Calculate repayment details for a loan item using compound interest formula A = P(1+r/n)^(nt)
 * Where n=1 (annual compounding) as specified, simplifying to A = P(1+r)^t
 *
 * @param item Loan item with issue date, amount, and interest rate
 * @param repaymentDate Date of repayment
 * @param paymentType Type of payment (full, custom, interest_only)
 * @param customAmount Custom amount for custom payments
 * @returns Object containing days, interest, total amount, and remaining principal
 */
export const calculateRepaymentItem = (
  itemInput: {
    issueDate: string | Date;
    oldRemainingPrincipal: number;   // The current remaining principal before this payment
    monthlyInterestPercent: string | number;
    newRemainingPrincipal?: number;  // Field for tracking the new remaining principal after this payment
    lastCompoundDate?: Date;         // Field for tracking compound interest
    lastPaymentDate?: string | Date | null; // Field for tracking last payment date
  },
  repaymentDate: Date,
  paymentType: 'full' | 'custom' | 'interest_only' = 'full',
  customAmount?: number              // Amount to pay for custom payment
) => {
  try {
    // Create a copy of the input item
    const item = { ...itemInput };
    
    // Ensure oldRemainingPrincipal is defined
    if (item.oldRemainingPrincipal === undefined || item.oldRemainingPrincipal === null) {
      console.error('[ERROR] Missing oldRemainingPrincipal');
      item.oldRemainingPrincipal = 0; // Set default to prevent calculation errors
    }
    

    const issueDate = new Date(item.issueDate);
    
    // Validate dates
    if (isNaN(issueDate.getTime())) {
      throw new Error(`Invalid issue date: ${item.issueDate}`);
    }
    
    if (isNaN(repaymentDate.getTime())) {
      throw new Error(`Invalid repayment date: ${repaymentDate}`);
    }
    
    // Ensure repayment date is not before issue date
    if (repaymentDate < issueDate) {
      return {
        daysDifference: 0,
        interest: 0,
        amountPayable: item.oldRemainingPrincipal,
        amountPaid: item.oldRemainingPrincipal,
        newRemainingPrincipal: 0,
        lastCompoundDate: repaymentDate
      };
    }
    
    // Parse the lastPaymentDate if it exists
    let lastPaymentDate: Date | null = null;
    if (item.lastPaymentDate) {
      lastPaymentDate = typeof item.lastPaymentDate === 'string' 
        ? new Date(item.lastPaymentDate) 
        : item.lastPaymentDate;
      
      // Validate lastPaymentDate
      if (isNaN(lastPaymentDate.getTime())) {
        lastPaymentDate = null;
      }
    }
    
    // Use the most recent date for interest calculation reference:
    // Priority: 1. lastCompoundDate, 2. lastPaymentDate, 3. issueDate
    // This is critical for correct interest calculation after partial payments
    const referenceDate = item.lastCompoundDate || lastPaymentDate || issueDate;
    
    // For display and reference, calculate days between dates
    const daysDifference = dateUtils.daysBetween(referenceDate, repaymentDate);
    // Get initial principal from the old remaining principal value
    const initialPrincipal = item.newRemainingPrincipal !== undefined ?
      new Decimal(item.newRemainingPrincipal) : new Decimal(item.oldRemainingPrincipal || 0);
    
    // Calculate time in years between reference date and repayment date
    const timeInYears = dateUtils.calculateTimeInYears(referenceDate, repaymentDate);
    
    // Convert monthly interest rate to annual rate as decimal
    const monthlyRate = parseInterestRate(item.monthlyInterestPercent);
    const annualRate = monthlyRate.times(12).dividedBy(100);
    
    // Apply compound interest formula: A = P(1+r)^t
    // Where: P = principal, r = annual rate (decimal), t = time in years, n = 1 (annual compounding)
    const rPlusPower = new Decimal(1).plus(annualRate).pow(timeInYears);
    
    const finalAmount = initialPrincipal.times(rPlusPower);
    
    // Interest is the difference between final amount and initial principal
    const interest = finalAmount.minus(initialPrincipal).toDecimalPlaces(2);
    
    // Calculate payment based on payment type
    let amountPaid: Decimal;
    let remainingPrincipal: Decimal;
    // Declare the variable for tracking the resulting remaining principal
    let resultingRemainingPrincipal: Decimal;
    
    switch (paymentType) {
      case 'full':
        // Pay the full amount (principal + interest)
        amountPaid = finalAmount;
        resultingRemainingPrincipal = new Decimal(0);
        break;
        
      case 'interest_only':
        // Pay only the interest
        amountPaid = interest;
        resultingRemainingPrincipal = initialPrincipal;
        break;
        
      case 'custom':
        // Pay a custom amount, interest first then principal
        if (customAmount !== undefined && !isNaN(customAmount)) {
          const paymentAmount = new Decimal(customAmount);
          
          if (paymentAmount.lessThanOrEqualTo(interest)) {
            // Can't even cover the interest
            amountPaid = paymentAmount;
            
            // Negative amortization: add unpaid interest to principal
            const unpaidInterest = interest.minus(paymentAmount);
            resultingRemainingPrincipal = initialPrincipal.plus(unpaidInterest);
          } else {
            // Pay interest first, then principal
            const remainingPayment = paymentAmount.minus(interest);
            amountPaid = paymentAmount;
            
            // If payment covers all interest and some principal
            resultingRemainingPrincipal = initialPrincipal.minus(remainingPayment);
            
            // Ensure remaining principal doesn't go below zero
            if (resultingRemainingPrincipal.isNegative()) {
              resultingRemainingPrincipal = new Decimal(0);
            }
          }
        } else {
          // Invalid custom amount, default to full payment
          amountPaid = finalAmount;
          resultingRemainingPrincipal = new Decimal(0);
        }
        break;
        
      default:
        // Default to full payment
        amountPaid = finalAmount;
        resultingRemainingPrincipal = new Decimal(0);
    }
    
    return {
      daysDifference,
      interest: interest.toNumber(),
      // For interest-only payments, amountPayable should equal interest
      amountPayable: paymentType === 'interest_only' ? interest.toNumber() : finalAmount.toNumber(),
      amountPaid: amountPaid.toNumber(),
      newRemainingPrincipal: resultingRemainingPrincipal.toNumber(),
      lastCompoundDate: repaymentDate // Always update to current payment date
    };
  } catch (error) {
    console.error('Calculation error:', error);
    // Return safe defaults on error
    return {
      daysDifference: 0,
      interest: 0,
      amountPayable: typeof itemInput.oldRemainingPrincipal === 'number' ? itemInput.oldRemainingPrincipal : 0,
      amountPaid: typeof itemInput.oldRemainingPrincipal === 'number' ? itemInput.oldRemainingPrincipal : 0,
      newRemainingPrincipal: 0,
      lastCompoundDate: new Date()
    };
  }
};

/**
 * Calculate the total repayment amount from multiple items
 * @param items Array of repayment items
 * @returns Object with total principal, interest, grand total, amount paid, and remaining principal
 */
export const calculateRepaymentTotal = (items: Array<{
  oldRemainingPrincipal: number;  // The current remaining principal before this payment
  interest: number;
  amountPayable: number;
  amountPaid?: number;
  newRemainingPrincipal?: number;  // The resulting remaining principal after payment
}>) => {
  return items.reduce(
    (acc, item) => ({
      totalPrincipal: new Decimal(acc.totalPrincipal).plus(item.oldRemainingPrincipal).toNumber(),
      totalInterest: new Decimal(acc.totalInterest).plus(item.interest).toNumber(),
      grandTotal: new Decimal(acc.grandTotal).plus(item.amountPayable).toNumber(),
      totalPaid: new Decimal(acc.totalPaid).plus(item.amountPaid || item.amountPayable).toNumber(),
      totalRemaining: new Decimal(acc.totalRemaining).plus(item.newRemainingPrincipal || 0).toNumber(),
    }),
    {
      totalPrincipal: 0,
      totalInterest: 0,
      grandTotal: 0,
      totalPaid: 0,
      totalRemaining: 0
    }
  );
};

/**
 * Helper function to check if significant time has passed since last calculation
 * With annual compounding (n=1), we're always using the compound interest formula,
 * but this function can help identify when a full year has passed since the last
 * repayment, which might be useful for notifications or reporting.
 *
 * @param issueDate Date the loan was issued
 * @param lastCompoundDate Date when interest was last calculated (or issue date if never calculated)
 * @param currentDate Current date
 * @returns Boolean indicating if a full year has passed
 */
export const needsCompoundInterest = (
  issueDate: Date,
  lastCompoundDate: Date | undefined,
  currentDate: Date = new Date()
): boolean => {
  const compoundDate = lastCompoundDate || issueDate;
  const timeInYears = dateUtils.calculateTimeInYears(compoundDate, currentDate);
  return timeInYears >= 1.0; // A full year or more has passed
};

/**
 * Apply compound interest to a loan
 * Uses the compound interest formula A = P(1+r)^t where n=1 (annual compounding)
 *
 * @param principal Current principal amount
 * @param annualInterestRate Annual interest rate (as a decimal, e.g., 0.24 for 24%)
 * @param timeInYears Time in years since last calculation
 * @param currentDate Date of this calculation
 * @returns Object with new principal and compound date
 */
export const applyCompoundInterest = (
  principal: number,
  annualInterestRate: number,
  timeInYears: number,
  currentDate: Date = new Date()
): { newPrincipal: number; lastCompoundDate: Date } => {

  // Apply the compound interest formula: A = P(1+r)^t
  const principalDecimal = new Decimal(principal);
  const rateDecimal = new Decimal(annualInterestRate);
  const timeDecimal = new Decimal(timeInYears);
  
  // Perform calculation step by step for better debugging
  const onePlusRate = new Decimal(1).plus(rateDecimal);
  
  const onePlusRatePower = onePlusRate.pow(timeDecimal);
  const newPrincipal = principalDecimal.times(onePlusRatePower).toNumber();
  
  return {
    newPrincipal,
    lastCompoundDate: currentDate
  };
};

export default calculateRepaymentItem;
