import React from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { Button } from '../styles/components'; // Using our component export
import Card from '../styles/components/Card';
import ThemeSwitcher from '../components/ThemeSwitcher';

/**
 * A showcase page demonstrating the theme system
 * This page can be used to see how different components look in each theme
 */
const ThemeShowcase: React.FC = () => {
  const { theme } = useTheme();
  
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-theme-primary mb-4">Theme Showcase</h1>
        <p className="text-theme-secondary mb-6">
          This page demonstrates the theme system and components. Current theme: <strong>{theme}</strong>
        </p>
        
        <div className="mb-6">
          <h2 className="text-xl font-semibold text-theme-primary mb-2">Theme Switcher</h2>
          <div className="p-4 bg-theme-primary border border-theme rounded-md">
            <ThemeSwitcher />
          </div>
        </div>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        {/* Color Palette Section */}
        <Card title="Color Palette">
          <div className="space-y-4">
            <div>
              <h3 className="text-sm font-medium text-theme-secondary mb-2">Background Colors</h3>
              <div className="grid grid-cols-3 gap-2">
                <div className="flex flex-col items-center">
                  <div className="w-full h-16 bg-theme-primary rounded border border-theme"></div>
                  <span className="text-xs mt-1 text-theme-tertiary">Primary</span>
                </div>
                <div className="flex flex-col items-center">
                  <div className="w-full h-16 bg-theme-secondary rounded border border-theme"></div>
                  <span className="text-xs mt-1 text-theme-tertiary">Secondary</span>
                </div>
                <div className="flex flex-col items-center">
                  <div className="w-full h-16 bg-theme-tertiary rounded border border-theme"></div>
                  <span className="text-xs mt-1 text-theme-tertiary">Tertiary</span>
                </div>
              </div>
            </div>
            
            <div>
              <h3 className="text-sm font-medium text-theme-secondary mb-2">Text Colors</h3>
              <div className="space-y-2 p-3 bg-theme-primary rounded border border-theme">
                <p className="text-theme-primary">Primary Text</p>
                <p className="text-theme-secondary">Secondary Text</p>
                <p className="text-theme-tertiary">Tertiary Text</p>
              </div>
            </div>
            
            <div>
              <h3 className="text-sm font-medium text-theme-secondary mb-2">Accent Colors</h3>
              <div className="grid grid-cols-2 gap-2">
                <div className="flex flex-col items-center">
                  <div className="w-full h-12 bg-accent rounded"></div>
                  <span className="text-xs mt-1 text-theme-tertiary">Accent</span>
                </div>
                <div className="flex flex-col items-center">
                  <div className="w-full h-12 bg-accent-hover rounded"></div>
                  <span className="text-xs mt-1 text-theme-tertiary">Accent Hover</span>
                </div>
              </div>
            </div>
            
            <div>
              <h3 className="text-sm font-medium text-theme-secondary mb-2">Additional Theme Colors</h3>
              <div className="grid grid-cols-1 gap-2">
                <div className="flex flex-col items-center">
                  <div className="w-full h-12 bg-theme-highlight rounded"></div>
                  <span className="text-xs mt-1 text-theme-tertiary">Theme Highlight</span>
                </div>
              </div>
            </div>
          </div>
        </Card>
        
        {/* Buttons Section */}
        <Card title="Buttons">
          <div className="space-y-6">
            <div>
              <h3 className="text-sm font-medium text-theme-secondary mb-2">Button Variants</h3>
              <div className="flex flex-wrap gap-2">
                <Button variant="primary">Primary</Button>
                <Button variant="secondary">Secondary</Button>
                <Button variant="outline">Outline</Button>
                <Button variant="ghost">Ghost</Button>
                <Button variant="danger">Danger</Button>
              </div>
            </div>
            
            <div>
              <h3 className="text-sm font-medium text-theme-secondary mb-2">Button Sizes</h3>
              <div className="flex items-center flex-wrap gap-2">
                <Button size="sm" variant="primary">Small</Button>
                <Button size="md" variant="primary">Medium</Button>
                <Button size="lg" variant="primary">Large</Button>
              </div>
            </div>
            
            <div>
              <h3 className="text-sm font-medium text-theme-secondary mb-2">Button States</h3>
              <div className="flex flex-wrap gap-2">
                <Button isLoading variant="primary">Loading</Button>
                <Button disabled variant="primary">Disabled</Button>
                <Button 
                  variant="primary"
                  leftIcon={
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                    </svg>
                  }
                >
                  With Icon
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Cards with different styles */}
        <Card title="Basic Card">
          <p className="text-theme-secondary">
            This is a basic card with a title and content.
          </p>
        </Card>
        
        <Card 
          title="Card with Footer"
          footer={
            <div className="flex space-x-2">
              <Button size="sm" variant="ghost">Cancel</Button>
              <Button size="sm" variant="primary">Save</Button>
            </div>
          }
        >
          <p className="text-theme-secondary">
            This card has a footer with action buttons.
          </p>
        </Card>
        
        <Card className="border-t-4 border-t-accent">
          <div className="flex justify-between items-start">
            <div>
              <h3 className="text-lg font-semibold text-theme-primary mb-1">Custom Card</h3>
              <p className="text-theme-secondary">
                A custom styled card with an accent border.
              </p>
            </div>
            <Button variant="ghost" size="sm">
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
              </svg>
            </Button>
          </div>
        </Card>
      </div>
      
      <div className="mt-8">
        <Card title="Typography">
          <div className="space-y-4">
            <div>
              <h1 className="text-4xl font-bold text-theme-primary">Heading 1</h1>
              <h2 className="text-3xl font-bold text-theme-primary">Heading 2</h2>
              <h3 className="text-2xl font-bold text-theme-primary">Heading 3</h3>
              <h4 className="text-xl font-bold text-theme-primary">Heading 4</h4>
              <h5 className="text-lg font-bold text-theme-primary">Heading 5</h5>
              <h6 className="text-base font-bold text-theme-primary">Heading 6</h6>
            </div>
            
            <div>
              <p className="text-theme-secondary mb-2">
                This is a paragraph with <strong>bold text</strong>, <em>italic text</em>, and 
                <a href="#" className="text-accent hover:underline"> a link</a>.
              </p>
              <p className="text-theme-tertiary text-sm">
                This is smaller text often used for captions or helper text.
              </p>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ThemeShowcase;