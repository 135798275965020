import React from 'react';
import { LoanRepayment } from '../types/repayment';
import { useRepaymentContext } from '../contexts/RepaymentContext';
import { Button } from '../../../styles/components';

interface CompactNavigationFooterProps {
  repayment: LoanRepayment;
  repaymentId?: string;
  onPreviousRepayment: () => void;
  onNextRepayment: () => void;
  onViewPreviousRepayments: () => void;
  onSubmit: (e: React.FormEvent) => Promise<void>;
}

export const CompactNavigationFooter: React.FC<CompactNavigationFooterProps> = ({
  repayment,
  repaymentId,
  onPreviousRepayment,
  onNextRepayment,
  onViewPreviousRepayments,
  onSubmit
}) => {
  const { state } = useRepaymentContext();
  const { isLoading } = state;
  
  // Has items selected - enable save button
  const hasItems = repayment.repaymentItems.length > 0;

  return (
    <div
      className="fixed bottom-0 left-0 right-0 bg-theme-primary shadow-theme-medium px-4 py-2 flex items-center justify-between border-t border-theme"
      style={{
        width: '100%',
        zIndex: 50,
      }}
    >
      <div className="flex items-center space-x-2">
        <Button
          type="button"
          onClick={onViewPreviousRepayments}
          disabled={isLoading}
          variant="secondary"
          size="sm"
        >
          History
        </Button>
        
        <div className="flex items-center">
          {repayment.voucherNumber && repayment.voucherNumber > 1 && (
            <Button
              type="button"
              onClick={onPreviousRepayment}
              disabled={isLoading}
              variant="outline"
              size="sm"
              title="Previous Repayment"
            >
              &lt;
            </Button>
          )}
          <span className="mx-2 text-sm text-theme-secondary">
            Voucher #{repayment.voucherNumber || '?'}
          </span>
          {repayment.voucherNumber && (
            <Button
              type="button"
              onClick={onNextRepayment}
              disabled={isLoading}
              variant="outline"
              size="sm"
              title="Next Repayment"
            >
              &gt;
            </Button>
          )}
        </div>
      </div>
      
      <div className="flex items-center space-x-3">
        <Button
          type="button"
          onClick={() => window.location.reload()}
          disabled={isLoading}
          variant="secondary"
          size="sm"
        >
          Reset
        </Button>
        
        <Button
          type="submit"
          disabled={isLoading || !hasItems}
          variant="primary"
          isLoading={isLoading}
        >
          {repaymentId ? 'Update Payment' : 'Save Payment'}
        </Button>
      </div>
    </div>
  );
};