import React, { useState, useEffect } from 'react';
import { Loan } from '../../types/loan';
import { loanApi } from '../../api/loanApi';
import { useLoanContext, loanActions } from '../../contexts/LoanContext';

interface PreviousLoansProps {
  onEdit: (loanId: string) => void;
}

export const PreviousLoans: React.FC<PreviousLoansProps> = ({
  onEdit,
}) => {
  const { state, dispatch } = useLoanContext();
  const [loans, setLoans] = useState<Loan[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const handleClose = () => {
    dispatch(loanActions.setPreviousLoansVisible(false));
  };

  useEffect(() => {
    const loadLoans = async () => {
      // Only load data when modal is visible
      if (!state.isPreviousLoansVisible) return;
      
      try {
        setLoading(true);
        setError(null);
        const response = await loanApi.fetchLoans();
        
        if (response.success && response.data.loans) {
          // Sort loans by voucher number in descending order
          const sortedLoans = [...response.data.loans].sort(
            (a, b) => b.voucherNumber - a.voucherNumber
          );
          setLoans(sortedLoans);
        } else {
          throw new Error('Failed to load loans');
        }
      } catch (err) {
        setError('Failed to load loans');
        console.error('Error loading loans:', err);
      } finally {
        setLoading(false);
      }
    };

    loadLoans();
  }, [state.isPreviousLoansVisible]); // Only re-run when visibility changes

  const handleDelete = async (loanId: string) => {
    if (!window.confirm('Are you sure you want to delete this loan?')) {
      return;
    }

    try {
      dispatch(loanActions.setLoading(true));
      await loanApi.deleteLoan(loanId);
      setLoans(prevLoans => prevLoans.filter(loan => loan._id !== loanId));
    } catch (err) {
      setError('Failed to delete loan');
      console.error('Error deleting loan:', err);
    } finally {
      dispatch(loanActions.setLoading(false));
    }
  };

  // Don't render anything if modal is not visible
  if (!state.isPreviousLoansVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-4 w-3/4 max-h-[80vh] overflow-hidden flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <h2 className="font-bold">Previous Loans</h2>
          <button
            onClick={handleClose}
            className="text-gray-500 hover:text-gray-700"
            type="button"
          >
            <span className="sr-only">Close</span>
            <svg
              className="h-5 w-5"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {loading && (
          <div className="flex justify-center items-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
          </div>
        )}

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded relative mb-4">
            {error}
          </div>
        )}

        <div className="overflow-auto flex-grow">
          <table className="min-w-full border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 p-1 text-left">Voucher No.</th>
                <th className="border border-gray-300 p-1 text-left">Date</th>
                <th className="border border-gray-300 p-1 text-left">Customer</th>
                <th className="border border-gray-300 p-1 text-right">Amount</th>
                <th className="border border-gray-300 p-1 text-center">Monthly Interest %</th>
                <th className="border border-gray-300 p-1 text-center">Items</th>
                <th className="border border-gray-300 p-1 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {loans.map((loan) => (
                <tr key={loan._id} className="hover:bg-gray-50">
                  <td className="border border-gray-300 p-1">
                    {loan.voucherNumber}
                  </td>
                  <td className="border border-gray-300 p-1">
                    {new Date(loan.date).toLocaleDateString()}
                  </td>
                  <td className="border border-gray-300 p-1">
                    {loan.selectedCustomer}
                  </td>
                  <td className="border border-gray-300 p-1 text-right">
                    ₹{loan.amount.toFixed(2)}
                  </td>
                  <td className="border border-gray-300 p-1 text-center">
                    {loan.monthlyInterestPercent}%
                  </td>
                  <td className="border border-gray-300 p-1 text-center">
                    {loan.items.length}
                  </td>
                  <td className="border border-gray-300 p-1">
                    <div className="flex justify-center gap-4">
                      <button
                        type="button"
                        onClick={() => {
                          if (loan._id) {
                            onEdit(loan._id);
                            handleClose();
                          }
                        }}
                        className="text-blue-600 hover:text-blue-900"
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        onClick={() => loan._id && handleDelete(loan._id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
