import React, { useState, useEffect } from 'react';
import { RepaymentItem } from '../types/repayment';
import { useRepaymentContext } from '../contexts/RepaymentContext';
import { dateUtils } from '../utils/dateUtils';
import { repaymentApi } from '../api/repaymentApi';
import { calculateRepaymentItem } from '../utils/calculations';
import { formatInterestDetails } from '../utils/loanCalculations';
import { Button } from '../../../styles/components';
import { StandaloneLoanDetails } from './StandaloneLoanDetails';
import { PaymentModeTable } from './PaymentModeTable';
import '../styles/table.css';

// Helper function to get original loan principal
const getLoanPrincipal = (loan: any): number => {
  if (loan?.originalPrincipal !== undefined) {
    return loan.originalPrincipal;
  }
  if (loan?.amount !== undefined) {
    return loan.amount;
  }
  console.warn(`Missing originalPrincipal for loan ${loan?._id || 'unknown'}`);
  return 0; // Fallback value
};

// Helper function to get the "old" principal balance (before payment) from repayment history
const getPrincipalBalanceOld = (loan: any): number => {
  // First try to get from repayment history (previous payments)
  if (loan?.repaymentHistory?.remainingPrincipalNew !== undefined) {
    return loan.repaymentHistory.remainingPrincipalNew;
  }
  // Fallback to original principal if no history exists
  return getLoanPrincipal(loan);
};

interface CompactRepaymentItemsProps {
  items: RepaymentItem[];
  onSelectItems: (items: RepaymentItem[]) => void;
  customerId?: string;
  hideAvailableLoans?: boolean;
}

// Define payment type options
type PaymentType = 'full' | 'custom' | 'interest_only';

export const CompactRepaymentItems: React.FC<CompactRepaymentItemsProps> = ({
  items,
  onSelectItems,
  customerId,
  hideAvailableLoans = false
}) => {
  const { state } = useRepaymentContext();
  const [customerLoans, setCustomerLoans] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedLoanIds, setSelectedLoanIds] = useState<string[]>([]);
  
  // Payment options state
  const [paymentType, setPaymentType] = useState<PaymentType>('full');
  const [paymentAmounts, setPaymentAmounts] = useState<Record<string, string | number>>({});
  const [roundingAmounts, setRoundingAmounts] = useState<Record<string, string | number>>({});
  // Add state for tracking the calculated new principal balance for the summary
  const [calculatedPrincipalBalanceNew, setCalculatedPrincipalBalanceNew] = useState<number | null>(null);
  
  // Use selected customer for fetch if available
  const selectedCustomerId = customerId || state.repayment.customer;
  const selectedCustomerName = state.repayment.selectedCustomer;

  // Fetch customer loans when customer is selected
  useEffect(() => {
    if (!selectedCustomerId) return;
    fetchCustomerLoans();
  }, [selectedCustomerId]);

  // Function to fetch loans for the selected customer
  const fetchCustomerLoans = async () => {
    if (!selectedCustomerId) return;
    
    try {
      setLoading(true);
      setError(null);
      setCustomerLoans([]);
      
      // Use our repaymentApi method to fetch loans
      try {
        const response = await repaymentApi.fetchCustomerLoans(selectedCustomerId);
        
        if (response && response.loans && response.loans.length > 0) {
          setCustomerLoans(response.loans);
        } else {
          // No loans found but no error
          setCustomerLoans([]);
          setError(`No outstanding loans found for ${selectedCustomerName || 'this customer'}`);
        }
      } catch (error) {
        // Clear any previous results
        setCustomerLoans([]);
        
        // Extract meaningful error message
        const errorMessage = error instanceof Error ? error.message : 'Unknown error';
        setError(`Failed to fetch loans: ${errorMessage}`);
      }
    } catch (err) {
      setCustomerLoans([]);
      setError(`Failed to fetch loans: ${err instanceof Error ? err.message : 'Unknown error'}`);
    } finally {
      setLoading(false);
    }
  };

  // Initialize selected loan IDs from current items when component mounts or when items change
  useEffect(() => {
    if (items.length > 0 && customerLoans.length > 0) {
      // Extract unique issue IDs from current items
      const currentIssueIds = [...new Set(items.map(item => item.issueId))];
      
      // Find corresponding loan IDs
      const matchingLoanIds = customerLoans
        .filter(loan => currentIssueIds.includes(loan._id))
        .map(loan => loan._id);
      
      if (matchingLoanIds.length > 0) {
        setSelectedLoanIds(matchingLoanIds);
      }
    }
  }, [items, customerLoans]);

  // Update payment amounts when loans are selected or payment type changes
  useEffect(() => {
    if (selectedLoanIds.length > 0) {
      const newPaymentAmounts: Record<string, string | number> = {};
      
      selectedLoanIds.forEach(loanId => {
        const loan = customerLoans.find(l => l._id === loanId);
        if (!loan) return;
        
        const repaymentDate = state.repayment.date || new Date();
        
        // Extract payment history dates
        const lastPaymentDate = loan.repaymentHistory?.lastPaymentDate || null;
        const lastCompoundDate = loan.repaymentHistory?.lastCompoundDate || null;
        
        // Get current remaining principal for calculations
        const currentPrincipal = getPrincipalBalanceOld(loan);
        
        const result = calculateRepaymentItem(
          {
            issueDate: loan.date,
            oldRemainingPrincipal: currentPrincipal, // Use the current remaining principal
            monthlyInterestPercent: loan.monthlyInterestPercent,
            lastPaymentDate: lastPaymentDate ? new Date(lastPaymentDate) : null,
            lastCompoundDate: lastCompoundDate ? new Date(lastCompoundDate) : undefined
          },
          repaymentDate
        );
        
        if (paymentType === 'full') {
          newPaymentAmounts[loanId] = result.amountPayable ?? result.interest; // Using calculation result amountPayable field with fallback
        } else if (paymentType === 'interest_only') {
          newPaymentAmounts[loanId] = result.interest;
        }
        // For custom payments, DON'T initialize with any value - let user enter it manually
        // This is intentionally removed to fix the automatic value filling issue
      });
      
      setPaymentAmounts(prev => ({
        ...prev,
        ...newPaymentAmounts
      }));
    }
  }, [selectedLoanIds, paymentType, customerLoans, state.repayment.date, state.repayment.customer]);

  // Handle payment type change
  const handlePaymentTypeChange = (type: PaymentType) => {
    // If switching to custom amount, clear any existing values
    if (type === 'custom') {
      // Create empty payment amounts object
      const emptyAmounts: Record<string, string | number> = {};
      
      // Set empty values for all selected loans
      selectedLoanIds.forEach(loanId => {
        emptyAmounts[loanId] = '';
      });
      
      // Update payment amounts state with empty values
      setPaymentAmounts(emptyAmounts);
    }
    
    
    setPaymentType(type);
    
    // When changing to interest_only mode, ensure we update all items to keep principal unchanged
    if (type === 'interest_only') {
      
      // Immediately update all selected loan items for interest-only payments
      const interestOnlyItems = customerLoans
        .filter(loan => selectedLoanIds.includes(loan._id))
        .map(loan => {
          const repaymentDate = state.repayment.date || new Date();
          
          // Extract payment history dates
          const lastPaymentDate = loan.repaymentHistory?.lastPaymentDate || null;
          const lastCompoundDate = loan.repaymentHistory?.lastCompoundDate || null;
          
          // Get current remaining principal for calculations
          const currentPrincipal = getPrincipalBalanceOld(loan);
          
          const calculatedValues = calculateRepaymentItem(
            {
              issueDate: loan.date,
              oldRemainingPrincipal: currentPrincipal, // Use the current remaining principal
              monthlyInterestPercent: loan.monthlyInterestPercent,
              lastPaymentDate: lastPaymentDate ? new Date(lastPaymentDate) : null,
              lastCompoundDate: lastCompoundDate ? new Date(lastCompoundDate) : undefined
            },
            repaymentDate,
            'interest_only' // Explicitly set payment type as interest_only
          );
          
          // Create repayment item with correct interest_only settings
          const principal = getLoanPrincipal(loan);
          const currentRemaining = getPrincipalBalanceOld(loan);
          
          // Log the values to help debug
          
          return {
            issueId: loan._id,
            issueDate: new Date(loan.date),
            originalPrincipal: principal,
            monthlyInterestPercent: loan.monthlyInterestPercent,
            interest: calculatedValues.interest,
            // For interest-only, amountPayable should equal interest (not the full amount)
            amountPayable: calculatedValues.interest,
            paymentType: 'interest_only' as const, // Use const assertion to fix TypeScript error
            amountPaid: calculatedValues.interest, // Only pay the interest
            rounding: 0,
            // CRITICAL FIX: Use the correct field names for principal tracking
            remainingPrincipalOld: currentRemaining, // Set the old principal value correctly
            remainingPrincipalNew: currentRemaining, // For interest-only, new equals old
            lastCompoundDate: calculatedValues.lastCompoundDate
          };
        });
      
      // Update the parent component immediately
      onSelectItems(interestOnlyItems);
      return; // Skip the regular updateRepaymentItems call
    } else if (type === 'full') {
    }
    
    updateRepaymentItems(selectedLoanIds, type);
  };

  // For tracking latest input values directly (bypassing state delay)
  const loanAmountsRef = React.useRef<Record<string, string>>({});
  const roundingAmountsRef = React.useRef<Record<string, string>>({});
  
  // Handle round off amount change
  const handleRoundOffChange = (e: React.ChangeEvent<HTMLInputElement>, loanId: string) => {
    const value = e.target.value;
    
    // Store the latest value in the ref for immediate access
    roundingAmountsRef.current[loanId] = value;
    
    // Update the state for rendering
    setRoundingAmounts(prev => ({
      ...prev,
      [loanId]: value
    }));

    // Update repayment items to include round off amounts
    updateRepaymentItems(selectedLoanIds, paymentType);
  };

  // Handle custom amount change
  const handleCustomAmountChange = (e: React.ChangeEvent<HTMLInputElement>, loanId: string) => {
    const value = e.target.value;
    
    // Store the latest value in the ref for immediate access
    loanAmountsRef.current[loanId] = value;
    
    // Update the state for rendering
    setPaymentAmounts(prev => ({
      ...prev,
      [loanId]: value
    }));
    
    // DIRECTLY update the payment items with the CURRENT input value
    // This ensures we're using the value that was just typed, not waiting for state updates
    if (selectedLoanIds.length > 0) {
      const allRepaymentItems = selectedLoanIds.flatMap(currentLoanId => {
        const loan = customerLoans.find(l => l._id === currentLoanId);
        if (!loan) return [];
        
        // Use the LATEST value from our ref, which has the current typed value
        // This is the key to fixing the "one step behind" issue
        const userAmountStr = currentLoanId === loanId
          ? value // Use the direct event value for the current field
          : (paymentAmounts[currentLoanId]?.toString() || '0');
          
        const userAmount = userAmountStr === '' ? 0 : parseFloat(userAmountStr);
        
        // Get the round off amount for this loan (if any)
        const roundOffStr = roundingAmountsRef.current[currentLoanId] || 
                          (roundingAmounts[currentLoanId]?.toString() || '0');
        const roundOffAmount = roundOffStr === '' ? 0 : parseFloat(roundOffStr);
        
        const repaymentDate = state.repayment.date || new Date();
        
        // Extract payment history dates
        const lastPaymentDate = loan.repaymentHistory?.lastPaymentDate || null;
        const lastCompoundDate = loan.repaymentHistory?.lastCompoundDate || null;
        
        const calculatedValues = calculateRepaymentItem(
          {
            issueDate: loan.date,
            oldRemainingPrincipal: getPrincipalBalanceOld(loan), // Pass the old principal balance before payment
            monthlyInterestPercent: loan.monthlyInterestPercent,
            lastPaymentDate: lastPaymentDate ? new Date(lastPaymentDate) : null,
            lastCompoundDate: lastCompoundDate ? new Date(lastCompoundDate) : undefined
          },
          repaymentDate,
          paymentType === 'custom' ? 'custom' : paymentType, 
          userAmount
        );
        
        // Create the repayment item with the correct amount and round off
        const principal = getLoanPrincipal(loan);
        const currentRemaining = getPrincipalBalanceOld(loan);
        const repaymentItem: RepaymentItem = {
          issueId: loan._id,
          issueDate: new Date(loan.date),
          originalPrincipal: principal, // Use consistent principal value
          monthlyInterestPercent: loan.monthlyInterestPercent,
          interest: calculatedValues.interest,
          amountPayable: calculatedValues.amountPayable || calculatedValues.interest,
          // Keep the paymentType consistent
          paymentType: paymentType,
          amountPaid: paymentType === 'custom' 
            ? userAmount - roundOffAmount  // FIX: Subtract rounding from the custom amount
            : paymentType === 'interest_only'
              ? calculatedValues.interest - roundOffAmount  // Also subtract rounding from interest in interest_only mode
              : ((calculatedValues as any).amountPaid || calculatedValues.amountPayable - roundOffAmount),
          rounding: roundOffAmount,
          // Calculate remaining principal directly based on payment type
          // with enhanced logging and safety checks
          // Old remainingPrincipal field is now split into old and new
          remainingPrincipalOld: currentRemaining,
          remainingPrincipalNew: paymentType === 'custom'
            ? Math.max(0, currentRemaining - Math.max(0, userAmount - calculatedValues.interest))
            : paymentType === 'interest_only'
              ? currentRemaining  // Interest only leaves the current remaining unchanged
              : 0,  // Full payment = zero remaining
          lastCompoundDate: (calculatedValues as any).lastCompoundDate || new Date()
        };
        
        
        return [repaymentItem];
      });
      
      // Update parent component with these items immediately
      onSelectItems(allRepaymentItems);
    }
  };

  // Update repayment items based on selected loans, payment type, and amounts
  const updateRepaymentItems = (loanIds: string[], type: PaymentType) => {
    if (loanIds.length === 0) {
      onSelectItems([]);
      return;
    }
    
    const repaymentDate = state.repayment.date || new Date();
    const selectedLoans = customerLoans.filter(loan => loanIds.includes(loan._id));
    
    try {
      const allRepaymentItems = selectedLoans.flatMap(loan => {
        // Ensure loan has required properties
        if (!loan._id || !loan.date) {
          return [];
        }
        
        // Get the principal amount with our helper function
        const principal = getLoanPrincipal(loan);
        if (principal === 0) {
          console.warn(`[WARNING] Loan ${loan._id} has no principal amount`);
          return [];
        }
        // Get the user input amount for this loan
        const userAmount = type === 'custom' ?
          (typeof paymentAmounts[loan._id] === 'string'
            ? parseFloat(paymentAmounts[loan._id] as string) || 0
            : (paymentAmounts[loan._id] as number) || 0)
          : 0;
        
        // Get the round off amount for this loan
        const roundOffStr = roundingAmountsRef.current[loan._id] || 
                         (roundingAmounts[loan._id]?.toString() || '0');
        const roundOffAmount = roundOffStr === '' ? 0 : parseFloat(roundOffStr);
        
        // Extract payment history dates
        const lastPaymentDate = loan.repaymentHistory?.lastPaymentDate || null;
        const lastCompoundDate = loan.repaymentHistory?.lastCompoundDate || null;
        
        // Use the calculation function that supports payment types
        const calculatedValues = calculateRepaymentItem(
          {
            issueDate: loan.date,
            oldRemainingPrincipal: getPrincipalBalanceOld(loan), // Pass the old principal balance before payment
            monthlyInterestPercent: loan.monthlyInterestPercent,
            lastPaymentDate: lastPaymentDate ? new Date(lastPaymentDate) : null,
            lastCompoundDate: lastCompoundDate ? new Date(lastCompoundDate) : undefined
          },
          repaymentDate,
          type,
          userAmount
        );
        
        // Generate repayment item with payment type info
        const repaymentItem: RepaymentItem = {
          issueId: loan._id,
          issueDate: new Date(loan.date),
          originalPrincipal: principal,
          monthlyInterestPercent: loan.monthlyInterestPercent,
          interest: calculatedValues.interest,
          amountPayable: calculatedValues.amountPayable ?? calculatedValues.interest,
          // FIXED: Ensure payment type is consistently 'custom' for custom amounts,
          // never undefined or another value that might default to 'full'
          paymentType: type,
          // For custom payments, directly use the user's input amount minus the rounding
          amountPaid: type === 'custom' 
            ? userAmount - roundOffAmount  // FIX: Subtract rounding from the custom amount
            : type === 'interest_only'
              ? calculatedValues.interest - roundOffAmount  // Also subtract rounding from interest in interest_only mode
              : ((calculatedValues as any).amountPaid || calculatedValues.amountPayable - roundOffAmount),
          rounding: roundOffAmount,
          // Be explicit about remaining principal for all payment types
          // with enhanced validation and explicit debugging
          remainingPrincipalOld: getPrincipalBalanceOld(loan),
          remainingPrincipalNew: type === 'custom'
            ? Math.max(0, getPrincipalBalanceOld(loan) - Math.max(0, userAmount - calculatedValues.interest))
            : type === 'interest_only'
              ? getPrincipalBalanceOld(loan)  // Interest only leaves the current remaining unchanged
              : 0,  // Full payment = zero remaining
          lastCompoundDate: (calculatedValues as any).lastCompoundDate
        };
        
        
        return [repaymentItem];
      });
      
      // Update parent component with selected items
      onSelectItems(allRepaymentItems);
    } catch (error) {
    }
  };
  
  // Handle loan selection
  const handleLoanSelection = (loan: any) => {
    // Toggle selection
    setSelectedLoanIds(prevSelected => {
      const isCurrentlySelected = prevSelected.includes(loan._id);
      
      // If selected, remove it; otherwise add it
      const newSelectedIds = isCurrentlySelected
        ? prevSelected.filter(id => id !== loan._id)
        : [...prevSelected, loan._id];
      
      // Update payment amounts for newly selected loan - ONLY for non-custom amount modes
      if (!isCurrentlySelected && paymentType !== 'custom') {
        const repaymentDate = state.repayment.date || new Date();
        
        // Extract payment history dates
        const lastPaymentDate = loan.repaymentHistory?.lastPaymentDate || null;
        const lastCompoundDate = loan.repaymentHistory?.lastCompoundDate || null;
        
        const result = calculateRepaymentItem(
          {
            issueDate: loan.date,
            oldRemainingPrincipal: getPrincipalBalanceOld(loan), // Pass the old principal balance before payment
            monthlyInterestPercent: loan.monthlyInterestPercent,
            lastPaymentDate: lastPaymentDate ? new Date(lastPaymentDate) : null,
            lastCompoundDate: lastCompoundDate ? new Date(lastCompoundDate) : undefined
          },
          repaymentDate
        );
        
        if (paymentType === 'full') {
          setPaymentAmounts(prev => ({
            ...prev,
            [loan._id]: result.amountPayable
          }));
        } else if (paymentType === 'interest_only') {
          setPaymentAmounts(prev => ({
            ...prev,
            [loan._id]: result.interest
          }));
        }
      }
      
      // Update repayment items based on the new selection
      updateRepaymentItems(newSelectedIds, paymentType);
      
      return newSelectedIds;
    });
  };

  // Retry loading if there was an error
  const handleRetry = () => {
    fetchCustomerLoans();
  };

  // Calculate interest for given loan
  const calculateInterest = (loan: any): number => {
    if (!loan || !loan.date) return 0;
    
    const principal = getLoanPrincipal(loan);
    if (principal === 0) return 0;
    
    const repaymentDate = state.repayment.date || new Date();
    
    // Extract payment history dates
    const lastPaymentDate = loan.repaymentHistory?.lastPaymentDate || null;
    const lastCompoundDate = loan.repaymentHistory?.lastCompoundDate || null;
    
    const result = calculateRepaymentItem(
      {
        issueDate: loan.date,
        oldRemainingPrincipal: getPrincipalBalanceOld(loan), // Pass the old principal balance before payment
        monthlyInterestPercent: loan.monthlyInterestPercent,
        lastPaymentDate: lastPaymentDate ? new Date(lastPaymentDate) : null,
        lastCompoundDate: lastCompoundDate ? new Date(lastCompoundDate) : undefined
      },
      repaymentDate
    );
    
    return result.interest;
  };

  // Show placeholder if no customer selected
  if (!selectedCustomerId) {
    return (
      <div className="p-4 bg-theme-secondary rounded-lg text-center">
        <p className="text-theme-secondary">Please select a customer first to view available loans.</p>
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <div>
          {loading && (
            <div className="flex items-center">
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-accent mr-2"></div>
              <span className="text-sm text-theme-secondary">Loading loans...</span>
            </div>
          )}
        </div>
      </div>

      {/* Error message */}
      {error && (
        <div className="text-red-500 bg-red-50 border border-red-200 p-3 rounded-md mb-4">
          <div className="flex items-start">
            <svg className="h-5 w-5 text-red-400 mr-2 mt-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
            <div>
              <p>{error}</p>
              <div className="mt-2">
                <Button
                  onClick={handleRetry}
                  variant="outline"
                  size="sm"
                >
                  Retry
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Compact Loan Table - With additional columns for loan details */}
      {!hideAvailableLoans && customerLoans.length > 0 && (
        <div className="mb-4">
          <div className="flex justify-between items-center mb-2">
            <h4 className="font-medium text-theme-primary">Available Loans</h4>
            <span className="text-sm text-theme-tertiary">{customerLoans.length} loan(s) found</span>
          </div>
          <div className="loan-table-container">
            <table className="loan-table">
              <thead>
                <tr className="bg-theme-secondary border-b border-theme">
                  <th className="col-checkbox text-center"></th>
                  <th className="col-date text-center">Issue Date</th>
                  <th className="col-amount text-right">Principal</th>
                  <th className="col-number text-center">Int. %</th>
                  <th className="col-action text-center">Details</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-theme">
                {customerLoans.map((loan) => (
                  <tr
                    key={loan._id}
                    className={`transition-colors ${selectedLoanIds.includes(loan._id) ? 'selected' : ''}`}
                    onClick={() => handleLoanSelection(loan)}
                  >
                    <td className="text-center">
                      <input
                        type="checkbox"
                        checked={selectedLoanIds.includes(loan._id)}
                        onChange={() => handleLoanSelection(loan)}
                        className="h-4 w-4 text-accent focus:ring-accent border-theme rounded cursor-pointer"
                      />
                    </td>
                    <td className="text-center text-theme-secondary">
                      {dateUtils.formatDate(loan.date)}
                    </td>
                    <td className="text-right text-theme-primary font-medium">
                      ₹{loan.amount?.toFixed(2) || "0.00"}
                    </td>
                    <td className="text-center text-theme-secondary">
                      {loan.monthlyInterestPercent || "0"}%
                    </td>
                    <td className="text-center">
                      <StandaloneLoanDetails
                        loanId={loan._id}
                        date={loan.date}
                        amount={loan.amount}
                        interestPercent={loan.monthlyInterestPercent}
                        items={loan.items || []}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Enhanced Payment Options */}
      {items.length > 0 && (
        <div className="mb-4">
          <h4 className="font-medium text-theme-primary mb-3 pb-2 border-b border-theme">Payment Options</h4>
          <div className="flex flex-wrap gap-4 mb-5">
            <div
              className={`flex p-4 border rounded-lg cursor-pointer hover:shadow-md transition-all ${paymentType === 'full' ? 'border-accent bg-accent bg-opacity-5 shadow-sm' : 'border-theme'}`}
              onClick={() => handlePaymentTypeChange('full')}
            >
              <div className="flex items-center">
                <input
                  type="radio"
                  id="payment-full"
                  name="paymentType"
                  checked={paymentType === 'full'}
                  onChange={() => handlePaymentTypeChange('full')}
                  className="mr-2"
                />
                <label htmlFor="payment-full" className="font-semibold text-theme-primary cursor-pointer">
                  Full Payment
                </label>
              </div>
            </div>
            
            <div
              className={`flex p-4 border rounded-lg cursor-pointer hover:shadow-md transition-all ${paymentType === 'interest_only' ? 'border-accent bg-accent bg-opacity-5 shadow-sm' : 'border-theme'}`}
              onClick={() => handlePaymentTypeChange('interest_only')}
            >
              <div className="flex items-center">
                <input
                  type="radio"
                  id="payment-interest"
                  name="paymentType"
                  checked={paymentType === 'interest_only'}
                  onChange={() => handlePaymentTypeChange('interest_only')}
                  className="mr-2"
                />
                <label htmlFor="payment-interest" className="font-semibold text-theme-primary cursor-pointer">
                  Interest Only
                </label>
              </div>
            </div>
            
            <div
              className={`flex p-4 border rounded-lg cursor-pointer hover:shadow-md transition-all ${paymentType === 'custom' ? 'border-accent bg-accent bg-opacity-5 shadow-sm' : 'border-theme'}`}
              onClick={() => handlePaymentTypeChange('custom')}
            >
              <div className="flex items-center">
                <input
                  type="radio"
                  id="payment-custom"
                  name="paymentType"
                  checked={paymentType === 'custom'}
                  onChange={() => handlePaymentTypeChange('custom')}
                  className="mr-2"
                />
                <label htmlFor="payment-custom" className="font-semibold text-theme-primary cursor-pointer">
                  Custom Amount
                </label>
              </div>
            </div>
          </div>
          
          {/* Enhanced Payment Details using PaymentModeTable */}
          {selectedLoanIds.length > 0 && (
            <div className="mb-4">
              <div className="flex justify-between items-center mb-3 pb-2 border-b border-theme">
                <h5 className="font-medium text-theme-primary">Payment Details</h5>
                <span className="text-sm text-theme-tertiary">{selectedLoanIds.length} loan(s) selected</span>
              </div>
              
              {/* Use individual PaymentModeTable components for each loan */}
              <div className="space-y-4">
                {selectedLoanIds.map(loanId => {
                  const loan = customerLoans.find(l => l._id === loanId);
                  if (!loan) return null;
                  
                  // Extract lastPaymentDate and lastCompoundDate from loan.repaymentHistory if they exist
                  const lastPaymentDate = loan.repaymentHistory?.lastPaymentDate || null;
                  const lastCompoundDate = loan.repaymentHistory?.lastCompoundDate || null;
                  
                  // Get calculation details for this loan
                  const calculatedValues = calculateRepaymentItem(
                    {
                      issueDate: loan.date,
                      oldRemainingPrincipal: getPrincipalBalanceOld(loan), // Pass the old principal balance before payment
                      monthlyInterestPercent: loan.monthlyInterestPercent,
                      lastPaymentDate: lastPaymentDate ? new Date(lastPaymentDate) : null,
                      lastCompoundDate: lastCompoundDate ? new Date(lastCompoundDate) : undefined
                    },
                    state.repayment.date || new Date(),
                    paymentType,
                    paymentType === 'custom' ? (parseFloat(paymentAmounts[loanId]?.toString() || '0') || 0) : undefined
                  );
                  
                  // Get current values for this loan
                  const amountValue = paymentAmounts[loanId] !== undefined && paymentAmounts[loanId] !== ''
                    ? (typeof paymentAmounts[loanId] === 'string'
                      ? paymentAmounts[loanId]
                      : (paymentAmounts[loanId] as number)?.toString())
                    : '';
                  
                  const roundOffValue = roundingAmounts[loanId] !== undefined && roundingAmounts[loanId] !== ''
                    ? (typeof roundingAmounts[loanId] === 'string'
                      ? roundingAmounts[loanId]
                      : (roundingAmounts[loanId] as number)?.toString())
                    : '0';
                  
                  // Format interest details using the utility function
                  const interestDetails = calculatedValues.daysDifference && loan.monthlyInterestPercent
                    ? formatInterestDetails(loan.monthlyInterestPercent, calculatedValues.daysDifference)
                    : '';
                  
                  // Get the principal for this loan - safely
                  const principal = getLoanPrincipal(loan);
                  if (principal === 0) {
                    console.log(`Using fallback principal value for loan ${loan._id || 'unknown'}`);
                  }
                  
                  return (
                    <div key={loanId}>
                      <PaymentModeTable
                        mode={paymentType === 'custom' ? 'custom' : paymentType}
                        payment={{
                          issueDate: loan.date,
                          originalPrincipal: principal,
                          // Always display the actual remaining principal from loan history
                          // regardless of payment mode - this is just for display purposes
                          remainingPrincipalOld: getPrincipalBalanceOld(loan),
                          remainingPrincipalNew: paymentType === 'full'
                            ? 0
                            : paymentType === 'interest_only'
                              ? getPrincipalBalanceOld(loan)
                              : Math.max(0, getPrincipalBalanceOld(loan) - Math.max(0, parseFloat(amountValue || '0') - calculatedValues.interest)),
                          lastPaymentDate: lastPaymentDate,
                          interest: calculatedValues.interest || 0,
                          interestDetails,
                          amountPayable: calculatedValues.amountPayable || 0,
                          rounding: parseFloat(roundOffValue) || 0,
                          lastCompoundDate: loan.lastCompoundDate || null,
                          numberOfDays: calculatedValues.daysDifference,
                          interestPercent: loan.monthlyInterestPercent,
                          // Add amountPaid property
                          amountPaid: paymentType === 'custom' 
                            ? (parseFloat(amountValue || '0') - parseFloat(roundOffValue || '0'))
                            : (paymentType === 'interest_only' 
                               ? calculatedValues.interest - parseFloat(roundOffValue || '0')
                               : calculatedValues.amountPayable - parseFloat(roundOffValue || '0'))
                        }}
                        // Add handler for new principal balance changes
                        onRemainingPrincipalNewChange={(newPrincipalBalance) => {
                          setCalculatedPrincipalBalanceNew(newPrincipalBalance);
                        }}
                        onCustomAmountChange={(value) => handleCustomAmountChange({
                          target: { value }
                        } as React.ChangeEvent<HTMLInputElement>, loanId)}
                        onRoundingChange={(value) => handleRoundOffChange({
                          target: { value }
                        } as React.ChangeEvent<HTMLInputElement>, loanId)}
                        onDetailsClick={() => {
                          // Use the existing StandaloneLoanDetails component
                          // Find the details button for this loan in the DOM and click it
                          const detailsBtn = document.querySelector(`[data-loan-id="${loanId}"] .details-btn`);
                          if (detailsBtn instanceof HTMLElement) {
                            detailsBtn.click();
                          }
                        }}
                        customAmount={amountValue?.toString()}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          
        </div>
      )}
      
      {/* Selected Loans Counter - Only show when no loans selected and not in hidden mode */}
      {!hideAvailableLoans && (
        <div className="mb-4">
          {items.length === 0 && (
            <div className="text-center text-sm text-theme-secondary bg-theme-highlight p-2 rounded-md">
              <p>Select loans from the table above by checking the boxes</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};