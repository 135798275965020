import React, { useState, useEffect } from 'react';
import { Dialog, Tab } from '@headlessui/react';
import { dateUtils } from '../utils/dateUtils';
import { repaymentApi } from '../api/repaymentApi';
import {
  formatTimePeriod,
  formatInterestDetails,
  calculatePrincipalPercentage,
  getPaymentStatusText
} from '../utils/loanCalculations';
import '../styles/table.css';

interface LoanItemDetail {
  itemNameWithType: string;
  pcs: number;
  grossWt: number;
  lessWt: number;
  netWt: number;
  tunch: number;
  wstg: number;
  fine: number;
}

interface PaymentRecord {
  date: string;
  paymentType: 'full' | 'custom' | 'interest_only';
  interest: number;
  interestDetails: string; // Format: "(2%, 3m 15d)"
  principalPaid: number;
  remainingPrincipal: number;
  total: number;
  roundOff: number;
  voucherNumber: number;
  repaymentId: string;
  numberOfDays: number;
  interestPercent: string;
  lastCompoundDate: string | null;
  originalPrincipal: number;
  timeInMonthsDays?: string;
  
  // Added properties from processing
  isFirstPaymentForLoan?: boolean;
  principalPercentage?: number;
  paymentStatus?: string;
}

interface PaymentSummary {
  totalInterestPaid: number;
  totalPrincipalPaid: number;
  totalAmountPaid: number;
  totalRoundOff: number;
  netPayment: number;
  recordCount: number;
  paymentTypes?: {
    full: number;
    custom: number;
    interest_only: number;
  };
  paymentTypeCount?: {
    full: number;
    custom: number;
    interest_only: number;
  };
  remainingPrincipal: number;
  lastPaymentDate: string | null;
  lastCompoundDate: string | null;
}

interface StandaloneLoanDetailsProps {
  loanId: string;
  date: string | Date;
  amount: number;
  interestPercent: string;
  items: LoanItemDetail[] | any[];
}

export const StandaloneLoanDetails: React.FC<StandaloneLoanDetailsProps> = ({
  loanId,
  date,
  amount,
  interestPercent,
  items
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [paymentHistory, setPaymentHistory] = useState<PaymentRecord[]>([]);
  const [paymentSummary, setPaymentSummary] = useState<PaymentSummary | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleOpenDetails = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(true);
  };

  const handleCloseDetails = () => {
    setIsOpen(false);
  };

  // Fetch payment history when the modal opens and tab changes to payments
  useEffect(() => {
    let isMounted = true;
    
    const fetchPaymentHistory = async () => {
      if (!isOpen || activeTab !== 2) return;
      
      try {
        setLoading(true);
        setError(null);
        
        const response = await repaymentApi.fetchPaymentHistory(loanId);
        
        if (isMounted) {
          // Process the payment records to add formatted details
          const processedPaymentRecords = (response.paymentRecords || []).map((record: {
            date: string;
            paymentType: 'full' | 'custom' | 'interest_only';
            interest: number;
            principalPaid: number;
            remainingPrincipal: number;
            total: number;
            roundOff: number;
            voucherNumber: number;
            repaymentId: string;
            numberOfDays: number;
            interestPercent: string;
            lastCompoundDate: string | null;
            originalPrincipal: number;
          }, index: number) => {
            // Format time period in months + days
            const timeInMonthsDays = formatTimePeriod(record.numberOfDays);
            
            // Format interest details string with rate and time period
            const interestDetails = formatInterestDetails(record.interestPercent, record.numberOfDays);
            
            // Add principal percentage
            const principalPercentage = calculatePrincipalPercentage(
              record.remainingPrincipal,
              record.originalPrincipal
            );
            
            // Calculate whether this was the first payment for this loan
            const isFirstPaymentForLoan = index === 0;
            
            // Get formatted payment status text
            const paymentStatus = getPaymentStatusText(
              record.paymentType,
              record.remainingPrincipal,
              record.originalPrincipal
            );
            
            return {
              ...record,
              timeInMonthsDays,
              interestDetails,
              principalPercentage,
              isFirstPaymentForLoan,
              paymentStatus
            };
          });
          
          setPaymentHistory(processedPaymentRecords);
          
          // Normalize the summary data to handle both paymentTypes and paymentTypeCount
          const normalizedSummary = response.summary ? {
            ...response.summary,
            // Ensure paymentTypes is available even if backend returns paymentTypeCount
            paymentTypes: response.summary.paymentTypes || response.summary.paymentTypeCount || {
              full: 0,
              custom: 0,
              interest_only: 0
            }
          } : null;
          
          setPaymentSummary(normalizedSummary);
        }
      } catch (err) {
        if (isMounted) {
          console.error('Error fetching payment history:', err);
          setError(err instanceof Error ? err.message : 'Failed to load payment history');
          setPaymentHistory([]);
          setPaymentSummary(null);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };
    
    fetchPaymentHistory();
    
    return () => {
      isMounted = false;
    };
  }, [isOpen, activeTab, loanId]);

  // Format date for display
  const formattedDate = typeof date === 'string'
    ? dateUtils.formatDate(new Date(date))
    : dateUtils.formatDate(date);

  // Helper function to format payment type for display
  const formatPaymentType = (type: string): string => {
    switch (type) {
      case 'full': return 'Full Payment';
      case 'custom': return 'Custom Amount';
      case 'interest_only': return 'Interest Only';
      default: return type;
    }
  };

  return (
    <>
      <button
        type="button"
        onClick={handleOpenDetails}
        className="px-3 py-1 bg-accent text-white rounded hover:bg-accent-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent"
      >
        Details
      </button>

      <Dialog
        open={isOpen}
        onClose={handleCloseDetails}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-theme-contrast/30" aria-hidden="true" />
        
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="bg-theme-secondary rounded-lg max-w-5xl w-full p-6 shadow-xl max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <Dialog.Title className="text-2xl font-bold text-theme-primary">
                Loan Details
              </Dialog.Title>
              <button
                onClick={handleCloseDetails}
                className="text-theme-tertiary hover:text-theme-primary focus:outline-none"
                aria-label="Close"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            {/* Tabbed interface */}
            <Tab.Group selectedIndex={activeTab} onChange={setActiveTab}>
              <Tab.List className="flex space-x-1 rounded-xl bg-theme-highlight p-1 mb-4">
                <Tab
                  className={({ selected }) =>
                    `w-full rounded-lg py-2.5 text-sm font-medium leading-5 transition-all
                    ${selected
                      ? 'bg-theme-primary text-theme-primary shadow'
                      : 'text-theme-secondary hover:bg-theme/[0.12] hover:text-theme-primary'
                    }`
                  }
                >
                  Loan Info
                </Tab>
                <Tab
                  className={({ selected }) =>
                    `w-full rounded-lg py-2.5 text-sm font-medium leading-5 transition-all
                    ${selected
                      ? 'bg-theme-primary text-theme-primary shadow'
                      : 'text-theme-secondary hover:bg-theme/[0.12] hover:text-theme-primary'
                    }`
                  }
                >
                  Items
                </Tab>
                <Tab
                  className={({ selected }) =>
                    `w-full rounded-lg py-2.5 text-sm font-medium leading-5 transition-all
                    ${selected
                      ? 'bg-theme-primary text-theme-primary shadow'
                      : 'text-theme-secondary hover:bg-theme/[0.12] hover:text-theme-primary'
                    }`
                  }
                >
                  Payment Records
                </Tab>
              </Tab.List>
              
              <Tab.Panels className="mt-2">
                {/* Loan Info Panel */}
                <Tab.Panel className="rounded-xl p-3">
                  <div className="p-4 bg-theme border-theme rounded-lg">
                    <h3 className="text-lg font-medium mb-3 text-theme-primary">Loan Summary</h3>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      <div>
                        <p className="text-sm text-theme-tertiary">Issue Date</p>
                        <p className="font-medium text-theme-primary">{formattedDate}</p>
                      </div>
                      <div>
                        <p className="text-sm text-theme-tertiary">Principal</p>
                        <p className="font-medium text-theme-primary">₹{amount.toFixed(2)}</p>
                      </div>
                      <div>
                        <p className="text-sm text-theme-tertiary">Interest Rate</p>
                        <p className="font-medium text-theme-primary">{interestPercent}%</p>
                      </div>
                    </div>
                  </div>
                </Tab.Panel>
                
                {/* Items Panel */}
                <Tab.Panel className="rounded-xl p-3">
                  <h3 className="text-lg font-medium mb-3 text-theme-primary">Pledged Items</h3>
                  
                  {items && items.length > 0 ? (
                    <div className="overflow-x-auto bg-theme-secondary border border-theme rounded-lg shadow-sm">
                      <table className="loan-table w-full border-collapse min-w-max">
                        <thead>
                          <tr className="bg-theme-secondary border-b border-theme">
                            <th className="py-2 px-3 text-xs font-medium text-left text-theme-tertiary uppercase">Item name</th>
                            <th className="py-2 px-3 text-xs font-medium text-center text-theme-tertiary uppercase">Pcs</th>
                            <th className="py-2 px-3 text-xs font-medium text-right text-theme-tertiary uppercase">Gross Wt</th>
                            <th className="py-2 px-3 text-xs font-medium text-right text-theme-tertiary uppercase">Less Wt</th>
                            <th className="py-2 px-3 text-xs font-medium text-right text-theme-tertiary uppercase">Net Wt</th>
                            <th className="py-2 px-3 text-xs font-medium text-right text-theme-tertiary uppercase">Tunch</th>
                            <th className="py-2 px-3 text-xs font-medium text-right text-theme-tertiary uppercase">Wstg</th>
                            <th className="py-2 px-3 text-xs font-medium text-right text-theme-tertiary uppercase">Fine</th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-theme">
                          {items.map((item, index) => (
                            <tr key={index} className="hover:bg-theme-highlight">
                              <td className="p-2 text-theme-primary">{item.itemNameWithType || "N/A"}</td>
                              <td className="p-2 text-center text-theme-secondary">{item.pcs || "0"}</td>
                              <td className="p-2 text-right text-theme-secondary">
                                {typeof item.grossWt === 'number' ? item.grossWt.toFixed(3) : "0.000"}
                              </td>
                              <td className="p-2 text-right text-theme-secondary">
                                {typeof item.lessWt === 'number' ? item.lessWt.toFixed(3) : "0.000"}
                              </td>
                              <td className="p-2 text-right text-theme-secondary">
                                {typeof item.netWt === 'number' ? item.netWt.toFixed(3) : "0.000"}
                              </td>
                              <td className="p-2 text-right text-theme-secondary">
                                {typeof item.tunch === 'number' ? item.tunch.toFixed(2) : "0.00"}
                              </td>
                              <td className="p-2 text-right text-theme-secondary">
                                {typeof item.wstg === 'number' ? item.wstg.toFixed(2) : "0.00"}
                              </td>
                              <td className="p-2 text-right text-theme-secondary">
                                {typeof item.fine === 'number' ? item.fine.toFixed(3) : "0.000"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="p-4 bg-theme rounded text-center text-theme-tertiary">
                      No items found for this loan
                    </div>
                  )}
                </Tab.Panel>
                
                {/* Payment Records Panel */}
                <Tab.Panel className="rounded-xl p-3">
                  <h3 className="text-lg font-medium mb-3 text-theme-primary">Payment History</h3>
                  
                  {loading && (
                    <div className="flex justify-center items-center p-8">
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-accent"></div>
                      <span className="ml-2 text-theme-secondary">Loading payment history...</span>
                    </div>
                  )}
                  
                  {error && (
                    <div className="p-4 bg-red-50 border border-red-200 rounded-lg text-red-700 mb-4">
                      <p className="font-medium">Error loading payment history</p>
                      <p className="text-sm">{error}</p>
                    </div>
                  )}
                  
                  {!loading && !error && paymentHistory.length === 0 && (
                    <div className="p-6 bg-theme rounded-lg text-center">
                      <p className="text-theme-tertiary">No payment records found for this loan.</p>
                    </div>
                  )}
                  
                  {!loading && !error && paymentHistory.length > 0 && (
                    <>
                      <div className="overflow-x-auto bg-theme-secondary border border-theme rounded-lg shadow-sm mb-4">
                        <table className="loan-table w-full border-collapse min-w-max">
                          <thead>
                            <tr className="bg-theme-secondary border-b border-theme">
                              <th className="py-2 px-3 text-xs font-medium text-left text-theme-tertiary uppercase">Date</th>
                              <th className="py-2 px-3 text-xs font-medium text-left text-theme-tertiary uppercase">Type</th>
                              <th className="py-2 px-3 text-xs font-medium text-right text-theme-tertiary uppercase">Interest</th>
                              <th className="py-2 px-3 text-xs font-medium text-right text-theme-tertiary uppercase">Principal Paid</th>
                              <th className="py-2 px-3 text-xs font-medium text-right text-theme-tertiary uppercase">Remaining Principal</th>
                              <th className="py-2 px-3 text-xs font-medium text-right text-theme-tertiary uppercase">Status</th>
                              <th className="py-2 px-3 text-xs font-medium text-right text-theme-tertiary uppercase">Total</th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-theme">
                            {paymentHistory.map((payment, index) => (
                              <tr key={index} className="hover:bg-theme-highlight">
                                <td className="p-2 text-theme-primary">{dateUtils.formatDate(new Date(payment.date))}</td>
                                <td className="p-2 text-theme-secondary">{formatPaymentType(payment.paymentType)}</td>
                                <td className="p-2 text-right text-theme-secondary">
                                  <div>₹{payment.interest.toFixed(2)}</div>
                                  <div className="text-xs text-theme-tertiary">
                                    ({payment.interestPercent}%, {payment.timeInMonthsDays || `${payment.numberOfDays} days`})
                                  </div>
                                </td>
                                <td className="p-2 text-right text-theme-secondary">₹{payment.principalPaid.toFixed(2)}</td>
                                <td className="p-2 text-right text-theme-secondary">
                                  <div>₹{payment.remainingPrincipal.toFixed(2)}</div>
                                  <div className="text-xs text-theme-tertiary">
                                    ({Math.round((payment.remainingPrincipal / payment.originalPrincipal) * 100)}%)
                                  </div>
                                </td>
                                <td className="p-2 text-right">
                                  {payment.isFirstPaymentForLoan ? (
                                    <span className="text-blue-600 text-xs">First payment</span>
                                  ) : payment.paymentType === 'full' ? (
                                    <span className="text-green-600 text-xs">Loan closed</span>
                                  ) : payment.paymentType === 'interest_only' ? (
                                    <span className="text-amber-600 text-xs">Interest paid</span>
                                  ) : (
                                    <span className="text-indigo-600 text-xs">Custom payment</span>
                                  )}
                                  {payment.roundOff > 0 && (
                                    <div className="text-xs text-theme-tertiary mt-1">
                                      Round off: ₹{payment.roundOff.toFixed(2)}
                                    </div>
                                  )}
                                </td>
                                <td className="p-2 text-right font-medium text-theme-primary">₹{payment.total.toFixed(2)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      
                      {/* Payment Summary */}
                      {paymentSummary && (
                        <div className="bg-theme-highlight p-4 rounded-lg">
                          <h3 className="text-md font-medium mb-2 text-theme-primary">Payment Summary</h3>
                          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <div>
                              <p className="text-sm text-theme-tertiary">Total Interest Paid</p>
                              <p className="font-medium text-theme-primary">₹{paymentSummary.totalInterestPaid.toFixed(2)}</p>
                            </div>
                            <div>
                              <p className="text-sm text-theme-tertiary">Total Principal Paid</p>
                              <p className="font-medium text-theme-primary">₹{paymentSummary.totalPrincipalPaid.toFixed(2)}</p>
                            </div>
                            <div>
                              <p className="text-sm text-theme-tertiary">Total Amount Paid</p>
                              <p className="font-medium text-accent">₹{paymentSummary.totalAmountPaid.toFixed(2)}</p>
                            </div>
                          </div>
                          
                          <div className="mt-3 grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                              <p className="text-sm text-theme-tertiary">Round Off Amount</p>
                              <p className="font-medium text-theme-primary">₹{paymentSummary.totalRoundOff.toFixed(2)}</p>
                            </div>
                            <div>
                              <p className="text-sm text-theme-tertiary">Net Payment</p>
                              <p className="font-medium text-theme-primary">₹{paymentSummary.netPayment.toFixed(2)}</p>
                            </div>
                          </div>
                          
                          <div className="mt-3 border-t border-theme pt-3">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                              <div>
                                <p className="text-sm text-theme-tertiary">Last Payment Date</p>
                                <p className="font-medium text-theme-primary">
                                  {paymentSummary.lastPaymentDate
                                    ? dateUtils.formatDate(new Date(paymentSummary.lastPaymentDate))
                                    : 'None'}
                                </p>
                              </div>
                              <div>
                                <p className="text-sm text-theme-tertiary">Remaining Principal</p>
                                <p className="font-medium text-theme-primary">₹{paymentSummary.remainingPrincipal.toFixed(2)}</p>
                              </div>
                            </div>
                          </div>
                          
                          <div className="mt-3 text-sm text-theme-tertiary">
                            <p>
                              Payment types:
                              {paymentSummary.paymentTypes?.full && paymentSummary.paymentTypes?.full > 0 &&
                                ` ${paymentSummary.paymentTypes.full} full payment(s)`}
                              {paymentSummary.paymentTypes?.custom && paymentSummary.paymentTypes?.custom > 0 &&
                                ` ${paymentSummary.paymentTypes.custom} custom amount payment(s)`}
                              {paymentSummary.paymentTypes?.interest_only && paymentSummary.paymentTypes?.interest_only > 0 &&
                                ` ${paymentSummary.paymentTypes.interest_only} interest-only payment(s)`}
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};