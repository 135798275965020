export interface LoanItem {
  tagNo?: string;
  itemId: string | { _id: string; name: string; type: string };
  itemNameWithType: string;
  pcs: number;
  grossWt: string | number;
  lessWt: string | number;
  netWt: number;
  tunch: string | number;
  wstg: string | number;
  fine: number;
  rate: string | number;
  amount: number;
}

export interface Customer {
  _id: string;
  name: string;
  phone?: string;
  address?: string;
  city?: string;
  state?: string;
  pinCode?: string;
  creditLimit?: number;
  referredBy?: string;
  outstandingAmount?: number;
  userId?: string;
  createdAt?: string;
  updatedAt?: string;
  __v?: number;
}

export interface Loan {
  _id?: string;
  customer: string | { _id: string; name: string };
  selectedCustomer: string;
  date: Date;
  voucherNumber: number;
  items: LoanItem[];
  amount: number;
  monthlyInterestPercent: string;
  note?: string;
  createdAt?: string;
  updatedAt?: string;
  userId?: string;
  __v?: number;
}

export interface SelectedItem {
  itemId: string | { _id: string; name: string; type: string };
  itemNameWithType: string;
  type: string;
}

// API Response Types
export interface ApiResponse<T> {
  success: boolean;
  data: T;
  error?: string;
}

export interface LoanResponse {
  success: boolean;
  data: Loan;
  error?: string;
}

export interface LoansResponse {
  success: boolean;
  data: {
    loans: Loan[];
    nextVoucherNumber: number;
    pagination?: {
      total: number;
      page: number;
      pages: number;
    };
  };
  error?: string;
}

// Default values
export const DEFAULT_LOAN_ITEM: LoanItem = {
  tagNo: '',
  itemId: '',
  itemNameWithType: '',
  pcs: 0,
  grossWt: '0',
  lessWt: '0',
  netWt: 0,
  tunch: '0',
  wstg: '0',
  fine: 0,
  rate: '0',
  amount: 0
};

// Type guards
export const isValidLoanItem = (item: LoanItem): boolean => {
  const id = typeof item.itemId === 'string' ? item.itemId : item.itemId._id;
  return (
    item.itemNameWithType.trim() !== '' &&
    id.trim() !== '' &&
    item.netWt > 0
  );
};

// Helper function to get itemId value
export const getItemId = (itemId: string | { _id: string; name: string; type: string }): string => {
  if (typeof itemId === 'string') return itemId;
  return itemId._id || '';
};
