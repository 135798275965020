import React from 'react';
import { Loan } from '../../types/loan';
import { isValidDecimalInput } from '../../utils/calculations';
import { useLoanContext, loanActions } from '../../contexts/LoanContext';

interface LoanTotalsProps {
  loan: Loan;
  onMonthlyInterestChange: (value: string) => void;
  onNoteChange: (value: string) => void;
}

export const LoanTotals: React.FC<LoanTotalsProps> = ({
  loan,
  onMonthlyInterestChange,
  onNoteChange
}) => {
  const { dispatch } = useLoanContext();

  const handleInterestChange = (value: string) => {
    if (value === '' || isValidDecimalInput(value)) {
      onMonthlyInterestChange(value);
    }
  };

  const formatAmount = (amount: number) => amount.toFixed(2);

  return (
    <div className="mb-4">
      <table className="border-collapse border border-gray-300">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-1 w-48 font-semibold">Amt</th>
            <th className="border border-gray-300 px-4 py-1 w-48 font-semibold">Monthly Int %</th>
            <th className="border border-gray-300 px-4 py-1 w-96 font-semibold">Note</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 p-0">
              <input
                type="text"
                value={loan.amount}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === '' || isValidDecimalInput(value)) {
                    dispatch(loanActions.updateTotals({
                      amount: value === '' ? 0 : parseFloat(value),
                      monthlyInterestPercent: loan.monthlyInterestPercent
                    }));
                  }
                }}
                className="w-full px-2 py-1 text-right"
                onBlur={(e) => {
                  if (!e.target.value) {
                    dispatch(loanActions.updateTotals({
                      amount: 0,
                      monthlyInterestPercent: loan.monthlyInterestPercent
                    }));
                  }
                }}
              />
            </td>
            <td className="border border-gray-300 p-0">
              <input
                type="text"
                value={loan.monthlyInterestPercent}
                onChange={(e) => handleInterestChange(e.target.value)}
                className="w-full px-2 py-1 text-right"
              />
            </td>
            <td className="border border-gray-300 p-0">
              <input
                type="text"
                value={loan.note || ''}
                onChange={(e) => onNoteChange(e.target.value)}
                className="w-full px-2 py-1"
                placeholder="Enter note"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
