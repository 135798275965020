import { parseISO, format, isValid, differenceInDays } from 'date-fns';

/**
 * Utility functions for handling dates consistently throughout the application
 */
export const dateUtils = {
  /**
   * Parse an ISO date string to a Date object with validation
   * @param dateStr ISO date string
   * @returns Date object
   * @throws Error if date is invalid
   */
  parseDate: (dateStr: string): Date => {
    const parsed = parseISO(dateStr);
    if (!isValid(parsed)) {
      throw new Error(`Invalid date format: ${dateStr}`);
    }
    return parsed;
  },
  
  /**
   * Format a date for display
   * @param date Date object or ISO string
   * @returns Formatted date string (DD-MM-YYYY)
   */
  formatDate: (date: Date | string): string => {
    const dateObj = typeof date === 'string' ? parseISO(date) : date;
    return format(dateObj, 'dd-MM-yyyy');
  },
  
  /**
   * Calculate the number of days between two dates
   * @param startDate Start date (Date object or ISO string)
   * @param endDate End date (Date object or ISO string)
   * @returns Number of days (always positive)
   * @throws Error if either date is invalid
   */
  daysBetween: (startDate: Date | string, endDate: Date | string): number => {
    const start = typeof startDate === 'string' ? parseISO(startDate) : startDate;
    const end = typeof endDate === 'string' ? parseISO(endDate) : endDate;
    
    if (!isValid(start) || !isValid(end)) {
      throw new Error('Invalid date provided for daysBetween calculation');
    }
    
    // Always return positive number of days
    return Math.abs(differenceInDays(end, start));
  },
  
  /**
   * Calculate time in years with months precision plus fractional years for days
   * @param startDate Starting date
   * @param endDate Ending date
   * @returns Time in years as a decimal
   */
  calculateTimeInYears: (startDate: Date | string, endDate: Date | string): number => {
    const start = typeof startDate === 'string' ? parseISO(startDate) : startDate;
    const end = typeof endDate === 'string' ? parseISO(endDate) : endDate;
    
    if (!isValid(start) || !isValid(end)) {
      throw new Error('Invalid date provided for calculateTimeInYears calculation');
    }
    
    // Ensure we calculate with positive time - if dates are reversed, swap them
    let startDate2 = start;
    let endDate2 = end;
    
    if (startDate2 > endDate2) {
      console.warn('[DEBUG] Start date later than end date. Swapping dates for calculation.');
      [startDate2, endDate2] = [endDate2, startDate2];
    }
    
    // Calculate full months between dates
    const startYear = startDate2.getFullYear();
    const startMonth = startDate2.getMonth();
    const startDay = startDate2.getDate();
    
    const endYear = endDate2.getFullYear();
    const endMonth = endDate2.getMonth();
    const endDay = endDate2.getDate();
    
    // Calculate months difference
    let monthsDiff = (endYear - startYear) * 12 + (endMonth - startMonth);
    
    // Adjust if end day is earlier than start day
    if (endDay < startDay) {
      monthsDiff--;
    }
    
    // Get years from full months
    const yearsFromMonths = monthsDiff / 12;
    
    // Calculate remaining days for fractional part
    const remainingStartDate = new Date(startDate2);
    remainingStartDate.setMonth(remainingStartDate.getMonth() + monthsDiff);
    
    // Get days difference for the remaining part
    const daysDiff = Math.max(0, differenceInDays(endDate2, remainingStartDate));
    
    // Convert days to fractional years (using exact 365.25 day year for precision)
    const yearsFromDays = daysDiff / 365.25;
    
    // Total time in years
    const result = yearsFromMonths + yearsFromDays;
    // Removed excessive logging that was contributing to the loop and browser slowdown
    
    return result;
  },
  
  /**
   * Check if a date is valid
   * @param date Date to check
   * @returns boolean indicating if date is valid
   */
  isValidDate: (date: Date | string): boolean => {
    if (typeof date === 'string') {
      try {
        return isValid(parseISO(date));
      } catch (e) {
        return false;
      }
    }
    return isValid(date);
  },
  
  /**
   * Get today's date at midnight
   * @returns Today's date with time set to 00:00:00
   */
  getTodayDate: (): Date => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  },
};