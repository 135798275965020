import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Customer } from '../../types/loan';
import { CustomerSearch } from '../CustomerSearch';
import { useLoanContext } from '../../contexts/LoanContext';

interface LoanHeaderProps {
  date: Date;
  voucherNumber: number;
  onCustomerSelect: (customer: Customer) => void;
  onDateChange?: (date: Date) => void;
  customerName?: string;
}

export const LoanHeader: React.FC<LoanHeaderProps> = ({
  date,
  voucherNumber,
  onCustomerSelect,
  onDateChange,
  customerName = ''
}) => {
  const { state } = useLoanContext();

  const handleCustomerSelect = (customer: Customer) => {
    console.log('Customer selected in LoanHeader:', customer);
    onCustomerSelect(customer);
  };

  return (
    <div className="mb-4">
      <div className="flex items-start gap-4">
        <div>
          <div className="font-semibold mb-1">Date:</div>
          <DatePicker
            selected={date}
            onChange={(newDate: Date | null) => {
              if (newDate && onDateChange) {
                onDateChange(newDate);
              }
            }}
            dateFormat="dd-MM-yyyy"
            className="border border-gray-300 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 w-32"
            placeholderText="Select date"
          />
        </div>

        <div className="flex-1">
          <div className="flex items-center gap-2 mb-1">
            <label className="font-semibold whitespace-nowrap">Customer Name:</label>
          </div>
          <div className="relative">
            <CustomerSearch 
              onCustomerSelect={handleCustomerSelect}
              initialCustomerName={customerName}
            />
            {state.isLoading && (
              <div className="absolute right-3 top-2">
                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-500"></div>
              </div>
            )}
          </div>
          {state.error && (
            <div className="text-red-500 mt-1">{state.error}</div>
          )}
        </div>

        <div className="flex items-center">
          <div className="font-semibold whitespace-nowrap">
            Voucher Number: {voucherNumber}
          </div>
        </div>
      </div>
    </div>
  );
};
