import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useRepaymentContext, repaymentActions } from '../contexts/RepaymentContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { RepaymentHeader } from './RepaymentHeader';
import { CompactRepaymentItems } from './RepaymentItems';
import { CollapsibleLoanSearch } from './CollapsibleLoanSearch';
import { CompactNavigationFooter } from './NavigationFooter';
import { SlidingRepaymentHistory } from './SlidingRepaymentHistory';
import { Customer } from '../../loanIssue/types/loan';
import { repaymentApi } from '../api/repaymentApi';
import { customerApi } from '../../customerBill2/api/customerApi';
import { useRepaymentForm } from '../hooks/useRepaymentForm';
import { Card } from '../../../styles/components';

interface SimplifiedRepaymentFormProps {
  repaymentId?: string;
}

export const SimplifiedRepaymentForm: React.FC<SimplifiedRepaymentFormProps> = ({ repaymentId }) => {
  const { state, dispatch } = useRepaymentContext();
  const auth = useContext(AuthContext);
  const [isInitialLoadDone, setIsInitialLoadDone] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Load initial data when component mounts
  useEffect(() => {
    let mounted = true;
    let abortController = new AbortController();

    const loadInitialData = async () => {
      if (isInitialLoadDone || !localStorage.getItem('token')) return;

      try {
        dispatch(repaymentActions.setLoading(true));
        dispatch(repaymentActions.setError(null));
        setError(null);
        
        // Load customers and repayments in parallel
        const [customersResponse, repaymentsResponse] = await Promise.all([
          customerApi.getCustomers(),
          repaymentApi.fetchRepayments()
        ]);

        if (!mounted) return;

        // Process customers data
        if (Array.isArray(customersResponse) && customersResponse.length > 0) {
          dispatch(repaymentActions.setCustomers(customersResponse));
          dispatch(repaymentActions.setFilteredCustomers(customersResponse));
        }

        // Process repayments data
        if (repaymentsResponse) {
          // Initialize with next voucher number even if no repayments exist
          dispatch(repaymentActions.setRepayment({
            ...state.repayment,
            voucherNumber: repaymentsResponse.nextVoucherNumber || 1
          }));
        }

        setIsInitialLoadDone(true);
      } catch (error) {
        if (!mounted) return;
        
        const errorMessage = error instanceof Error ? error.message : 'Failed to load initial data';
        setError(errorMessage);
        dispatch(repaymentActions.setError(errorMessage));
      } finally {
        if (mounted) {
          dispatch(repaymentActions.setLoading(false));
        }
      }
    };

    // Only load if we have auth and token
    if (auth?.user && localStorage.getItem('token')) {
      loadInitialData();
    }

    return () => {
      mounted = false;
      abortController.abort();
    };
  }, [dispatch, state.repayment, repaymentId, auth?.user, isInitialLoadDone]);

  const {
    repayment,
    isLoading: formLoading,
    error: formError,
    handleCustomerSelect,
    handleRepaymentItemSelect,
    handleSubmit,
    handleDateChange,
    handlePreviousRepayment,
    handleNextRepayment,
    handleViewPreviousRepayments
  } = useRepaymentForm(repaymentId);

  const onCustomerSelect = useCallback((customer: Customer) => {
    handleCustomerSelect(customer);
  }, [handleCustomerSelect]);

  const onSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    // Validate the form before submission
    if (!repayment.customer || !repayment.selectedCustomer) {
      dispatch(repaymentActions.setError('Please select a customer first'));
      return;
    }
    
    if (repayment.repaymentItems.length === 0) {
      dispatch(repaymentActions.setError('Please select at least one loan to repay'));
      return;
    }
    
    try {
      await handleSubmit();
    } catch (error) {
    }
  }, [handleSubmit, repayment.customer, repayment.selectedCustomer, repayment.repaymentItems.length, dispatch]);

  const onDateChange = useCallback((date: Date) => {
    handleDateChange(date);
  }, [handleDateChange]);

  const handleEdit = useCallback(async (editRepaymentId: string) => {
    try {
      dispatch(repaymentActions.setLoading(true));
      dispatch(repaymentActions.setError(null));
      
      const repaymentData = await repaymentApi.fetchRepaymentDetails(editRepaymentId);
      dispatch(repaymentActions.setRepayment({
        ...repaymentData.data,
        date: new Date(repaymentData.data.date)
      }));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to load repayment';
      dispatch(repaymentActions.setError(errorMessage));
    } finally {
      dispatch(repaymentActions.setLoading(false));
    }
  }, [dispatch]);

  // Show loading state while initializing
  if (!isInitialLoadDone || state.isLoading || formLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-theme-secondary">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-accent"></div>
      </div>
    );
  }

  // Show error state
  if (error || state.error || formError) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-theme-secondary">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-md relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error || state.error || formError}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-theme-secondary min-h-screen py-6 pb-20">
      <form onSubmit={onSubmit} className="space-y-4 max-w-6xl mx-auto px-4">
        {/* Header Section with Customer Selection and Date - Keep original layout */}
        <Card>
          <RepaymentHeader
            date={repayment.date}
            voucherNumber={repayment.voucherNumber}
            onCustomerSelect={onCustomerSelect}
            onDateChange={onDateChange}
            customerName={repayment.selectedCustomer}
          />
        </Card>
        
        {/* Collapsible Loan Selection Section */}
        <Card>
          <CollapsibleLoanSearch
            items={repayment.repaymentItems}
            onSelectItems={handleRepaymentItemSelect}
            customerId={repayment.customer}
          />
        </Card>

        {/* Payment Details - Only show when loans are selected */}
        {repayment.repaymentItems.length > 0 && (
          <Card>
            {/* This would use the detailed payment table from CompactRepaymentItems */}
            <CompactRepaymentItems
              items={repayment.repaymentItems}
              onSelectItems={handleRepaymentItemSelect}
              customerId={repayment.customer}
              hideAvailableLoans={true} // We're adding this prop to hide the available loans table
            />
          </Card>
        )}

        {/* Navigation Footer */}
        <CompactNavigationFooter
          repayment={repayment}
          repaymentId={repaymentId}
          onPreviousRepayment={handlePreviousRepayment}
          onNextRepayment={handleNextRepayment}
          onViewPreviousRepayments={handleViewPreviousRepayments}
          onSubmit={onSubmit}
        />
      </form>

      {/* Sliding History Panel */}
      <SlidingRepaymentHistory onEdit={handleEdit} />
    </div>
  );
};