import { useEffect, useState, useCallback } from 'react';
import { useLoanContext, loanActions } from '../contexts/LoanContext';
import { loanApi } from '../api/loanApi';
import { Customer, LoanItem, isValidLoanItem, SelectedItem } from '../types/loan';
import { calculateNetWeight, calculateFine, calculateAmount } from '../utils/calculations';
import { DEFAULT_LOAN_ITEM } from '../types/loan';
import { parseISO } from 'date-fns';

export type LoanItemStringFields = keyof Pick<LoanItem, 'itemId' | 'itemNameWithType' | 'tagNo' | 'grossWt' | 'lessWt' | 'tunch' | 'wstg' | 'rate' | 'pcs'>;

const ensureDateObject = (date: Date | string): Date => {
  if (date instanceof Date) return date;
  if (typeof date === 'string') return parseISO(date);
  return new Date();
};

export const useLoanForm = (initialLoanId?: string) => {
  const { state, dispatch } = useLoanContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [currentLoanId, setCurrentLoanId] = useState<string | undefined>(initialLoanId);

  // Load initial loan only once when initialLoanId changes
  useEffect(() => {
    let mounted = true;
    
    if (initialLoanId) {
      (async () => {
        try {
          setIsLoading(true);
          const response = await loanApi.fetchLoanDetails(initialLoanId);
          if (mounted) {
            dispatch(loanActions.setLoan({
              ...response.data,
              date: ensureDateObject(response.data.date)
            }));
            setCurrentLoanId(initialLoanId);
          }
        } catch (err) {
          if (mounted) {
            setError(err instanceof Error ? err.message : 'Failed to load loan');
          }
        } finally {
          if (mounted) {
            setIsLoading(false);
          }
        }
      })();
    }

    return () => {
      mounted = false;
    };
  }, [initialLoanId, dispatch]);

  const resetForm = useCallback((nextVoucherNumber: number) => {
    dispatch(loanActions.setLoan({
      ...state.loan,
      voucherNumber: nextVoucherNumber,
      customer: '',
      selectedCustomer: '',
      items: [{ ...DEFAULT_LOAN_ITEM }],
      amount: 0,
      monthlyInterestPercent: '0',
      note: '',
      date: new Date()
    }));
    setCurrentLoanId(undefined);
  }, [dispatch, state.loan]);

  const handleCustomerSelect = useCallback((customer: Customer) => {
    if (!customer._id || !customer.name) return;

    dispatch(loanActions.setLoan({
      ...state.loan,
      customer: customer._id,
      selectedCustomer: customer.name
    }));
  }, [dispatch, state.loan]);

  const handleLoanItemSelect = useCallback((index: number, selectedItem: SelectedItem) => {
    const updatedItems = [...state.loan.items];
    updatedItems[index] = {
      ...updatedItems[index],
      itemId: typeof selectedItem.itemId === 'string' ? selectedItem.itemId : selectedItem.itemId._id,
      itemNameWithType: selectedItem.itemNameWithType
    };
    
    dispatch(loanActions.updateLoanItems(updatedItems));
  }, [dispatch, state.loan]);

  const handleLoanItemChange = useCallback((index: number, field: keyof LoanItem, value: string) => {
    const updatedItems = [...state.loan.items];
    const currentItem = { ...updatedItems[index] };
    
    if (field === 'pcs') {
      currentItem.pcs = value === '' ? 0 : parseInt(value, 10);
    } else {
      (currentItem as any)[field] = value;
    }
    
    if (['grossWt', 'lessWt', 'tunch', 'wstg', 'rate'].includes(field)) {
      const netWt = calculateNetWeight(currentItem.grossWt, currentItem.lessWt);
      const fine = calculateFine(netWt, currentItem.tunch, currentItem.wstg);
      const amount = calculateAmount(fine, currentItem.rate);
      
      currentItem.netWt = netWt;
      currentItem.fine = fine;
      currentItem.amount = amount;
    }
    
    updatedItems[index] = currentItem;
    dispatch(loanActions.updateLoanItems(updatedItems));
  }, [dispatch, state.loan]);

  const handleDateChange = useCallback((date: Date) => {
    dispatch(loanActions.setLoan({
      ...state.loan,
      date
    }));
  }, [dispatch, state.loan]);

  const handleMonthlyInterestChange = useCallback((value: string) => {
    dispatch(loanActions.updateTotals({
      amount: state.loan.amount,
      monthlyInterestPercent: value
    }));
  }, [dispatch, state.loan.amount]);

  const handleNoteChange = useCallback((value: string) => {
    dispatch(loanActions.setLoan({
      ...state.loan,
      note: value
    }));
  }, [dispatch, state.loan]);

  const addLoanItem = useCallback(() => {
    dispatch(loanActions.updateLoanItems([...state.loan.items, { ...DEFAULT_LOAN_ITEM }]));
  }, [dispatch, state.loan.items]);

  const validateLoan = useCallback(() => {
    const validItems = state.loan.items.filter(isValidLoanItem);

    if (validItems.length === 0) {
      throw new Error('At least one valid item with name, ID, and weight is required');
    }

    return {
      validItems
    };
  }, [state.loan]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setError(null);

      const { validItems } = validateLoan();

      const loanData = {
        ...state.loan,
        items: validItems
      };

      if (currentLoanId) {
        await loanApi.updateLoan(currentLoanId, loanData);
        const loansResponse = await loanApi.fetchLoans();
        resetForm(loansResponse.data.nextVoucherNumber);
      } else {
        const createResponse = await loanApi.createLoan(loanData);
        resetForm(createResponse.data.nextVoucherNumber);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save loan');
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreviousLoan = useCallback(async () => {
    if (!state.loan.voucherNumber) return;
    
    try {
      setIsLoading(true);
      setError(null);
      const response = await loanApi.fetchNearestPreviousLoan(state.loan.voucherNumber);
      dispatch(loanActions.setLoan({
        ...response.data,
        date: ensureDateObject(response.data.date)
      }));
      setCurrentLoanId(response.data._id);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to load previous loan';
      if (!errorMessage.includes('No previous loan found')) {
        setError(errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, state.loan.voucherNumber]);

  const handleNextLoan = useCallback(async () => {
    if (!state.loan.voucherNumber) return;
    
    try {
      setIsLoading(true);
      setError(null);
      const response = await loanApi.fetchNearestNextLoan(state.loan.voucherNumber);
      dispatch(loanActions.setLoan({
        ...response.data,
        date: ensureDateObject(response.data.date)
      }));
      setCurrentLoanId(response.data._id);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : '';
      if (errorMessage.includes('No next loan found')) {
        const loansResponse = await loanApi.fetchLoans();
        resetForm(loansResponse.data.nextVoucherNumber);
      } else {
        setError(errorMessage || 'Failed to load next loan');
      }
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, state.loan.voucherNumber, resetForm]);

  const handleViewPreviousLoans = useCallback(() => {
    dispatch(loanActions.setPreviousLoansVisible(true));
  }, [dispatch]);

  return {
    loan: state.loan,
    isLoading,
    error,
    handleCustomerSelect,
    handleLoanItemChange,
    handleLoanItemSelect,
    handleSubmit,
    handleDateChange,
    handleMonthlyInterestChange,
    handleNoteChange,
    addLoanItem,
    handlePreviousLoan,
    handleNextLoan,
    handleViewPreviousLoans,
    resetForm
  };
};
