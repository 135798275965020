import React from 'react';
import { useTheme } from '../contexts/ThemeContext';
import type { ThemeMode } from '../styles/theme';

interface ThemeOptionProps {
  theme: ThemeMode;
  currentTheme: ThemeMode;
  onClick: () => void;
  icon: React.ReactNode;
  label: string;
}

const ThemeOption: React.FC<ThemeOptionProps> = ({ 
  theme, 
  currentTheme, 
  onClick, 
  icon, 
  label 
}) => (
  <button 
    onClick={onClick}
    className={`
      p-2 rounded-full transition-colors duration-200 ease-in-out
      ${currentTheme === theme 
        ? 'bg-primary-100 text-primary-700 dark:bg-primary-800 dark:text-primary-200' 
        : 'text-gray-600 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-800'
      }
    `}
    aria-label={label}
    title={label}
  >
    {icon}
  </button>
);

const ThemeSwitcher: React.FC = () => {
  const { theme, setTheme } = useTheme();

  const themeOptions: Array<{
    value: ThemeMode;
    label: string;
    icon: React.ReactNode;
  }> = [
    {
      value: 'light',
      label: 'Light theme',
      icon: (
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth="2" 
            d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" 
          />
        </svg>
      )
    },
    {
      value: 'dark',
      label: 'Dark theme',
      icon: (
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth="2" 
            d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" 
          />
        </svg>
      )
    },
    {
      value: 'gold',
      label: 'Gold theme',
      icon: (
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <circle cx="12" cy="12" r="8" strokeWidth="2" />
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth="2" 
            d="M12 8v8m-4-4h8" 
          />
        </svg>
      )
    },
    {
      value: 'silver',
      label: 'Silver theme',
      icon: (
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <circle cx="12" cy="12" r="8" strokeWidth="2" />
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth="2" 
            d="M12 8v8" 
          />
        </svg>
      )
    }
  ];

  return (
    <div className="flex items-center space-x-2">
      {themeOptions.map((option) => (
        <ThemeOption
          key={option.value}
          theme={option.value}
          currentTheme={theme}
          onClick={() => setTheme(option.value)}
          icon={option.icon}
          label={option.label}
        />
      ))}
    </div>
  );
};

export default ThemeSwitcher;