import { useEffect, useState, useCallback, useRef } from 'react';
import { useRepaymentContext, repaymentActions } from '../contexts/RepaymentContext';
import { Customer } from '../../loanIssue/types/loan';
import { repaymentApi } from '../api/repaymentApi';
import { RepaymentItem } from '../types/repayment';
import { dateUtils } from '../utils/dateUtils';
import { calculateRepaymentItem } from '../utils/calculations';
import axios from 'axios';

// Auto-save key for local storage
const DRAFT_STORAGE_KEY = 'repayment_form_draft';

export const useRepaymentForm = (initialRepaymentId?: string) => {
  const { state, dispatch } = useRepaymentContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [currentRepaymentId, setCurrentRepaymentId] = useState<string | undefined>(initialRepaymentId);
  const [isDirty, setIsDirty] = useState(false);
  // Add a ref to track if we've already shown the confirmation dialog
  const hasCheckedLocalStorage = useRef(false);

  // Load initial repayment if ID is provided
  useEffect(() => {
    let mounted = true;
    
    if (initialRepaymentId) {
      (async () => {
        try {
          setIsLoading(true);
          setError(null);
          const response = await repaymentApi.fetchRepaymentDetails(initialRepaymentId);
          if (mounted && response.data) {
            dispatch(repaymentActions.setRepayment({
              ...response.data,
              date: dateUtils.isValidDate(response.data.date) 
                ? new Date(response.data.date) 
                : new Date()
            }));
            setCurrentRepaymentId(initialRepaymentId);
          }
        } catch (err) {
          if (mounted) {
            const errorMessage = err instanceof Error ? err.message : 'Failed to load repayment';
            setError(errorMessage);
            console.error('Error loading repayment:', err);
          }
        } finally {
          if (mounted) {
            setIsLoading(false);
          }
        }
      })();
    } else {
      // Check for saved draft when creating new repayment
      // Only check localStorage once during component initialization
      if (!hasCheckedLocalStorage.current) {
        hasCheckedLocalStorage.current = true;
        
        const savedDraft = localStorage.getItem(DRAFT_STORAGE_KEY);
        if (savedDraft) {
          try {
            const parsedDraft = JSON.parse(savedDraft);
            const timestamp = new Date(parsedDraft.timestamp);
            
            // Only restore if saved within last 24 hours
            const isRecent = (new Date().getTime() - timestamp.getTime()) < 24 * 60 * 60 * 1000;
            
            // Using a single confirmation dialog
            if (isRecent && window.confirm('Do you want to restore your previous unsaved work?')) {
              dispatch(repaymentActions.setRepayment({
                ...parsedDraft,
                date: new Date(parsedDraft.date)
              }));
              setIsDirty(true);
            } else {
              // Clear old data
              localStorage.removeItem(DRAFT_STORAGE_KEY);
            }
          } catch (error) {
            console.error('Failed to parse saved form data', error);
            localStorage.removeItem(DRAFT_STORAGE_KEY);
          }
        }
      }
    }

    return () => {
      mounted = false;
    };
  }, [initialRepaymentId, dispatch]);

  // Auto-save form data periodically
  useEffect(() => {
    if (!isDirty) return;
    
    // Save form data to localStorage every 30 seconds
    const intervalId = setInterval(() => {
      const formData = {
        customer: state.repayment.customer,
        selectedCustomer: state.repayment.selectedCustomer,
        date: state.repayment.date.toISOString(),
        repaymentItems: state.repayment.repaymentItems,
        timestamp: new Date().toISOString(),
        voucherNumber: state.repayment.voucherNumber
      };
      
      localStorage.setItem(DRAFT_STORAGE_KEY, JSON.stringify(formData));
      console.log('Auto-saved repayment form draft');
    }, 30000);
    
    return () => clearInterval(intervalId);
  }, [isDirty, state.repayment]);

  // Track form changes to detect dirty state and save immediately
  useEffect(() => {
    if (state.repayment.customer || state.repayment.repaymentItems.length > 0) {
      setIsDirty(true);
      
      // Save form data immediately when changes are detected
      const formData = {
        customer: state.repayment.customer,
        selectedCustomer: state.repayment.selectedCustomer,
        date: state.repayment.date.toISOString(),
        repaymentItems: state.repayment.repaymentItems,
        timestamp: new Date().toISOString(),
        voucherNumber: state.repayment.voucherNumber
      };
      
      localStorage.setItem(DRAFT_STORAGE_KEY, JSON.stringify(formData));
      console.log('Immediately saved form data after change');
    }
  }, [state.repayment.customer, state.repayment.repaymentItems, state.repayment.date, state.repayment.selectedCustomer, state.repayment.voucherNumber]);

  // Enhanced reset function with confirmation and draft clearing
  const resetForm = useCallback((nextVoucherNumber: number, skipConfirmation: boolean = false) => {
    const performReset = () => {
      dispatch(repaymentActions.resetRepayment(nextVoucherNumber));
      setCurrentRepaymentId(undefined);
      setError(null);
      setIsDirty(false);
      // Clear any stored form data
      localStorage.removeItem(DRAFT_STORAGE_KEY);
    };
    
    if (skipConfirmation || !isDirty) {
      performReset();
      return;
    }
    
    // Show confirmation dialog
    if (window.confirm('You have unsaved changes. Are you sure you want to reset the form?')) {
      performReset();
    }
  }, [dispatch, isDirty]);

  const handleCustomerSelect = useCallback((customer: Customer) => {
    if (!customer._id || !customer.name) {
      setError('Invalid customer selection');
      return;
    }

    // Validate MongoDB ObjectId format - must be 24 characters
    if (typeof customer._id !== 'string' || customer._id.length !== 24) {
      console.error('Invalid customer ID format:', {
        id: customer._id,
        type: typeof customer._id,
        length: typeof customer._id === 'string' ? customer._id.length : 'N/A'
      });
      setError('Invalid customer ID format - cannot proceed with loan search');
      return;
    }

    console.log('Selected valid customer:', {
      id: customer._id,
      name: customer.name,
      idLength: customer._id.length
    });

    dispatch(repaymentActions.setRepayment({
      ...state.repayment,
      customer: customer._id,
      selectedCustomer: customer.name
    }));
    setError(null);
    setIsDirty(true);
  }, [dispatch, state.repayment]);

  const handleDateChange = useCallback((date: Date) => {
    if (!dateUtils.isValidDate(date)) {
      setError('Invalid date selected');
      return;
    }

    dispatch(repaymentActions.setRepayment({
      ...state.repayment,
      date
    }));
    
    // Recalculate interest and totals for all items when date changes
    if (state.repayment.repaymentItems.length > 0) {
      const updatedItems = state.repayment.repaymentItems.map(item => {
        const calculatedValues = calculateRepaymentItem({
          issueDate: item.issueDate,
          oldRemainingPrincipal: item.originalPrincipal, // Use the updated parameter name
          monthlyInterestPercent: item.monthlyInterestPercent
        }, date);
        
        return {
          ...item,
          ...calculatedValues
        };
      });
      
      dispatch(repaymentActions.updateRepaymentItems(updatedItems));
    }
    
    setError(null);
    setIsDirty(true);
  }, [dispatch, state.repayment]);

  const handleRepaymentItemSelect = useCallback((items: RepaymentItem[]) => {
    if (!Array.isArray(items)) {
      setError('Invalid items selection');
      return;
    }
    
    dispatch(repaymentActions.updateRepaymentItems(items));
    setError(null);
    setIsDirty(true);
  }, [dispatch]); // No additional dependencies needed here since this is just forwarding items

  // Validate the repayment data before submitting
  const validateRepayment = useCallback(() => {
    if (!state.repayment.customer) {
      setError('Please select a customer');
      return false;
    }

    if (!state.repayment.repaymentItems.length) {
      setError('Please add at least one repayment item');
      return false;
    }

    if (!dateUtils.isValidDate(state.repayment.date)) {
      setError('Invalid repayment date');
      return false;
    }

    // Check if any item has negative or zero values
    const invalidItems = state.repayment.repaymentItems.filter(
      item => item.originalPrincipal <= 0 || item.interest < 0 || item.amountPayable <= 0
    );
    
    if (invalidItems.length > 0) {
      setError('One or more items have invalid amounts or calculations');
      return false;
    }

    return true;
  }, [state.repayment]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setError(null);

      // Validate form data
      if (!validateRepayment()) {
        setIsLoading(false);
        return;
      }

      const repaymentData = {
        ...state.repayment
      };

      if (currentRepaymentId) {
        await repaymentApi.updateRepayment(currentRepaymentId, repaymentData);
        const repaymentsResponse = await repaymentApi.fetchRepayments();
        resetForm(repaymentsResponse.nextVoucherNumber, true);
        
        // Show success message
        alert('Repayment updated successfully');
      } else {
        const createResponse = await repaymentApi.createRepayment(repaymentData);
        resetForm(createResponse.data.nextVoucherNumber, true);
        
        // Show success message
        alert('Repayment saved successfully');
      }
      
      // Clear any saved draft
      localStorage.removeItem(DRAFT_STORAGE_KEY);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to save repayment';
      setError(errorMessage);
      console.error('Submit error:', err);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreviousRepayment = useCallback(async () => {
    if (!state.repayment.voucherNumber) return;
    
    try {
      // Ask for confirmation if form is dirty
      if (isDirty && !window.confirm('You have unsaved changes. Are you sure you want to navigate away?')) {
        return;
      }
      
      setIsLoading(true);
      setError(null);
      
      const response = await repaymentApi.fetchNearestPreviousRepayment(state.repayment.voucherNumber);
      if (response.data) {
        dispatch(repaymentActions.setRepayment({
          ...response.data,
          date: dateUtils.isValidDate(response.data.date) 
            ? new Date(response.data.date) 
            : new Date()
        }));
        
        setCurrentRepaymentId(response.data._id);
        setIsDirty(false);
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : '';
      if (!errorMessage.includes('No previous repayment found')) {
        setError(errorMessage || 'Failed to load previous repayment');
      } else {
        setError('You are viewing the first repayment');
      }
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, state.repayment.voucherNumber, isDirty]);

  const handleNextRepayment = useCallback(async () => {
    if (!state.repayment.voucherNumber) return;
    
    try {
      // Ask for confirmation if form is dirty
      if (isDirty && !window.confirm('You have unsaved changes. Are you sure you want to navigate away?')) {
        return;
      }
      
      setIsLoading(true);
      setError(null);
      
      const response = await repaymentApi.fetchNearestNextRepayment(state.repayment.voucherNumber);
      
      if (response.data) {
        dispatch(repaymentActions.setRepayment({
          ...response.data,
          date: dateUtils.isValidDate(response.data.date) 
            ? new Date(response.data.date) 
            : new Date()
        }));
        
        setCurrentRepaymentId(response.data._id);
        setIsDirty(false);
      }
    } catch (err) {
      console.log('Next repayment error:', err);
      // Check for 404 error or specific error messages
      const errorMessage = err instanceof Error ? err.message : '';
      const is404Error = axios.isAxiosError(err as unknown) && (err as any).response?.status === 404;
      
      if (errorMessage.includes('No next repayment found') || errorMessage.includes('Resource not found') || is404Error) {
        // If no next repayment, create a new one
        try {
          const repaymentsResponse = await repaymentApi.fetchRepayments();
          resetForm(repaymentsResponse.nextVoucherNumber, true);
          setError(null); // Clear any previous errors
        } catch (fetchError) {
          console.error('Error fetching next voucher number:', fetchError);
          setError('Failed to create new repayment form');
        }
      } else {
        setError(errorMessage || 'Failed to load next repayment');
      }
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, state.repayment.voucherNumber, resetForm, isDirty]);

  const handleViewPreviousRepayments = useCallback(() => {
    dispatch(repaymentActions.setPreviousRepaymentsVisible(true));
  }, [dispatch]);

  return {
    repayment: state.repayment,
    isLoading,
    error,
    isDirty,
    handleCustomerSelect,
    handleRepaymentItemSelect,
    handleSubmit,
    handleDateChange,
    handlePreviousRepayment,
    handleNextRepayment,
    handleViewPreviousRepayments,
    resetForm,
    validateRepayment
  };
};
