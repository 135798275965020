/**
 * Utility functions for loan repayment calculations and formatting
 */

/**
 * Formats a time period from days to a more human-readable months + days format
 * with improved readability for the loan repayment UI
 *
 * @param days Number of days
 * @returns Formatted string like "3 months 15 days" or shorter formats as appropriate
 */
export function formatTimePeriod(days: number): string {
  if (!days && days !== 0) return '--';
  
  // Use more accurate month calculation (average month length)
  const months = Math.floor(days / 30.44);
  const remainingDays = Math.round(days % 30.44);
  
  // Return appropriate format based on values
  if (months === 0) {
    return remainingDays === 1 ? `${remainingDays} day` : `${remainingDays} days`;
  } else if (remainingDays === 0) {
    return months === 1 ? `${months} month` : `${months} months`;
  } else {
    // Full format with both months and days
    const monthText = months === 1 ? 'month' : 'months';
    const dayText = remainingDays === 1 ? 'day' : 'days';
    return `${months} ${monthText} ${remainingDays} ${dayText}`;
  }
}

/**
 * Formats a time period in a compact format for space-constrained UI elements
 *
 * @param days Number of days
 * @returns Formatted string like "3m 15d" (3 months and 15 days)
 */
export function formatTimePeriodCompact(days: number): string {
  if (!days && days !== 0) return '--';
  
  const months = Math.floor(days / 30.44);
  const remainingDays = Math.round(days % 30.44);
  
  if (months === 0) {
    return `${remainingDays}d`;
  } else if (remainingDays === 0) {
    return `${months}m`;
  } else {
    return `${months}m ${remainingDays}d`;
  }
}

/**
 * Formats interest details including rate and time period
 * @param interestPercent Monthly interest rate percentage
 * @param days Number of days for interest calculation
 * @returns Formatted string like "(2%, 3m 15d)"
 */
export function formatInterestDetails(interestPercent: string, days: number): string {
  const timeString = formatTimePeriodCompact(days);
  return `(${interestPercent}%, ${timeString})`;
}

/**
 * Formats detailed interest information with last payment date reference
 *
 * @param interestPercent Monthly interest rate percentage
 * @param days Number of days for interest calculation
 * @param lastPaymentDate Optional date of last payment
 * @returns Formatted string like "2% for 3 months 15 days (from 10-05-2025)"
 */
export function formatDetailedInterestInfo(
  interestPercent: string,
  days: number,
  lastPaymentDate?: string | Date | null
): string {
  const timeString = formatTimePeriod(days);
  
  if (!lastPaymentDate) {
    return `${interestPercent}% for ${timeString}`;
  }
  
  // Format the date if provided
  const dateString = typeof lastPaymentDate === 'string'
    ? new Date(lastPaymentDate).toLocaleDateString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric' })
    : lastPaymentDate instanceof Date
      ? lastPaymentDate.toLocaleDateString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric' })
      : '';
      
  return `${interestPercent}% for ${timeString} (from ${dateString})`;
}

/**
 * Calculates the percentage of principal remaining
 * @param remainingPrincipalNew Current remaining principal amount after payment
 * @param originalPrincipal Original principal amount
 * @returns Percentage of original principal that remains (0-100)
 */
export function calculatePrincipalPercentage(
  remainingPrincipalNew: number,
  originalPrincipal: number
): number {
  if (!originalPrincipal || originalPrincipal === 0) return 0;
  return Math.round((remainingPrincipalNew / originalPrincipal) * 100);
}

/**
 * Returns a text description of payment status based on payment type and amounts
 * @param paymentType The type of payment (full, custom, interest_only)
 * @param remainingPrincipalNew Remaining principal after payment (new value)
 * @param originalPrincipal Original principal amount
 * @returns User-friendly status text
 */
export function getPaymentStatusText(
  paymentType: string,
  remainingPrincipalNew: number,
  originalPrincipal: number
): string {
  if (remainingPrincipalNew === 0) {
    return 'Loan closed';
  }
  
  switch (paymentType) {
    case 'full':
      return 'Full payment';
    case 'interest_only':
      return 'Interest only';
    case 'custom':
      const percentage = calculatePrincipalPercentage(remainingPrincipalNew, originalPrincipal);
      return `${percentage}% remaining`;
    default:
      return 'Payment recorded';
  }
}

/**
 * Returns a description for the remaining principal based on payment mode
 *
 * @param mode Payment mode ('full', 'interest_only', or 'custom')
 * @returns Description text for the remaining principal
 */
export function getRemainingPrincipalDescription(
  mode: 'full' | 'interest_only' | 'custom'
): string {
  switch (mode) {
    case 'full':
      return '(0% after payment)';
    case 'interest_only':
      return '(unchanged)';
    case 'custom':
      return '(after payment)';
    default:
      return '';
  }
}

/**
 * Returns an appropriate CSS class based on payment type
 * @param paymentType The type of payment
 * @returns CSS class name for styling
 */
export function getPaymentTypeClass(paymentType: string): string {
  switch (paymentType) {
    case 'full':
      return 'text-green-600';
    case 'interest_only':
      return 'text-amber-600';
    case 'custom':
      return 'text-indigo-600';
    default:
      return 'text-theme-secondary';
  }
}

/**
 * Returns a human-friendly name for payment type
 *
 * @param type Payment type identifier
 * @returns User-friendly payment type name
 */
export function formatPaymentTypeName(type: string): string {
  switch (type) {
    case 'full': return 'Full Payment';
    case 'custom': return 'Custom Amount';
    case 'interest_only': return 'Interest Only';
    default: return type;
  }
}

/**
 * Formats a monetary amount with currency symbol
 * @param amount The monetary amount to format
 * @returns Formatted currency string
 */
export function formatCurrency(amount: number): string {
  return `₹${amount.toFixed(2)}`;
}

/**
 * Calculates the interest for a loan based on original or remaining amount
 *
 * @param principal Principal amount (original or remaining)
 * @param interestRatePercent Monthly interest rate percentage
 * @param days Number of days for interest calculation
 * @returns Calculated interest amount
 */
export function calculateInterestAmount(
  principal: number,
  interestRatePercent: string | number,
  days: number
): number {
  // Convert interest rate to a number if it's a string
  const rate = typeof interestRatePercent === 'string'
    ? parseFloat(interestRatePercent)
    : interestRatePercent;
  
  // Convert monthly rate to daily rate (approximate)
  const dailyRate = rate / 30 / 100;
  
  // Calculate interest
  return principal * dailyRate * days;
}

/**
 * Determines if this is a first payment for a loan
 *
 * @param lastPaymentDate Date of last payment (if any)
 * @returns True if this is the first payment for the loan
 */
export function isFirstPayment(lastPaymentDate: string | Date | null): boolean {
  return !lastPaymentDate;
}