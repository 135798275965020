import React from 'react';

interface TotalsTableProps {
  totalMoney: string;
}

const TotalsTable: React.FC<TotalsTableProps> = ({ totalMoney }) => {
  return (
    <div className="mb-4">
      <h3 className="text-lg font-semibold mb-2">Totals</h3>
      <table className="w-full border-collapse border border-gray-300">
        <colgroup>
          <col className="w-1/2" />
          <col className="w-1/2" />
        </colgroup>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2 font-semibold">Total Money</td>
            <td className="border border-gray-300 px-4 py-2">
              {Number(totalMoney)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TotalsTable;
