import axios, { AxiosError } from 'axios';
import { LoanRepayment, RepaymentItem } from '../types/repayment';

const API_URL = import.meta.env.VITE_API_URL;

// Create axios instance with default config
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add auth token to all requests
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['x-auth-token'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Error handler
const handleApiError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError<any>;
    console.error('API Error:', axiosError.response?.data);

    // Handle validation errors
    if (axiosError.response?.status === 400) {
      if (axiosError.response.data?.error) {
        throw new Error(`Validation error: ${axiosError.response.data.error}`);
      }
      throw new Error('Invalid request data');
    }

    // Handle authentication errors
    if (axiosError.response?.status === 401) {
      throw new Error('Authentication required');
    }

    // Handle not found errors
    if (axiosError.response?.status === 404) {
      throw new Error('Resource not found');
    }

    // Handle server errors
    if (axiosError.response?.status === 500) {
      throw new Error('Server error occurred');
    }

    // Handle network errors
    if (!axiosError.response) {
      throw new Error('Network error occurred');
    }

    // Handle other errors
    throw new Error(axiosError.response?.data?.error || 'An error occurred');
  }

  // Handle non-axios errors
  throw error;
};

export const repaymentApi = {
  fetchRepayments: async (params?: Record<string, any>) => {
    try {
      const response = await api.get('/api/loan-repayment/search', { params });
      
      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to fetch repayments');
      }
      
      return response.data.data;
    } catch (error) {
      console.error('Error fetching repayments:', error);
      throw handleApiError(error);
    }
  },

  fetchPaymentHistory: async (loanId: string) => {
    try {
      
      // Validate loanId format
      if (typeof loanId !== 'string' || loanId.length !== 24) {
        throw new Error(`Invalid loan ID format: ${loanId}`);
      }
      
      const response = await api.get(`/api/loan-repayment/payment-history/${loanId}`);
      
      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to fetch payment history');
      }
      
      return response.data.data;
    } catch (error) {
      console.error('Error fetching payment history:', error);
      
      // Enhanced error handling
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<any>;
        
        if (axiosError.response?.status === 404) {
          // Return empty data structure for no payment history
          return {
            paymentRecords: [],
            summary: {
              totalInterestPaid: 0,
              totalPrincipalPaid: 0,
              totalAmountPaid: 0,
              totalRounding: 0,
              netPayment: 0,
              recordCount: 0,
              paymentTypes: {
                full: 0,
                custom: 0,
                interest_only: 0
              },
              remainingPrincipal: 0,
              lastPaymentDate: null,
              lastCompoundDate: null
            }
          };
        }
      }
      
      throw handleApiError(error);
    }
  },

  fetchRepaymentDetails: async (id: string) => {
    try {
      const response = await api.get(`/api/loan-repayment/${id}`);
      
      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to fetch repayment details');
      }
      
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  createRepayment: async (repaymentData: LoanRepayment) => {
    try {
      // Deep clone the repayment data before sending
      
      const dataToSend = JSON.parse(JSON.stringify(repaymentData));
      
      // Ensure that all repayment items have the complete set of required properties
      if (dataToSend.repaymentItems && Array.isArray(dataToSend.repaymentItems)) {
        // Validate that all repayment items have the required principal values
        dataToSend.repaymentItems.forEach((item: any) => {
          if (item.originalPrincipal === undefined) {
            console.error(`[ERROR] Missing originalPrincipal for item ${item.issueId}`);
            throw new Error(`Missing originalPrincipal for one or more repayment items`);
          }
          
          // Map our internal oldRemainingPrincipal to remainingPrincipalOld if needed
          if (item.oldRemainingPrincipal !== undefined) {
            item.remainingPrincipalOld = item.oldRemainingPrincipal;
            delete item.oldRemainingPrincipal; // Remove the internal field
          }
          
          // Map our internal newRemainingPrincipal to remainingPrincipalNew if needed
          if (item.newRemainingPrincipal !== undefined) {
            item.remainingPrincipalNew = item.newRemainingPrincipal;
            delete item.newRemainingPrincipal; // Remove the internal field
          }
        });
        dataToSend.repaymentItems = dataToSend.repaymentItems.map((item: RepaymentItem) => {
          // Validate and fix payment type information
          if (!item.paymentType) {
            item.paymentType = 'full';
          }
          
          // Ensure interest_only payment types maintain their principal amount
          // and totalAmount equals interest exactly
          if (item.paymentType === 'interest_only') {
            
            // CRITICAL FIX: DO NOT set remainingPrincipalOld to originalPrincipal automatically!
            // If we're seeing the issue, this means RepaymentItems component didn't set the correct value
            // We need to investigate if there's an issue with data passed from RepaymentItems
            
            // Only use originalPrincipal as a fallback if no value is provided
            if (item.remainingPrincipalOld === undefined) {
              item.remainingPrincipalOld = item.originalPrincipal;
            } else {
              // Preserve the existing value - THIS IS THE KEY FIX
            }
            
            // For interest-only payments, the remainingPrincipalNew equals remainingPrincipalOld
            // as only interest is paid, principal stays the same
            item.remainingPrincipalNew = item.remainingPrincipalOld;
            
            // Add debug info
            
            // Critical fix: Ensure amountPayable equals interest exactly for interest-only payments
            if (Math.abs(item.amountPayable - item.interest) > 0.01) {
              item.amountPayable = item.interest;
            }
          }
          
          // Ensure full payment types have zero remaining principal (new)
          else if (item.paymentType === 'full') {
            // For full payment, ensure remainingPrincipalOld is set correctly first
            // It should be the previous voucher's remainingPrincipalNew
            if (item.remainingPrincipalOld === undefined) {
              item.remainingPrincipalOld = item.originalPrincipal;
            } else {
            }
            
            // For full payments, the remainingPrincipalNew is always 0
            item.remainingPrincipalNew = 0;
          }
          
          // Calculate correct remaining principal for custom payments
          else if (item.paymentType === 'custom') {
            // Get the amount values
            const amountPaid = parseFloat(item.amountPaid?.toString() || '0');
            const rounding = parseFloat(item.rounding?.toString() || '0');
            const interest = parseFloat(item.interest?.toString() || '0');
            
            // Total amount entered by user (amountPaid + rounding)
            const amountPayable = amountPaid + rounding;
            
            // Calculate remaining principal using the correct formula:
            // Remaining Principal (New) = Remaining Principal (Old) + Interest - Amount Payable
            
            // For custom payment, verify we have the proper remaining principal value
            // IMPORTANT: remainingPrincipalOld for this voucher should be equal to
            // the remainingPrincipalNew from the previous voucher
            
            // RepaymentItems component should have already set item.remainingPrincipalOld
            // based on the previous voucher's data
            if (item.remainingPrincipalOld === undefined) {
              // Fallback to original principal if this is the first payment
              item.remainingPrincipalOld = parseFloat(item.originalPrincipal?.toString() || '0');
            } else {
            }
            
            // Calculate and store the new value (after payment)
            // Now item.remainingPrincipalOld is guaranteed to be defined
            const remainingPrincipalOld = item.remainingPrincipalOld || 0; // TypeScript safety
            const calculatedRemainingPrincipal = Math.max(0, remainingPrincipalOld + interest - amountPayable);
            item.remainingPrincipalNew = calculatedRemainingPrincipal;
            
            // Set the amountPayable
            item.amountPayable = amountPayable;
            
          }
          
          // Ensure lastCompoundDate is set for proper interest calculation
          if (!item.lastCompoundDate) {
            item.lastCompoundDate = new Date();
          }
          
          return item;
        });
      }
      
      const response = await api.post('/api/loan-repayment', dataToSend);
      
      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to create repayment');
      }
      
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  updateRepayment: async (id: string, repaymentData: LoanRepayment) => {
    try {
      const response = await api.put(`/api/loan-repayment/${id}`, repaymentData);
      
      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to update repayment');
      }
      
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  deleteRepayment: async (id: string) => {
    try {
      const response = await api.delete(`/api/loan-repayment/${id}`);
      
      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to delete repayment');
      }
      
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  fetchNearestPreviousRepayment: async (voucherNumber: number) => {
    try {
      const response = await api.get(`/api/loan-repayment/nearest-previous/${voucherNumber}`);
      
      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to fetch previous repayment');
      }
      
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  fetchNearestNextRepayment: async (voucherNumber: number) => {
    try {
      const response = await api.get(`/api/loan-repayment/nearest-next/${voucherNumber}`);
      
      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to fetch next repayment');
      }
      
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  fetchNextVoucherNumber: async () => {
    try {
      const response = await api.get('/api/loan-repayment/next-voucher-number');
      
      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to fetch next voucher number');
      }
      
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  // Fetch loans for a customer from loan-repayment API
  fetchCustomerLoans: async (customerId: string) => {
    try {
      
      // Validate customerId
      if (typeof customerId !== 'string' || customerId.length !== 24) {
        throw new Error(`Invalid customer ID format: ${customerId}`);
      }

      // Make request to our own loan-repayment API
      const response = await api.get(`/api/loan-repayment/customer-loans/${customerId}`);
      
      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to fetch customer loans');
      }
      
      return response.data.data;
    } catch (error) {
      console.error('Error fetching customer loans:', error);
      
      // Enhanced error handling with better details
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<any>;
        if (axiosError.response?.status === 500) {
          throw new Error('Server error occurred while fetching loans');
        }
        
        if (axiosError.response?.status === 400) {
          throw new Error(`Invalid request: ${axiosError.response.data?.error || 'Bad request'}`);
        }
        
        if (axiosError.response?.status === 404) {
          throw new Error('Loan search endpoint not found');
        }
      }
      
      throw new Error(`Failed to fetch loans: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
  }
};
