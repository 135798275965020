import React from 'react';
import { Loan } from '../../types/loan';
import { useLoanContext } from '../../contexts/LoanContext';

interface NavigationFooterProps {
  loan: Loan;
  loanId?: string;
  onPreviousLoan?: () => void;
  onNextLoan?: () => void;
  onViewPreviousLoans?: () => void;
  onSubmit: (e: React.FormEvent) => Promise<void>;
}

export const NavigationFooter: React.FC<NavigationFooterProps> = ({
  loan,
  loanId,
  onPreviousLoan,
  onNextLoan,
  onViewPreviousLoans,
  onSubmit
}) => {
  const { state } = useLoanContext();
  const { isLoading } = state;

  return (
    <div 
      className="fixed bottom-0 left-0 right-0 bg-white shadow-lg px-4 py-2 flex items-center justify-between border-t border-gray-300"
      style={{
        width: '100%',
        zIndex: 50,
      }}
    >
      <div className="flex items-center gap-4">
        {/* Only show previous button if there's a voucher number and it's greater than 1 */}
        {loan.voucherNumber && loan.voucherNumber > 1 && (
          <button
            type="button"
            onClick={onPreviousLoan}
            disabled={isLoading}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50"
            title="Previous Loan"
          >
            &lt;
          </button>
        )}
        
        <button
          type="button"
          onClick={onViewPreviousLoans}
          disabled={isLoading}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50"
        >
          View Previous Loans
        </button>
        
        {/* Only show next button if there's a voucher number */}
        {loan.voucherNumber && (
          <button
            type="button"
            onClick={onNextLoan}
            disabled={isLoading}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50"
            title="Next Loan"
          >
            &gt;
          </button>
        )}
      </div>

      <div className="flex items-center gap-4">
        <button
          type="submit"
          disabled={isLoading}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline disabled:opacity-50"
        >
          {isLoading ? (
            <span className="flex items-center gap-2">
              <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Processing...
            </span>
          ) : loanId ? 'Update' : 'Save'}
        </button>
      </div>
    </div>
  );
};
