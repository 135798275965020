import React, { useCallback, useContext } from 'react';
import { useLoanContext, loanActions } from '../../contexts/LoanContext';
import { AuthContext } from '../../../../contexts/AuthContext';
import { LoanHeader } from './LoanHeader';
import { LoanItems } from './LoanItems';
import { LoanTotals } from './LoanTotals';
import { NavigationFooter } from './NavigationFooter';
import { PreviousLoans } from './PreviousLoans';
import { Customer } from '../../types/loan';
import { loanApi } from '../../api/loanApi';
import { customerApi } from '../../../customerBill2/api/customerApi';
import { useLoanForm } from '../../hooks/useLoanForm';

interface LoanFormProps {
  loanId?: string;
}

export const LoanForm: React.FC<LoanFormProps> = ({ loanId }) => {
  const { state, dispatch } = useLoanContext();
  const auth = useContext(AuthContext);
  const [isInitialLoadDone, setIsInitialLoadDone] = React.useState(false);

  // Load initial data when component mounts
  React.useEffect(() => {
    let mounted = true;

    const loadInitialData = async () => {
      // Don't load if already done or no token
      if (isInitialLoadDone || !localStorage.getItem('token')) return;

      try {
        dispatch(loanActions.setLoading(true));
        dispatch(loanActions.setError(null));
        
        // Load customers and loans in parallel
        const [customers, loansData] = await Promise.all([
          customerApi.getCustomers(),
          loanApi.fetchLoans()
        ]);
        
        if (!mounted) return;

        if (Array.isArray(customers) && customers.length > 0) {
          dispatch(loanActions.setCustomers(customers));
          dispatch(loanActions.setFilteredCustomers(customers));
        } else {
          dispatch(loanActions.setError('No customers found'));
        }

        // Set the next voucher number from loans data
        if (!loanId && loansData.data.nextVoucherNumber) {
          dispatch(loanActions.setLoan({
            ...state.loan,
            voucherNumber: loansData.data.nextVoucherNumber
          }));
        }

        setIsInitialLoadDone(true);
      } catch (error) {
        if (mounted) {
          const errorMessage = error instanceof Error ? error.message : 'Failed to load initial data';
          dispatch(loanActions.setError(errorMessage));
        }
      } finally {
        if (mounted) {
          dispatch(loanActions.setLoading(false));
        }
      }
    };

    // Only load if we have auth and token
    if (auth?.user && localStorage.getItem('token')) {
      loadInitialData();
    }

    return () => {
      mounted = false;
    };
  }, [dispatch, state.loan, loanId, auth?.user, isInitialLoadDone]);

  const {
    loan,
    isLoading: formLoading,
    error: formError,
    handleCustomerSelect,
    handleLoanItemChange,
    handleLoanItemSelect,
    handleSubmit,
    handleDateChange,
    addLoanItem,
    handlePreviousLoan,
    handleNextLoan,
    handleViewPreviousLoans,
    handleMonthlyInterestChange,
    handleNoteChange
  } = useLoanForm(loanId);

  const onCustomerSelect = useCallback((customer: Customer) => {
    handleCustomerSelect(customer);
  }, [handleCustomerSelect]);

  const onSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await handleSubmit();
    } catch (error) {
      // Let PrivateRoute handle auth errors
      console.error('Submit error:', error);
    }
  }, [handleSubmit]);

  const onDateChange = useCallback((date: Date) => {
    handleDateChange(date);
  }, [handleDateChange]);

  const handleEdit = useCallback(async (editLoanId: string) => {
    try {
      dispatch(loanActions.setLoading(true));
      dispatch(loanActions.setError(null));
      
      const loanData = await loanApi.fetchLoanDetails(editLoanId);
      dispatch(loanActions.setLoan({
        ...loanData.data,
        date: new Date(loanData.data.date)
      }));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to load loan';
      dispatch(loanActions.setError(errorMessage));
    } finally {
      dispatch(loanActions.setLoading(false));
    }
  }, [dispatch]);

  // Show loading state while initializing
  if (!isInitialLoadDone || state.isLoading || formLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  // Show error state
  if (state.error || formError) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{state.error || formError}</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <form onSubmit={onSubmit} className="space-y-6">
        <LoanHeader
          date={loan.date}
          voucherNumber={loan.voucherNumber}
          onCustomerSelect={onCustomerSelect}
          onDateChange={onDateChange}
          customerName={loan.selectedCustomer}
        />

        <LoanItems
          items={loan.items}
          onItemChange={handleLoanItemChange}
          onItemSelect={handleLoanItemSelect}
          onAddItem={addLoanItem}
        />

        <LoanTotals
          loan={loan}
          onMonthlyInterestChange={handleMonthlyInterestChange}
          onNoteChange={handleNoteChange}
        />

        <NavigationFooter
          loan={loan}
          loanId={loanId}
          onPreviousLoan={handlePreviousLoan}
          onNextLoan={handleNextLoan}
          onViewPreviousLoans={handleViewPreviousLoans}
          onSubmit={onSubmit}
        />
      </form>

      <PreviousLoans onEdit={handleEdit} />
    </>
  );
};
