import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useRepaymentContext, repaymentActions } from '../contexts/RepaymentContext';
import { dateUtils } from '../utils/dateUtils';
import { repaymentApi } from '../api/repaymentApi';
import { calculateRepaymentItem } from '../utils/calculations';
import { Button } from '../../../styles/components';
import { StandaloneLoanDetails } from './StandaloneLoanDetails';
import { RepaymentItem } from '../types/repayment';
import '../styles/table.css';
import '../styles/collapsibleSearch.css';

interface CollapsibleLoanSearchProps {
  items: RepaymentItem[];
  onSelectItems: (items: RepaymentItem[]) => void;
  customerId?: string;
}

export const CollapsibleLoanSearch: React.FC<CollapsibleLoanSearchProps> = ({
  items,
  onSelectItems,
  customerId
}) => {
  const { state, dispatch } = useRepaymentContext();
  const [customerLoans, setCustomerLoans] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedLoanIds, setSelectedLoanIds] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  
  // Refs to prevent infinite loops
  const searchInputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const isUpdatingRef = useRef(false);
  const prevSelectedIdsRef = useRef<string[]>([]);
  const hasMountedRef = useRef(false);

  // Use selected customer for fetch if available
  const selectedCustomerId = customerId || state.repayment.customer;
  const selectedCustomerName = state.repayment.selectedCustomer;

  // Expand/collapse state is managed in the context
  const isExpanded = state.isLoanSearchFocused;

  // Filter loans based on search term - memoize to prevent recalculation
  const filteredLoans = useMemo(() => {
    return customerLoans.filter(loan => {
      if (!searchTerm) return true;
      
      const searchLower = searchTerm.toLowerCase();
      const dateStr = dateUtils.formatDate(loan.date).toLowerCase();
      const amountStr = loan.amount?.toString().toLowerCase() || '';
      
      return dateStr.includes(searchLower) || 
             amountStr.includes(searchLower) ||
             (loan.monthlyInterestPercent?.toString() || '').includes(searchLower);
    });
  }, [customerLoans, searchTerm]);

  // Calculate total selected amount - memoize to prevent recalculation
  const totalSelectedAmount = useMemo(() => {
    return customerLoans
      .filter(loan => selectedLoanIds.includes(loan._id))
      .reduce((total, loan) => total + (loan.amount || 0), 0);
  }, [customerLoans, selectedLoanIds]);

  // Function to fetch loans for the selected customer
  const fetchCustomerLoans = useCallback(async () => {
    if (!selectedCustomerId) return;
    
    try {
      setLoading(true);
      setError(null);
      setCustomerLoans([]);
      
      const response = await repaymentApi.fetchCustomerLoans(selectedCustomerId);
      
      if (response && response.loans && response.loans.length > 0) {
        setCustomerLoans(response.loans);
      } else {
        // No loans found but no error
        setCustomerLoans([]);
        setError(`No outstanding loans found for ${selectedCustomerName || 'this customer'}`);
      }
    } catch (err) {
      setCustomerLoans([]);
      const errorMessage = err instanceof Error ? err.message : 'Unknown error';
      setError(`Failed to fetch loans: ${errorMessage}`);
    } finally {
      setLoading(false);
    }
  }, [selectedCustomerId, selectedCustomerName]);

  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current && 
        !dropdownRef.current.contains(event.target as Node) &&
        searchInputRef.current && 
        !searchInputRef.current.contains(event.target as Node)
      ) {
        dispatch(repaymentActions.setLoanSearchFocus(false));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dispatch]);

  // Fetch customer loans when customer is selected - only once
  useEffect(() => {
    if (!selectedCustomerId) return;
    fetchCustomerLoans();
    // Only include selectedCustomerId as dependency to prevent fetching on every render
  }, [selectedCustomerId]); 

  // Initialize selected loan IDs from current items when component mounts or when items change
  useEffect(() => {
    // Skip if already processing an update
    if (isUpdatingRef.current) return;
    
    if (items.length > 0 && customerLoans.length > 0) {
      // Extract unique issue IDs from current items
      const currentIssueIds = [...new Set(items.map(item => item.issueId))];
      
      // Find corresponding loan IDs
      const matchingLoanIds = customerLoans
        .filter(loan => currentIssueIds.includes(loan._id))
        .map(loan => loan._id);
      
      if (matchingLoanIds.length > 0 && 
          JSON.stringify(matchingLoanIds) !== JSON.stringify(prevSelectedIdsRef.current)) {
        prevSelectedIdsRef.current = matchingLoanIds;
        setSelectedLoanIds(matchingLoanIds);
      }
    }
  }, [items, customerLoans]);

  // Create repayment items from selected loans - single effect with complete processing
  const createRepaymentItemsFromLoans = useCallback((loanIds: string[]) => {
    if (loanIds.length === 0) {
      return [];
    }
    
    const repaymentDate = state.repayment.date || new Date();
    const selectedLoans = customerLoans.filter(loan => loanIds.includes(loan._id));
    
    try {
      return selectedLoans.flatMap(loan => {
        // Ensure loan has required properties
        if (!loan?._id || !loan?.date || loan?.amount === undefined || loan?.amount === null) {
          return [];
        }
        
        // Check for existing payment type
        const currentPaymentType = state.repayment.repaymentItems.length > 0
          ? state.repayment.repaymentItems[0].paymentType || 'full'
          : 'full';
          
        // Extract payment history dates
        const lastPaymentDate = loan.repaymentHistory?.lastPaymentDate || null;
        const lastCompoundDate = loan.repaymentHistory?.lastCompoundDate || null;
        
        // Calculate values with appropriate payment type
        const calculatedValues = calculateRepaymentItem(
          {
            issueDate: loan.date,
            oldRemainingPrincipal: loan.amount, // Use the updated parameter name
            monthlyInterestPercent: loan.monthlyInterestPercent,
            lastPaymentDate: lastPaymentDate ? new Date(lastPaymentDate) : null,
            lastCompoundDate: lastCompoundDate ? new Date(lastCompoundDate) : undefined
          },
          repaymentDate,
          currentPaymentType
        );
        
        // Set values based on payment type
        let remainingPrincipal = 0;
        let amountPaid = 0;
        let amountPayable = 0;
        
        if (currentPaymentType === 'interest_only') {
          remainingPrincipal = typeof loan.amount === 'number' ? loan.amount : 0;
          amountPaid = calculatedValues.interest;
          amountPayable = calculatedValues.interest;
        } else if (currentPaymentType === 'full') {
          remainingPrincipal = 0;
          amountPaid = calculatedValues.amountPayable;
          amountPayable = calculatedValues.amountPayable;
        } else {
          remainingPrincipal = typeof loan.amount === 'number' ? loan.amount : 0;
          amountPaid = calculatedValues.amountPayable;
          amountPayable = calculatedValues.amountPayable;
        }
        
        return [{
          issueId: loan._id,
          issueDate: new Date(loan.date),
          originalPrincipal: typeof loan.amount === 'number' ? loan.amount : 0,
          monthlyInterestPercent: loan.monthlyInterestPercent || '0',
          interest: calculatedValues.interest,
          amountPayable: typeof amountPayable === 'number' ? amountPayable : 0,
          paymentType: currentPaymentType as 'full' | 'custom' | 'interest_only',
          amountPaid: typeof amountPaid === 'number' ? amountPaid : 0,
          rounding: 0,
          remainingPrincipal: typeof remainingPrincipal === 'number' ? remainingPrincipal : 0,
          lastCompoundDate: calculatedValues.lastCompoundDate
        }];
      });
    } catch (error) {
      console.error("Error creating repayment items:", error);
      return [];
    }
  }, [customerLoans, state.repayment.date, state.repayment.repaymentItems]);

  // Handle loan selection with all processing in one place
  const handleLoanSelection = useCallback((loan: any) => {
    if (!loan?._id) return;
    
    setSelectedLoanIds(prevSelected => {
      const isCurrentlySelected = prevSelected.includes(loan._id);
      
      // If already selected, remove it; otherwise add it
      const newSelectedIds = isCurrentlySelected
        ? prevSelected.filter(id => id !== loan._id)
        : [...prevSelected, loan._id];
      
      // Collapse the dropdown after selection
      setTimeout(() => {
        dispatch(repaymentActions.setLoanSearchFocus(false));
      }, 150);
      
      // Update if the IDs actually changed
      if (JSON.stringify(prevSelected) !== JSON.stringify(newSelectedIds)) {
        // Use ref to avoid multiple concurrent updates
        if (!isUpdatingRef.current) {
          isUpdatingRef.current = true;
          
          // Update the parent with new items
          const newItems = createRepaymentItemsFromLoans(newSelectedIds);
          if (newItems.length > 0 || newSelectedIds.length === 0) {
            onSelectItems(newItems);
          }
          
          // Reset the update flag after a short delay
          setTimeout(() => {
            isUpdatingRef.current = false;
          }, 50);
          
          // Store current selection for comparison
          prevSelectedIdsRef.current = newSelectedIds;
        }
      }
      
      return newSelectedIds;
    });
  }, [createRepaymentItemsFromLoans, dispatch, onSelectItems]);

  // Handle search input focus
  const handleSearchFocus = useCallback(() => {
    dispatch(repaymentActions.setLoanSearchFocus(true));
  }, [dispatch]);

  // Handle search input change
  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  // Handle retry
  const handleRetry = useCallback(() => {
    fetchCustomerLoans();
  }, [fetchCustomerLoans]);

  // Format the display text for the input when collapsed
  const getDisplayText = useCallback(() => {
    if (selectedLoanIds.length === 0) {
      return 'Search loans...';
    }
    
    return `${selectedLoanIds.length} loan${selectedLoanIds.length > 1 ? 's' : ''} selected (₹${totalSelectedAmount.toLocaleString('en-IN')})`;
  }, [selectedLoanIds.length, totalSelectedAmount]);

  // Show placeholder if no customer selected
  if (!selectedCustomerId) {
    return (
      <div className="p-4 bg-theme-secondary rounded-lg text-center">
        <p className="text-theme-secondary">Please select a customer first to view available loans.</p>
      </div>
    );
  }

  return (
    <div className="loan-search-container">
      {/* Search Input */}
      <div className="relative">
        <input
          ref={searchInputRef}
          type="text"
          className={`loan-search-input ${selectedLoanIds.length > 0 ? 'has-selection' : ''}`}
          placeholder="Search loans..."
          value={isExpanded ? searchTerm : getDisplayText()}
          onChange={handleSearchChange}
          onFocus={handleSearchFocus}
          readOnly={!isExpanded}
        />
        <span className="loan-search-icon">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
        </span>
        {selectedLoanIds.length > 0 && !isExpanded && (
          <span className="loan-edit-icon" onClick={handleSearchFocus}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
            </svg>
          </span>
        )}
      </div>

      {/* Dropdown for Loans Table */}
      <div 
        ref={dropdownRef} 
        className={`loan-dropdown ${isExpanded ? 'expanded' : ''}`}
      >
        {/* Loading indicator */}
        {loading && (
          <div className="flex items-center justify-center py-4">
            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-accent mr-2"></div>
            <span className="text-sm text-theme-secondary">Loading loans...</span>
          </div>
        )}
        
        {/* Error message */}
        {error && (
          <div className="text-red-500 bg-red-50 border border-red-200 p-3 m-2 rounded-md">
            <div className="flex items-start">
              <svg className="h-5 w-5 text-red-400 mr-2 mt-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
              <div>
                <p>{error}</p>
                <div className="mt-2">
                  <Button
                    onClick={handleRetry}
                    variant="outline"
                    size="sm"
                  >
                    Retry
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Loans Table */}
        {!loading && !error && filteredLoans.length > 0 && (
          <div className="loan-table-container">
            <div className="flex justify-between items-center mb-2">
              <h4 className="font-medium text-theme-primary">Available Loans</h4>
              <span className="text-sm text-theme-tertiary">{filteredLoans.length} loan(s) found</span>
            </div>
            <table className="loan-table">
              <thead>
                <tr className="bg-theme-secondary border-b border-theme">
                  <th className="col-checkbox text-center"></th>
                  <th className="col-date text-center">Issue Date</th>
                  <th className="col-amount text-right">Principal</th>
                  <th className="col-number text-center">Int. %</th>
                  <th className="col-action text-center">Details</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-theme">
                {filteredLoans.map((loan) => (
                  <tr
                    key={loan._id}
                    className={`transition-colors ${selectedLoanIds.includes(loan._id) ? 'selected' : ''}`}
                    onClick={() => handleLoanSelection(loan)}
                  >
                    <td className="text-center">
                      <input
                        type="checkbox"
                        checked={selectedLoanIds.includes(loan._id)}
                        onChange={() => handleLoanSelection(loan)}
                        className="h-4 w-4 text-accent focus:ring-accent border-theme rounded cursor-pointer"
                      />
                    </td>
                    <td className="text-center text-theme-secondary">
                      {dateUtils.formatDate(loan.date)}
                    </td>
                    <td className="text-right text-theme-primary font-medium">
                      ₹{loan.amount?.toFixed(2) || "0.00"}
                    </td>
                    <td className="text-center text-theme-secondary">
                      {loan.monthlyInterestPercent || "0"}%
                    </td>
                    <td className="text-center">
                      <StandaloneLoanDetails
                        loanId={loan._id}
                        date={loan.date}
                        amount={loan.amount}
                        interestPercent={loan.monthlyInterestPercent}
                        items={loan.items || []}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* No Results */}
        {!loading && !error && filteredLoans.length === 0 && customerLoans.length > 0 && (
          <div className="text-center py-4">
            <p className="text-theme-secondary">No loans match your search.</p>
          </div>
        )}
      </div>

      {/* Selected Loans Counter - Only show when no loans selected */}
      <div className="mt-2">
        {!isExpanded && selectedLoanIds.length === 0 && (
          <div className="text-center text-sm text-theme-secondary">
            <p>Click to search and select loans</p>
          </div>
        )}
      </div>
    </div>
  );
};