import React from 'react';
import { LoanForm } from '../features/loanIssue/components/LoanForm';
import { LoanProvider } from '../features/loanIssue/contexts/LoanContext';
import { Navigate } from 'react-router-dom';

const LoanIssue: React.FC = () => {
  // Check for token directly
  const token = localStorage.getItem('token');
  if (!token) {
    return <Navigate to="/auth" replace />;
  }

  return (
    <div className="min-h-screen bg-gray-100 p-2">
      <LoanProvider>
        <LoanForm />
      </LoanProvider>
    </div>
  );
};

export default LoanIssue;
