import React from 'react';
import { Navigate } from 'react-router-dom';
import { SimplifiedRepaymentForm } from '../features/loanRepayment';
import { RepaymentProvider } from '../features/loanRepayment/contexts/RepaymentContext';

const LoanRepayment: React.FC = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return <Navigate to="/auth" replace />;
  }
  
  return (
    <div className="min-h-screen bg-gray-100 p-2">
      <RepaymentProvider>
        <SimplifiedRepaymentForm />
      </RepaymentProvider>
    </div>
  );
};

export default LoanRepayment;