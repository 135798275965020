import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/globals.css'; // Use our new global styles
import Home from './pages/Home';
import Auth from './pages/Auth';
import Dashboard from './pages/Dashboard';
import Customers from './pages/Customers';
import Vendors from './pages/Vendors';
import Items from './pages/Items';
import CustomerBill from './pages/CustomerBill';
import VendorBill from './pages/VendorBill';
import VendorFineCut from './pages/VendorFineCut';
import CustomerReport from './pages/CustomerReport';
import VendorReport from './pages/VendorReport';
import Stock from './pages/Stock';
import StockReport from './pages/StockReport';
import MoneyReceived from './pages/MoneyReceived';
import AccountSettings from './pages/AccountSettings';
import LoanIssue from './pages/LoanIssue';
import LoanRepayment from './pages/LoanRepayment';
import ThemeShowcase from './pages/ThemeShowcase'; // Import the theme showcase
import PrivateRoute from './components/PrivateRoute';
import Layout from './components/Layout';

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <Router>
          <ToastContainer />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/auth" element={<Auth />} />
            <Route
              path="/*"
              element={
                <PrivateRoute>
                  <Layout>
                    <Routes>
                      <Route path="dashboard" element={<Dashboard />} />
                      <Route path="customers" element={<Customers />} />
                      <Route path="vendors" element={<Vendors />} />
                      <Route path="items" element={<Items />} />
                      <Route path="customer-bill" element={<CustomerBill />} />
                      <Route path="vendor-bill" element={<VendorBill />} />
                      <Route path="vendor-fine-cut" element={<VendorFineCut />} />
                      <Route path="customer-report" element={<CustomerReport />} />
                      <Route path="vendor-report" element={<VendorReport />} />
                      <Route path="stock" element={<Stock />} />
                      <Route path="stock-report" element={<StockReport />} />
                      <Route path="money-received" element={<MoneyReceived />} />
                      <Route path="account-settings" element={<AccountSettings />} />
                      <Route path="loan-issue" element={<LoanIssue />} />
                      <Route path="loan-repayment" element={<LoanRepayment />} />
                      <Route path="theme-showcase" element={<ThemeShowcase />} />
                    </Routes>
                  </Layout>
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
