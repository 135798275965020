// Parse string to number safely
export const parseNumber = (value: string | number): number => {
  const parsed = typeof value === 'string' ? parseFloat(value) : value;
  return isNaN(parsed) ? 0 : parsed;
};

// Parse string to integer safely
export const parseInt = (value: string | number): number => {
  const parsed = typeof value === 'string' ? Number.parseInt(value, 10) : value;
  return isNaN(parsed) ? 0 : parsed;
};

// Format number to fixed decimal places
export const formatNumber = (value: number, decimals: number = 2): string => {
  return value.toFixed(decimals);
};

// Calculate net weight
export const calculateNetWeight = (grossWt: string | number, lessWt: string | number): number => {
  return parseNumber(grossWt) - parseNumber(lessWt);
};

// Calculate fine weight
export const calculateFine = (netWt: number, tunch: string | number, wstg: string | number): number => {
  const tunchValue = parseNumber(tunch);
  const wstgValue = parseNumber(wstg);
  return (netWt * (tunchValue + wstgValue)) / 100;
};

// Calculate amount
export const calculateAmount = (fine: number, rate: string | number): number => {
  return fine * parseNumber(rate);
};

// Format currency
export const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};

// Validate decimal number input
export const isValidDecimalInput = (value: string): boolean => {
  return /^\d*\.?\d*$/.test(value);
};

// Calculate loan totals
export const calculateLoanTotals = (
  items: Array<{
    amount: number;
  }>,
  monthlyInterestPercent: string
): {
  amount: number;
} => {
  // Calculate total amount from items
  const totalAmount = items.reduce((sum, item) => sum + parseNumber(item.amount), 0);

  return {
    amount: totalAmount
  };
};

// Recalculate loan item values while preserving identity fields
export const recalculateLoanItem = (item: {
  grossWt: string | number;
  lessWt: string | number;
  tunch: string | number;
  wstg: string | number;
  rate: string | number;
}): {
  netWt: number;
  fine: number;
  amount: number;
} => {
  const netWt = calculateNetWeight(item.grossWt, item.lessWt);
  const fine = calculateFine(netWt, item.tunch, item.wstg);
  const amount = calculateAmount(fine, item.rate);

  return {
    netWt,
    fine,
    amount
  };
};
