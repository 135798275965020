import axios from 'axios';
import { Loan, ApiResponse, LoanResponse, LoansResponse } from '../types/loan';

// Create axios instance with base URL
const api = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/api/loan-issue`,
  headers: {
    'Content-Type': 'application/json',
  }
});

// Set default auth header if token exists
const token = localStorage.getItem('token');
if (token) {
  api.defaults.headers.common['x-auth-token'] = token;
}

// Request interceptor for adding auth token
api.interceptors.request.use(
  (config) => {
    // Get token again in case it was updated
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['x-auth-token'] = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const loanApi = {
  createLoan: async (loanData: Omit<Loan, '_id'>): Promise<ApiResponse<CreateLoanResponse>> => {
    try {
      const response = await api.post<ApiResponse<CreateLoanResponse>>('/', loanData);
      if (!response.data.success) {
        throw new Error('Failed to create loan');
      }
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.error || 'Failed to create loan');
      }
      throw error;
    }
  },

  fetchLoans: async (): Promise<LoansResponse> => {
    try {
      const token = localStorage.getItem('token');
      const response = await api.get<LoansResponse>('/', {
        headers: {
          'x-auth-token': token
        }
      });
      if (!response.data.success) {
        throw new Error('Failed to fetch loans');
      }
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.error || 'Failed to fetch loans');
      }
      throw error;
    }
  },

  fetchLoanByVoucherNumber: async (voucherNumber: number): Promise<LoanResponse> => {
    try {
      const token = localStorage.getItem('token');
      const response = await api.get<LoanResponse>(
        `/search/by-voucher/${voucherNumber}`,
        {
          headers: {
            'x-auth-token': token
          }
        }
      );
      if (!response.data.success) {
        throw new Error('Failed to fetch loan');
      }
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.error || 'Failed to fetch loan');
      }
      throw error;
    }
  },

  fetchLoanDetails: async (loanId: string): Promise<LoanResponse> => {
    try {
      const token = localStorage.getItem('token');
      const response = await api.get<LoanResponse>(`/${loanId}`, {
        headers: {
          'x-auth-token': token
        }
      });
      if (!response.data.success) {
        throw new Error('Failed to fetch loan details');
      }
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.error || 'Failed to fetch loan details');
      }
      throw error;
    }
  },

  updateLoan: async (loanId: string, loanData: Partial<Loan>): Promise<LoanResponse> => {
    try {
      const token = localStorage.getItem('token');
      const response = await api.put<LoanResponse>(
        `/${loanId}`,
        loanData,
        {
          headers: {
            'x-auth-token': token
          }
        }
      );
      if (!response.data.success) {
        throw new Error('Failed to update loan');
      }
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.error || 'Failed to update loan');
      }
      throw error;
    }
  },

  deleteLoan: async (loanId: string): Promise<void> => {
    try {
      const token = localStorage.getItem('token');
      const response = await api.delete<ApiResponse<void>>(`/${loanId}`, {
        headers: {
          'x-auth-token': token
        }
      });
      if (!response.data.success) {
        throw new Error('Failed to delete loan');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.error || 'Failed to delete loan');
      }
      throw error;
    }
  },

  fetchNearestPreviousLoan: async (voucherNumber: number): Promise<LoanResponse> => {
    try {
      const token = localStorage.getItem('token');
      const response = await api.get<LoanResponse>(
        `/search/nearest-previous/${voucherNumber}`,
        {
          headers: {
            'x-auth-token': token
          }
        }
      );
      if (!response.data.success) {
        throw new Error('No previous loan found');
      }
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.error || 'Failed to fetch previous loan');
      }
      throw error;
    }
  },

  fetchNearestNextLoan: async (voucherNumber: number): Promise<LoanResponse> => {
    try {
      const token = localStorage.getItem('token');
      const response = await api.get<LoanResponse>(
        `/search/nearest-next/${voucherNumber}`,
        {
          headers: {
            'x-auth-token': token
          }
        }
      );
      if (!response.data.success) {
        throw new Error('No next loan found');
      }
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.error || 'Failed to fetch next loan');
      }
      throw error;
    }
  },

  searchLoans: async (params: {
    page?: number;
    limit?: number;
    startDate?: string;
    endDate?: string;
    customer?: string;
    minAmount?: number;
    maxAmount?: number;
  }): Promise<{
    loans: Loan[];
    pagination: {
      total: number;
      page: number;
      pages: number;
    }
  }> => {
    try {
      // Validate MongoDB ObjectId for customer
      if (params.customer) {
        // MongoDB ObjectIds are 24 characters long
        if (typeof params.customer === 'string' && params.customer.length !== 24) {
          console.warn(`Invalid customer ID format: ${params.customer} (length: ${params.customer.length})`);
          throw new Error('Invalid customer ID format: must be 24 characters');
        }
      }

      const token = localStorage.getItem('token');
      const queryString = new URLSearchParams();
      Object.entries(params).forEach(([key, value]) => {
        if (value !== undefined) {
          queryString.append(key, value.toString());
        }
      });

      // Log the full query string to help debug
      console.log(`Searching loans with query: /search?${queryString.toString()}`);
      console.log('Search parameters:', params);

      const response = await api.get<ApiResponse<{
        loans: Loan[];
        pagination: {
          total: number;
          page: number;
          pages: number;
        }
      }>>(`/search?${queryString}`, {
        headers: {
          'x-auth-token': token
        }
      });
      
      if (!response.data.success) {
        throw new Error('Failed to search loans');
      }
      
      // Add validation for expected response structure
      if (!response.data.data?.loans) {
        console.error('Invalid response structure:', response.data);
        throw new Error('Invalid response: loans array missing');
      }
      
      return response.data.data;
    } catch (error) {
      // Enhanced error handling with better logging
      console.error('Error in searchLoans:', error);
      
      if (axios.isAxiosError(error)) {
        // Log detailed information about the Axios error
        console.error('Axios error details:', {
          status: error.response?.status,
          statusText: error.response?.statusText,
          data: error.response?.data,
          config: {
            url: error.config?.url,
            method: error.config?.method,
            params: error.config?.params
          }
        });
        
        // Provide more specific error messages based on status code
        if (error.response?.status === 400) {
          throw new Error(error.response.data?.error || 'Invalid search parameters');
        } else if (error.response?.status === 401) {
          throw new Error('Authentication required - please log in again');
        } else if (error.response?.status === 404) {
          throw new Error('Loan search endpoint not found');
        } else if (error.response?.status === 500) {
          throw new Error('Server error processing loan search');
        }
        
        throw new Error(error.response?.data?.error || 'Failed to search loans');
      }
      
      // For non-Axios errors, provide the error message if available
      if (error instanceof Error) {
        throw new Error(`Loan search failed: ${error.message}`);
      }
      
      throw new Error('Unknown error occurred during loan search');
    }
  }
};

interface CreateLoanResponse {
  loan: Loan;
  nextVoucherNumber: number;
}
