import React, { createContext, useState, useEffect, useContext } from 'react';
import { ThemeMode } from '../styles/theme';

interface ThemeContextType {
  theme: ThemeMode;
  setTheme: (theme: ThemeMode) => void;
}

// Create context with undefined default value
const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Get saved theme from localStorage or use default
  const [theme, setTheme] = useState<ThemeMode>(() => {
    // Check if we're in the browser environment
    if (typeof window !== 'undefined') {
      const savedTheme = localStorage.getItem('theme');
      // Check if saved theme is a valid ThemeMode
      if (savedTheme && ['light', 'dark', 'gold', 'silver'].includes(savedTheme)) {
        return savedTheme as ThemeMode;
      }
    }
    return 'light'; // Default theme
  });

  // Update localStorage and apply theme class when theme changes
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Save to localStorage
      localStorage.setItem('theme', theme);
      
      // Remove all theme classes and add the current one
      document.documentElement.classList.remove('light', 'dark', 'gold', 'silver');
      document.documentElement.classList.add(theme);
    }
  }, [theme]);

  // Check for system preference on initial load
  useEffect(() => {
    // Only check if the user hasn't already set a preference
    if (typeof window !== 'undefined' && !localStorage.getItem('theme')) {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      if (prefersDark) {
        setTheme('dark');
      }
    }
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

// Custom hook for using the theme context
export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};