import React, { createContext, useContext, useReducer, ReactNode } from 'react';
import { LoanRepayment, RepaymentItem } from '../types/repayment';
import { Customer } from '../../loanIssue/types/loan';

export interface RepaymentContextState {
  repayment: LoanRepayment;
  isLoading: boolean;
  error: string | null;
  isDirty: boolean;
  isInitialized: boolean;
  isPreviousRepaymentsVisible: boolean;
  customers: Customer[];
  filteredCustomers: Customer[];
  isCustomerSearchFocused: boolean;
  isLoanSearchFocused: boolean;
}

type RepaymentAction =
  | { type: 'SET_REPAYMENT'; payload: LoanRepayment }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_ERROR'; payload: string | null }
  | { type: 'SET_DIRTY'; payload: boolean }
  | { type: 'SET_INITIALIZED'; payload: boolean }
  | { type: 'SET_PREVIOUS_REPAYMENTS_VISIBLE'; payload: boolean }
  | { type: 'UPDATE_REPAYMENT_ITEMS'; payload: RepaymentItem[] }
  | { type: 'RESET_REPAYMENT'; payload: { voucherNumber: number } }
  | { type: 'SET_CUSTOMERS'; payload: Customer[] }
  | { type: 'SET_FILTERED_CUSTOMERS'; payload: Customer[] }
  | { type: 'SET_CUSTOMER_SEARCH_FOCUS'; payload: boolean }
  | { type: 'SET_LOAN_SEARCH_FOCUS'; payload: boolean };

const getInitialRepaymentState = (voucherNumber = 1): LoanRepayment => ({
  customer: '',
  selectedCustomer: '',
  date: new Date(),
  voucherNumber,
  repaymentItems: []
});

const initialState: RepaymentContextState = {
  repayment: getInitialRepaymentState(),
  isLoading: false,
  error: null,
  isDirty: false,
  isInitialized: false,
  isPreviousRepaymentsVisible: false,
  customers: [],
  filteredCustomers: [],
  isCustomerSearchFocused: false,
  isLoanSearchFocused: false
};

const repaymentReducer = (state: RepaymentContextState, action: RepaymentAction): RepaymentContextState => {
  switch (action.type) {
    case 'SET_REPAYMENT':
      return { 
        ...state, 
        repayment: {
          ...action.payload,
          // Ensure repaymentItems is always an array
          repaymentItems: Array.isArray(action.payload.repaymentItems) 
            ? action.payload.repaymentItems 
            : []
        },
        isDirty: true,
        error: null // Clear any previous errors
      };

    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };

    case 'SET_ERROR':
      return { ...state, error: action.payload };

    case 'SET_DIRTY':
      return { ...state, isDirty: action.payload };

    case 'SET_INITIALIZED':
      return { ...state, isInitialized: action.payload };

    case 'SET_PREVIOUS_REPAYMENTS_VISIBLE':
      return { ...state, isPreviousRepaymentsVisible: action.payload };

    case 'UPDATE_REPAYMENT_ITEMS':
      return {
        ...state,
        repayment: {
          ...state.repayment,
          repaymentItems: action.payload
        },
        isDirty: true,
        error: null // Clear any previous errors
      };

    case 'RESET_REPAYMENT':
      return {
        ...state,
        repayment: getInitialRepaymentState(action.payload.voucherNumber),
        isDirty: false,
        error: null // Clear any previous errors
      };

    case 'SET_CUSTOMERS':
      return { 
        ...state, 
        customers: action.payload,
        error: null // Clear any previous errors
      };

    case 'SET_FILTERED_CUSTOMERS':
      return { 
        ...state, 
        filteredCustomers: action.payload,
        error: null // Clear any previous errors
      };

    case 'SET_CUSTOMER_SEARCH_FOCUS':
      return { ...state, isCustomerSearchFocused: action.payload };
      
    case 'SET_LOAN_SEARCH_FOCUS':
      return { ...state, isLoanSearchFocused: action.payload };

    default:
      return state;
  }
};

// Export action creators
export const repaymentActions = {
  setRepayment: (repayment: LoanRepayment) => ({ 
    type: 'SET_REPAYMENT' as const, 
    payload: repayment 
  }),
  setLoading: (isLoading: boolean) => ({ 
    type: 'SET_LOADING' as const, 
    payload: isLoading 
  }),
  setError: (error: string | null) => ({ 
    type: 'SET_ERROR' as const, 
    payload: error 
  }),
  setDirty: (isDirty: boolean) => ({ 
    type: 'SET_DIRTY' as const, 
    payload: isDirty 
  }),
  setInitialized: (isInitialized: boolean) => ({ 
    type: 'SET_INITIALIZED' as const, 
    payload: isInitialized 
  }),
  setPreviousRepaymentsVisible: (visible: boolean) => ({ 
    type: 'SET_PREVIOUS_REPAYMENTS_VISIBLE' as const, 
    payload: visible 
  }),
  updateRepaymentItems: (items: RepaymentItem[]) => ({ 
    type: 'UPDATE_REPAYMENT_ITEMS' as const, 
    payload: items 
  }),
  resetRepayment: (voucherNumber: number) => ({ 
    type: 'RESET_REPAYMENT' as const, 
    payload: { voucherNumber } 
  }),
  setCustomers: (customers: Customer[]) => ({
    type: 'SET_CUSTOMERS' as const,
    payload: customers
  }),
  setFilteredCustomers: (customers: Customer[]) => ({
    type: 'SET_FILTERED_CUSTOMERS' as const,
    payload: customers
  }),
  setCustomerSearchFocus: (focused: boolean) => ({
    type: 'SET_CUSTOMER_SEARCH_FOCUS' as const,
    payload: focused
  }),
  setLoanSearchFocus: (focused: boolean) => ({
    type: 'SET_LOAN_SEARCH_FOCUS' as const,
    payload: focused
  })
};

interface RepaymentContextValue {
  state: RepaymentContextState;
  dispatch: React.Dispatch<RepaymentAction>;
}

const RepaymentContext = createContext<RepaymentContextValue | undefined>(undefined);

export const RepaymentProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(repaymentReducer, initialState);

  return (
    <RepaymentContext.Provider value={{ state, dispatch }}>
      {children}
    </RepaymentContext.Provider>
  );
};

export const useRepaymentContext = () => {
  const context = useContext(RepaymentContext);
  if (!context) {
    throw new Error('useRepaymentContext must be used within a RepaymentProvider');
  }
  return context;
};
