import React, { useState, useEffect } from 'react';
import { LoanRepayment } from '../types/repayment';
import { repaymentApi } from '../api/repaymentApi';
import { useRepaymentContext, repaymentActions } from '../contexts/RepaymentContext';
import { Button } from '../../../styles/components';

interface SlidingRepaymentHistoryProps {
  onEdit: (repaymentId: string) => void;
}

export const SlidingRepaymentHistory: React.FC<SlidingRepaymentHistoryProps> = ({
  onEdit,
}) => {
  const { state, dispatch } = useRepaymentContext();
  const [repayments, setRepayments] = useState<LoanRepayment[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleClose = () => {
    dispatch(repaymentActions.setPreviousRepaymentsVisible(false));
  };

  useEffect(() => {
    const loadRepayments = async () => {
      // Only load data when panel is visible
      if (!state.isPreviousRepaymentsVisible) return;
      
      try {
        setLoading(true);
        setError(null);
        const response = await repaymentApi.fetchRepayments();
        
        if (response && response.repayments) {
          // Sort repayments by voucher number in descending order
          const sortedRepayments = [...response.repayments].sort(
            (a, b) => b.voucherNumber - a.voucherNumber
          );
          setRepayments(sortedRepayments);
        } else {
          throw new Error('Failed to load repayments');
        }
      } catch (err) {
        setError('Failed to load repayments');
        console.error('Error loading repayments:', err);
      } finally {
        setLoading(false);
      }
    };

    loadRepayments();
  }, [state.isPreviousRepaymentsVisible]); // Only re-run when visibility changes

  const handleDelete = async (repaymentId: string) => {
    if (!window.confirm('Are you sure you want to delete this repayment?')) {
      return;
    }

    try {
      dispatch(repaymentActions.setLoading(true));
      await repaymentApi.deleteRepayment(repaymentId);
      setRepayments(prevRepayments => prevRepayments.filter(repayment => repayment._id !== repaymentId));
    } catch (err) {
      setError('Failed to delete repayment');
      console.error('Error deleting repayment:', err);
    } finally {
      dispatch(repaymentActions.setLoading(false));
    }
  };

  // Don't render anything if panel is not visible
  if (!state.isPreviousRepaymentsVisible) return null;

  return (
    <div className="fixed inset-0 z-50 flex">
      {/* Darkened overlay */}
      <div 
        className="absolute inset-0 bg-black bg-opacity-30"
        onClick={handleClose}
      ></div>
      
      {/* Sliding panel */}
      <div 
        className="absolute top-0 bottom-0 right-0 w-full md:w-2/3 lg:w-1/2 xl:w-1/3 bg-theme-primary shadow-theme-high transition-transform transform animate-slide-in-right overflow-hidden flex flex-col"
        style={{
          animation: 'slideInRight 0.3s ease-out'
        }}
      >
        {/* Header */}
        <div className="flex justify-between items-center px-4 py-3 border-b border-theme">
          <h2 className="font-bold text-theme-primary text-lg">Payment History</h2>
          <button
            onClick={handleClose}
            className="text-theme-tertiary hover:text-theme-primary transition-colors p-1"
            type="button"
            aria-label="Close"
          >
            <svg
              className="h-5 w-5"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        
        {/* Content */}
        <div className="flex-grow overflow-auto p-4">
          {loading && (
            <div className="flex justify-center items-center py-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-accent"></div>
            </div>
          )}
          
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded-md mb-4">
              {error}
            </div>
          )}
          
          {!loading && !error && repayments.length === 0 && (
            <div className="text-center py-8 text-theme-secondary">
              <p>No payment history found</p>
            </div>
          )}
          
          {repayments.length > 0 && (
            <div className="space-y-3">
              {repayments.map((repayment) => (
                <div 
                  key={repayment._id} 
                  className="border border-theme rounded-lg overflow-hidden bg-white hover:bg-theme-highlight transition-colors"
                >
                  <div className="p-3">
                    <div className="flex justify-between items-start">
                      <div>
                        <div className="flex items-center">
                          <span className="font-medium text-theme-primary">
                            Voucher #{repayment.voucherNumber}
                          </span>
                          <span className="ml-2 text-sm text-theme-secondary">
                            {new Date(repayment.date).toLocaleDateString()}
                          </span>
                        </div>
                        <div className="text-theme-secondary mt-1">
                          {repayment.selectedCustomer}
                        </div>
                      </div>
                      <div className="text-right">
                        <div className="font-medium text-accent">
                          ₹{repayment.repaymentItems.reduce((sum, item) => sum + item.amountPayable, 0).toFixed(2)}
                        </div>
                        <div className="text-sm text-theme-secondary">
                          {repayment.repaymentItems.length} item(s)
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end mt-2 space-x-2">
                      <Button
                        type="button"
                        onClick={() => {
                          if (repayment._id) {
                            onEdit(repayment._id);
                            handleClose();
                          }
                        }}
                        variant="ghost"
                        size="sm"
                      >
                        Edit
                      </Button>
                      <Button
                        type="button"
                        onClick={() => repayment._id && handleDelete(repayment._id)}
                        variant="danger"
                        size="sm"
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Add this to your global CSS
// @keyframes slideInRight {
//   from {
//     transform: translateX(100%);
//   }
//   to {
//     transform: translateX(0);
//   }
// }