import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';

interface UserMenuProps {
  username: string;
  onLogout: () => void;
}

const UserMenu: React.FC<UserMenuProps> = ({ username, onLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { theme } = useTheme(); // Access the current theme

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleAccount = () => {
    navigate('/account-settings');
    setIsOpen(false);
  };

  const handleSettings = () => {
    // Navigate to settings page
    setIsOpen(false);
  };

  const handleSignOut = () => {
    setIsOpen(false);
    onLogout();
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 bg-accent hover:bg-accent-hover text-white font-bold py-2 px-4 rounded-md transition-colors"
        aria-label="User menu"
        aria-expanded={isOpen}
      >
        <span>{username}</span>
        <svg
          className={`w-4 h-4 transition-transform ${isOpen ? 'rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-theme-primary border border-theme rounded-md shadow-theme-medium py-1 z-50">
          <button
            onClick={handleAccount}
            className="block w-full text-left px-4 py-2 text-sm text-theme-primary hover:bg-theme-secondary transition-colors"
          >
            Account
          </button>
          <button
            onClick={handleSettings}
            className="block w-full text-left px-4 py-2 text-sm text-theme-primary hover:bg-theme-secondary transition-colors"
          >
            Settings
          </button>
          <div className="border-t border-theme"></div>
          <button
            onClick={handleSignOut}
            className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-theme-secondary transition-colors"
          >
            Sign out
          </button>
        </div>
      )}
    </div>
  );
};

export default UserMenu;
