import React, { useRef } from 'react';
import { LoanItem, SelectedItem } from '../../types/loan';
import { ItemSearch } from '../ItemSearch';
import { isValidDecimalInput } from '../../utils/calculations';

interface LoanItemsProps {
  items: LoanItem[];
  onItemChange: (index: number, field: keyof LoanItem, value: string) => void;
  onItemSelect: (index: number, selectedItem: SelectedItem) => void;
  onAddItem?: () => void;
}

export const LoanItems: React.FC<LoanItemsProps> = ({
  items,
  onItemChange,
  onItemSelect,
  onAddItem
}) => {
  const lastItemRowRef = useRef<HTMLTableRowElement>(null);

  const handleNumberChange = (index: number, field: keyof LoanItem, value: string) => {
    if (isValidDecimalInput(value)) {
      onItemChange(index, field, value);
    }
  };

  const getItemId = (itemId: string | { _id: string; name: string; type: string }): string => {
    if (typeof itemId === 'string') return itemId;
    return itemId._id || '';
  };

  const getItemType = (itemId: string | { _id: string; name: string; type: string }): string => {
    if (typeof itemId === 'object' && itemId.type) {
      return itemId.type;
    }
    return '';
  };

  const inputClass = "w-full px-1 border-0 focus:outline-none focus:ring-0 min-h-0 leading-none";

  return (
    <div className="mb-4">
      <h3 className="text-lg font-semibold mb-2">Loan Items</h3>
      <div className="overflow-x-auto">
        <table className="w-full mb-4 border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-200">
              <th className="border border-gray-300 p-0 w-10 text-center">Sr.</th>
              <th className="border border-gray-300 p-0 w-24">Tag no.</th>
              <th className="border border-gray-300 p-0 min-w-[300px]">Item name</th>
              <th className="border border-gray-300 p-0 w-16">Pcs</th>
              <th className="border border-gray-300 p-0 w-24">Gross Wt</th>
              <th className="border border-gray-300 p-0 w-24">Less Wt</th>
              <th className="border border-gray-300 p-0 w-24">Net Wt</th>
              <th className="border border-gray-300 p-0 w-24">Tunch</th>
              <th className="border border-gray-300 p-0 w-24">Wstg</th>
              <th className="border border-gray-300 p-0 w-24">Fine</th>
              <th className="border border-gray-300 p-0 w-24">Rate</th>
              <th className="border border-gray-300 p-0 w-28">Amount</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index} ref={index === items.length - 1 ? lastItemRowRef : null}>
                <td className="border border-gray-300 p-0 text-center">{index + 1}</td>
                <td className="border border-gray-300 p-0">
                  <input
                    type="text"
                    value={item.tagNo || ''}
                    onChange={(e) => onItemChange(index, 'tagNo', e.target.value)}
                    className={inputClass}
                  />
                </td>
                <td className="border border-gray-300 p-0">
                  <ItemSearch
                    selectedItem={{
                      itemId: getItemId(item.itemId),
                      itemNameWithType: typeof item.itemNameWithType === 'string' ? item.itemNameWithType : '',
                      type: getItemType(item.itemId)
                    }}
                    onItemSelect={(selectedItem) => onItemSelect(index, selectedItem)}
                  />
                </td>
                <td className="border border-gray-300 p-0">
                  <input
                    type="text"
                    value={item.pcs}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || /^\d+$/.test(value)) {
                        onItemChange(index, 'pcs', value);
                      }
                    }}
                    className={inputClass}
                  />
                </td>
                <td className="border border-gray-300 p-0">
                  <input
                    type="text"
                    value={item.grossWt}
                    onChange={(e) => handleNumberChange(index, 'grossWt', e.target.value)}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        handleNumberChange(index, 'grossWt', '0');
                      }
                    }}
                    className={inputClass}
                  />
                </td>
                <td className="border border-gray-300 p-0">
                  <input
                    type="text"
                    value={item.lessWt}
                    onChange={(e) => handleNumberChange(index, 'lessWt', e.target.value)}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        handleNumberChange(index, 'lessWt', '0');
                      }
                    }}
                    className={inputClass}
                  />
                </td>
                <td className="border border-gray-300 p-0 text-right px-1">
                  {item.netWt.toFixed(3)}
                </td>
                <td className="border border-gray-300 p-0">
                  <input
                    type="text"
                    value={item.tunch}
                    onChange={(e) => handleNumberChange(index, 'tunch', e.target.value)}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        handleNumberChange(index, 'tunch', '0');
                      }
                    }}
                    className={inputClass}
                  />
                </td>
                <td className="border border-gray-300 p-0">
                  <input
                    type="text"
                    value={item.wstg}
                    onChange={(e) => handleNumberChange(index, 'wstg', e.target.value)}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        handleNumberChange(index, 'wstg', '0');
                      }
                    }}
                    className={inputClass}
                  />
                </td>
                <td className="border border-gray-300 p-0 text-right px-1">
                  {item.fine.toFixed(3)}
                </td>
                <td className="border border-gray-300 p-0">
                  <input
                    type="text"
                    value={item.rate}
                    onChange={(e) => handleNumberChange(index, 'rate', e.target.value)}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        handleNumberChange(index, 'rate', '0');
                      }
                    }}
                    className={inputClass}
                  />
                </td>
                <td className="border border-gray-300 p-0 text-right px-1">
                  {item.amount.toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {onAddItem && (
        <button
          type="button"
          onClick={onAddItem}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline"
        >
          +
        </button>
      )}
    </div>
  );
};
