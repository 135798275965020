import React, { useEffect } from 'react';
import { dateUtils } from '../utils/dateUtils';
import {
  formatInterestDetails,
  getRemainingPrincipalDescription,
  isFirstPayment,
  formatCurrency
} from '../utils/loanCalculations';

interface Payment {
  issueDate: string | Date;
  originalPrincipal: number;
  remainingPrincipalOld: number; // Principal amount before payment
  remainingPrincipalNew: number; // Principal amount after payment
  lastPaymentDate: string | Date | null;
  interest: number;
  interestDetails: string;
  amountPayable: number;         // Renamed from totalAmount
  rounding: number;              // Renamed from roundOff
  lastCompoundDate?: string | Date | null;
  numberOfDays?: number;
  interestPercent?: string;
  amountPaid?: number;
}

interface PaymentModeTableProps {
  mode: 'full' | 'interest_only' | 'custom';
  payment: Payment;
  onCustomAmountChange?: (amount: string) => void;
  onRoundingChange: (amount: string) => void;
  onDetailsClick: () => void;
  customAmount?: string;
  // Add new prop for notifying parent of new remaining principal amount
  onRemainingPrincipalNewChange?: (remainingPrincipalNew: number) => void;
}

export const PaymentModeTable: React.FC<PaymentModeTableProps> = ({
  mode,
  payment,
  onCustomAmountChange,
  onRoundingChange,
  onDetailsClick,
  customAmount = '',
  onRemainingPrincipalNewChange,
}) => {
  // Format date functions
  const formatDate = (date: string | Date | null) => {
    if (!date) return '--';
    return typeof date === 'string' ? dateUtils.formatDate(new Date(date)) : dateUtils.formatDate(date);
  };

  // Calculate remaining principal percentage
  const getRemainingPercent = () => {
    if (!payment.originalPrincipal) return '0%';
    const percent = Math.round((payment.remainingPrincipalOld / payment.originalPrincipal) * 100);
    return `${percent}%`;
  };

  // For area 1 in the UI, we should NOT dynamically calculate the Remaining Principal
  // This value should be static from the payment data and not change as user types
  const getTableRemainingPrincipal = () => {
    return payment.remainingPrincipalOld;
  };
  
  // This function now only calculates the projected remaining principal
  // for the summary section (area 2) but is NOT used in the table display
  const calculateRemainingPrincipalNew = () => {
    if (mode !== 'custom' || customAmount === undefined || customAmount === '') {
      // For non-custom modes or when custom amount is not set
      if (mode === 'full') {
        return 0; // Full payment always results in zero remaining
      } else if (mode === 'interest_only') {
        // Interest-only keeps principal unchanged
        return payment.remainingPrincipalOld;
      } else {
        // Default to passed in value
        return payment.remainingPrincipalOld;
      }
    }
    
    // In custom mode, calculate how much remains after payment
    const customAmountValue = parseFloat(customAmount) || 0;
    const interestValue = payment.interest || 0;
    
    // If custom amount is less than interest, principal remains unchanged
    if (customAmountValue <= interestValue) {
      return payment.remainingPrincipalOld;
    }
    
    // Otherwise, calculate how much principal is paid
    const principalPayment = customAmountValue - interestValue;
    
    // Calculate remaining principal based on old remaining principal minus principal payment
    return Math.max(0, payment.remainingPrincipalOld - principalPayment);
  };

  // Add useEffect to notify parent when new remaining principal amount changes
  useEffect(() => {
    if (onRemainingPrincipalNewChange) {
      const newRemainingPrincipal = calculateRemainingPrincipalNew();
      onRemainingPrincipalNewChange(newRemainingPrincipal);
    }
  }, [customAmount, mode, payment.interest, payment.originalPrincipal]);
  // Removed onProjectedRemainingChange from dependencies to prevent infinite loops

  // Calculate dynamic principal payment percentage for all modes
  const getDynamicPrincipalPercentage = () => {
    if (!payment.originalPrincipal) return '0%';
    
    // Always use projected calculation for the summary
    const dynamicRemaining = calculateRemainingPrincipalNew();
    const percent = Math.round((dynamicRemaining / payment.originalPrincipal) * 100);
    
    return `${percent}%`;
  };

  // Get formatted interest details with proper time period
  const getFormattedInterestDetails = () => {
    if (payment.interestDetails) {
      return payment.interestDetails;
    }
    
    if (payment.numberOfDays && payment.interestPercent) {
      return formatInterestDetails(payment.interestPercent, payment.numberOfDays);
    }
    
    return ''; // Fallback to empty string if no data available
  };

  // Conditionally render last payment info
  const renderLastPaymentCell = () => {
    
    // Check if this is a first payment
    const firstPayment = isFirstPayment(payment.lastPaymentDate);
    const formattedDate = formatDate(payment.lastPaymentDate);
    
    return (
      <>
        <div className="font-medium">{formattedDate}</div>
        <div className="text-xs text-theme-tertiary">
          {firstPayment ? '(First payment)' : '(Last paid)'}
        </div>
      </>
    );
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full border-collapse border border-theme-highlight bg-theme-secondary rounded-lg shadow-sm">
        <thead>
          <tr className="bg-theme border-b border-theme-highlight">
            <th className="py-3 px-4 text-xs font-medium text-left text-theme-tertiary uppercase">
              Issue Date
            </th>
            <th className="py-3 px-4 text-xs font-medium text-right text-theme-tertiary uppercase">
              Original<br/>Principal
            </th>
            <th className="py-3 px-4 text-xs font-medium text-right text-theme-tertiary uppercase">
              Remaining<br/>Principal (Old)
            </th>
            <th className="py-3 px-4 text-xs font-medium text-left text-theme-tertiary uppercase">
              Last Payment
            </th>
            <th className="py-3 px-4 text-xs font-medium text-center text-theme-tertiary uppercase">
              Details
            </th>
            <th className="py-3 px-4 text-xs font-medium text-right text-theme-tertiary uppercase">
              Interest
            </th>

            <th className="py-3 px-4 text-xs font-medium text-right text-theme-tertiary uppercase">
              Amount Payable
            </th>

            <th className="py-3 px-4 text-xs font-medium text-right text-theme-tertiary uppercase">
              Rounding
            </th>

            <th className="py-3 px-4 text-xs font-medium text-right text-theme-tertiary uppercase">
              Amount Paid
            </th>

            <th className="py-3 px-4 text-xs font-medium text-right text-theme-tertiary uppercase">
              Remaining<br/>Principal (New)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-b border-theme-highlight">
            <td className="py-3 px-4 text-theme-primary">
              {formatDate(payment.issueDate)}
            </td>
            
            <td className="py-3 px-4 text-right text-theme-primary">
              {formatCurrency(payment.originalPrincipal)}
            </td>
            
            <td className="py-3 px-4 text-right">
              <div className="text-theme-primary">
                {/* Always show the actual remaining principal from payment history */}
                {formatCurrency(getTableRemainingPrincipal())}
              </div>
            </td>
            
            <td className="py-3 px-4 text-theme-primary">
              {renderLastPaymentCell()}
            </td>
            <td className="py-3 px-4 text-center">
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onDetailsClick();
                }}
                className="px-3 py-1 bg-accent text-white rounded text-sm hover:bg-accent-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent"
              >
                Details
              </button>
            </td>
            
            <td className="py-3 px-4 text-right">
              <div className="text-theme-primary">{formatCurrency(payment.interest)}</div>
              <div className="text-xs text-theme-tertiary">{getFormattedInterestDetails()}</div>
            </td>
            
            {/* Conditional columns based on mode */}
            {mode === 'full' && (
              <td className="py-3 px-4 text-right text-theme-primary font-semibold">
                {formatCurrency(payment.amountPayable)}
              </td>
            )}
            
            {mode === 'interest_only' && (
              <td className="py-3 px-4 text-right text-theme-primary font-semibold">
                {formatCurrency(payment.amountPayable)}
              </td>
            )}
            
            {mode === 'custom' && (
              <td className="py-3 px-4 text-right">
                <div className="flex items-center justify-end">
                  <span className="mr-1">₹</span>
                  <input
                    type="text"
                    value={customAmount}
                    onChange={(e) => {
                      // Call the parent's onChange handler with the new value
                      if (onCustomAmountChange) {
                        onCustomAmountChange(e.target.value);
                      }
                      // Removed direct projected remaining calculation and notification
                      // This will now happen through the useEffect hook to prevent duplicate updates
                    }}
                    className="w-24 p-1 text-right border border-theme-highlight rounded focus:outline-none focus:ring-1 focus:ring-accent"
                    placeholder="Amount"
                  />
                </div>
              </td>
            )}
            <td className="py-3 px-4 text-right">
              <div className="flex items-center justify-end">
                <span className="mr-1">₹</span>
                <input
                  type="text"
                  value={payment.rounding?.toString() || '0'}
                  onChange={(e) => onRoundingChange(e.target.value)}
                  className="w-24 p-1 text-right border border-theme-highlight rounded focus:outline-none focus:ring-1 focus:ring-accent"
                  placeholder="0.00"
                />
              </div>
            </td>

            <td className="py-3 px-4 text-right text-theme-primary font-semibold">
              {formatCurrency(payment.amountPaid !== undefined ? payment.amountPaid : (payment.amountPayable - payment.rounding))}
            </td>
          
          <td className="py-3 px-4 text-right">
              <div className={`font-medium ${calculateRemainingPrincipalNew() > 0 ? 'text-amber-600' : 'text-green-600'}`}>
                {formatCurrency(calculateRemainingPrincipalNew())}
              </div>
              {mode === 'full' && (
                <div className="text-green-600 text-xs">(Loan Closed)</div>
              )}
              {mode === 'interest_only' && (
                <div className="text-blue-600 text-xs">(Principal Unchanged)</div>
              )}
              {mode === 'custom' && (
                <div className="text-indigo-600 text-xs">
                  (Partial Payment)
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};