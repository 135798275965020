import React, { createContext, useContext, useReducer, ReactNode } from 'react';
import { Loan, Customer, DEFAULT_LOAN_ITEM } from '../types/loan';

export interface LoanContextState {
  loan: Loan;
  isLoading: boolean;
  error: string | null;
  isDirty: boolean;
  customers: Customer[];
  filteredCustomers: Customer[];
  isCustomerSearchFocused: boolean;
  isPreviousLoansVisible: boolean;
  isInitialized: boolean;
}

type LoanAction = 
  | { type: 'SET_LOAN'; payload: Loan }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_ERROR'; payload: string | null }
  | { type: 'SET_CUSTOMERS'; payload: Customer[] }
  | { type: 'SET_FILTERED_CUSTOMERS'; payload: Customer[] }
  | { type: 'SET_CUSTOMER_SEARCH_FOCUS'; payload: boolean }
  | { type: 'SET_DIRTY'; payload: boolean }
  | { type: 'UPDATE_LOAN_ITEMS'; payload: Loan['items'] }
  | { type: 'SET_PREVIOUS_LOANS_VISIBLE'; payload: boolean }
  | { type: 'SET_INITIALIZED'; payload: boolean }
  | { type: 'UPDATE_TOTALS'; payload: Pick<Loan, 
      'amount' | 
      'monthlyInterestPercent'
    > };

const getInitialLoanState = (voucherNumber = 1): Loan => ({
  customer: '',
  selectedCustomer: '',
  date: new Date(),
  voucherNumber,
  items: [{ ...DEFAULT_LOAN_ITEM }],
  amount: 0,
  monthlyInterestPercent: '0',
  note: ''
});

const initialState: LoanContextState = {
  loan: getInitialLoanState(),
  isLoading: false,
  error: null,
  isDirty: false,
  customers: [],
  filteredCustomers: [],
  isCustomerSearchFocused: false,
  isPreviousLoansVisible: false,
  isInitialized: false
};

const loanReducer = (state: LoanContextState, action: LoanAction): LoanContextState => {
  switch (action.type) {
    case 'SET_LOAN': {
      const loan = action.payload;
      return { 
        ...state, 
        loan: {
          ...loan,
          items: loan.items?.map(item => ({ ...DEFAULT_LOAN_ITEM, ...item })) || [{ ...DEFAULT_LOAN_ITEM }],
          monthlyInterestPercent: loan.monthlyInterestPercent || '0',
          note: loan.note || '',
          voucherNumber: loan.voucherNumber || state.loan.voucherNumber
        },
        isDirty: true 
      };
    }
    
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    
    case 'SET_CUSTOMERS':
      return { 
        ...state, 
        customers: action.payload || [],
        isInitialized: true
      };
    
    case 'SET_FILTERED_CUSTOMERS':
      return { ...state, filteredCustomers: action.payload || [] };
    
    case 'SET_CUSTOMER_SEARCH_FOCUS':
      return { ...state, isCustomerSearchFocused: action.payload };
    
    case 'SET_DIRTY':
      return { ...state, isDirty: action.payload };
    
    case 'UPDATE_LOAN_ITEMS': {
      const items = action.payload;
      const updatedItems = items.map(item => ({
        ...DEFAULT_LOAN_ITEM,
        ...state.loan.items.find(i => i === item) || {},
        ...item
      }));
      
      return {
        ...state,
        loan: {
          ...state.loan,
          items: updatedItems.length > 0 ? updatedItems : [{ ...DEFAULT_LOAN_ITEM }]
        },
        isDirty: true
      };
    }

    case 'SET_PREVIOUS_LOANS_VISIBLE':
      return {
        ...state,
        isPreviousLoansVisible: action.payload
      };

    case 'SET_INITIALIZED':
      return {
        ...state,
        isInitialized: action.payload
      };

    case 'UPDATE_TOTALS': {
      const totals = action.payload;
      return {
        ...state,
        loan: {
          ...state.loan,
          ...totals
        },
        isDirty: true
      };
    }
    
    default:
      return state;
  }
};

interface LoanContextValue {
  state: LoanContextState;
  dispatch: React.Dispatch<LoanAction>;
}

const LoanContext = createContext<LoanContextValue | undefined>(undefined);

export const LoanProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(loanReducer, initialState);

  return (
    <LoanContext.Provider value={{ state, dispatch }}>
      {children}
    </LoanContext.Provider>
  );
};

export const useLoanContext = () => {
  const context = useContext(LoanContext);
  if (!context) {
    throw new Error('useLoanContext must be used within a LoanProvider');
  }
  return context;
};

export const loanActions = {
  setLoan: (loan: Loan) => ({ type: 'SET_LOAN' as const, payload: loan }),
  setLoading: (isLoading: boolean) => ({ type: 'SET_LOADING' as const, payload: isLoading }),
  setError: (error: string | null) => ({ type: 'SET_ERROR' as const, payload: error }),
  setCustomers: (customers: Customer[]) => ({ type: 'SET_CUSTOMERS' as const, payload: customers }),
  setFilteredCustomers: (customers: Customer[]) => ({ type: 'SET_FILTERED_CUSTOMERS' as const, payload: customers }),
  setCustomerSearchFocus: (focused: boolean) => ({ type: 'SET_CUSTOMER_SEARCH_FOCUS' as const, payload: focused }),
  setDirty: (isDirty: boolean) => ({ type: 'SET_DIRTY' as const, payload: isDirty }),
  updateLoanItems: (items: Loan['items']) => ({ type: 'UPDATE_LOAN_ITEMS' as const, payload: items }),
  setPreviousLoansVisible: (visible: boolean) => ({ type: 'SET_PREVIOUS_LOANS_VISIBLE' as const, payload: visible }),
  setInitialized: (initialized: boolean) => ({ type: 'SET_INITIALIZED' as const, payload: initialized }),
  updateTotals: (totals: Pick<Loan, 
    'amount' | 
    'monthlyInterestPercent'
  >) => ({ type: 'UPDATE_TOTALS' as const, payload: totals })
};

export { getInitialLoanState };
