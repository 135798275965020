import React from 'react';

interface CardProps {
  children: React.ReactNode;
  title?: string;
  className?: string;
  footer?: React.ReactNode;
}

/**
 * A themed card component that adapts to the current theme
 * 
 * Usage:
 * ```tsx
 * <Card title="Card Title">
 *   <p>Card content goes here</p>
 * </Card>
 * ```
 * 
 * With footer:
 * ```tsx
 * <Card 
 *   title="Card with Footer" 
 *   footer={<button className="btn-primary">Action</button>}
 * >
 *   <p>Card content goes here</p>
 * </Card>
 * ```
 */
const Card: React.FC<CardProps> = ({ 
  children, 
  title, 
  className = '',
  footer
}) => {
  return (
    <div className={`card ${className}`}>
      {title && (
        <div className="mb-4 pb-2 border-b border-theme">
          <h3 className="text-lg font-semibold text-theme-primary">{title}</h3>
        </div>
      )}
      
      <div>
        {children}
      </div>
      
      {footer && (
        <div className="mt-4 pt-3 border-t border-theme flex justify-end">
          {footer}
        </div>
      )}
    </div>
  );
};

export default Card;