import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CustomerStatementPopup from '../features/CustomerReport/CustomerStatementPopup';

interface Bill {
  _id: string;
  date: string;
  totalAmount: number;
  outstandingAmount: number;
}

interface MoneyReceivedEntry {
  _id: string;
  date: string;
  amount: number;
}

interface CustomerOutstanding {
  _id: string;
  customerName: string;
  totalOutstanding: number;
  totalBillOutstanding: number;
  totalMoneyReceived: number;
  bills: Bill[];
  moneyReceived: MoneyReceivedEntry[];
}

const CustomerReport: React.FC = () => {
  const [outstandingData, setOutstandingData] = useState<CustomerOutstanding[]>([]);
  const [totalOutstanding, setTotalOutstanding] = useState<number>(0);
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerOutstanding | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    fetchOutstandingData();
  }, []);

  const fetchOutstandingData = async () => {
    try {
      const res = await axios.get<{ success: boolean; data: CustomerOutstanding[] }>(`${import.meta.env.VITE_API_URL}/api/customerBills/search/outstanding`);
      setOutstandingData(res.data.data);
      
      // Calculate total outstanding across all customers
      const total = res.data.data.reduce((acc, customer) => acc + customer.totalOutstanding, 0);
      setTotalOutstanding(total);
    } catch (err) {
      console.error('Error fetching outstanding data:', err);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleShowDetails = (customer: CustomerOutstanding) => {
    setSelectedCustomer(customer);
    setIsPopupOpen(true);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold text-gray-900">Customer Report</h1>
          <div className="text-xl font-semibold text-gray-700">
            Total Outstanding: ₹{totalOutstanding.toFixed(2)}
          </div>
        </div>

        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Customer Name
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Total Outstanding
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Details
                </th>
              </tr>
            </thead>
            <tbody>
              {outstandingData.map((customer) => (
                <tr key={customer._id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div className="text-sm leading-5 font-medium text-gray-900">
                      {customer.customerName}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-right">
                    <div className="text-sm leading-5 font-medium text-red-600">
                      ₹{customer.totalOutstanding.toFixed(2)}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-center">
                    <button
                      onClick={() => handleShowDetails(customer)}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      Show Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {selectedCustomer && (
        <CustomerStatementPopup
          isOpen={isPopupOpen}
          onClose={() => {
            setIsPopupOpen(false);
            setSelectedCustomer(null);
          }}
          customerName={selectedCustomer.customerName}
          bills={selectedCustomer.bills}
          moneyReceived={selectedCustomer.moneyReceived}
          totalBillOutstanding={selectedCustomer.totalBillOutstanding}
          totalMoneyReceived={selectedCustomer.totalMoneyReceived}
          totalOutstanding={selectedCustomer.totalOutstanding}
        />
      )}
    </div>
  );
};

export default CustomerReport;
