import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import axios from 'axios';
import { SelectedItem } from '../../types/loan';
import { AuthContext } from '../../../../contexts/AuthContext';
import { useLoanContext } from '../../contexts/LoanContext';

interface ItemSearchProps {
  selectedItem?: SelectedItem;
  onItemSelect: (item: SelectedItem) => void;
}

interface Item {
  _id: string;
  name: string;
  type: string;
}

export const ItemSearch: React.FC<ItemSearchProps> = ({ 
  selectedItem,
  onItemSelect
}) => {
  const auth = useContext(AuthContext);
  const { state } = useLoanContext();
  const [searchTerm, setSearchTerm] = useState(selectedItem?.itemNameWithType || '');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [items, setItems] = useState<Item[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const searchRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (selectedItem?.itemNameWithType) {
      setSearchTerm(selectedItem.itemNameWithType);
    }
  }, [selectedItem]);

  const fetchItems = async (query?: string) => {
    // Don't fetch if not initialized or no auth
    if (!state.isInitialized || !auth?.user) return;

    try {
      setIsLoading(true);
      setError(null);
      const token = localStorage.getItem('token');
      const url = query 
        ? `${import.meta.env.VITE_API_URL}/api/items/search?query=${encodeURIComponent(query)}`
        : `${import.meta.env.VITE_API_URL}/api/items`;
      
      const response = await axios.get(url, {
        headers: {
          'x-auth-token': token
        }
      });

      if (response.data.success) {
        const itemsWithNameType = response.data.data.map((item: Item) => ({
          ...item,
          itemNameWithType: `${item.name} ${item.type}`
        }));
        setItems(itemsWithNameType);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          // Let PrivateRoute handle auth errors
          setError('Authentication error');
          setItems([]);
          return;
        }
        setError(error.response?.data?.error || 'Failed to fetch items');
      } else {
        setError('An unexpected error occurred');
      }
      setItems([]); // Clear items on error
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = async (value: string) => {
    setSearchTerm(value);
    setIsDropdownOpen(true);
    if (value.trim()) {
      await fetchItems(value.trim());
    }
  };

  const handleItemSelect = (item: Item) => {
    const selectedItem: SelectedItem = {
      itemId: item._id,
      itemNameWithType: `${item.name} ${item.type}`,
      type: item.type
    };
    
    // Update local state first
    setSearchTerm(selectedItem.itemNameWithType);
    setIsDropdownOpen(false);
    
    // Then notify parent
    onItemSelect(selectedItem);
  };

  const handleFocus = async () => {
    setIsDropdownOpen(true);
    if (items.length === 0) {
      await fetchItems(searchTerm.trim());
    }
  };

  // Don't render until initialized
  if (!state.isInitialized) {
    return null;
  }

  return (
    <div className="relative" ref={searchRef}>
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => handleSearch(e.target.value)}
        onFocus={handleFocus}
        className="w-full px-2 py-1 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder="Search items by name or type..."
      />

      {isLoading && (
        <div className="absolute right-2 top-2">
          <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-blue-500"></div>
        </div>
      )}

      {error && (
        <div className="text-red-500 mt-1">{error}</div>
      )}

      {isDropdownOpen && items.length > 0 && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-y-auto">
          <div className="divide-y divide-gray-200">
            {items.map((item) => (
              <div
                key={item._id}
                onClick={() => handleItemSelect(item)}
                className="px-2 py-1 hover:bg-gray-100 cursor-pointer"
              >
                {`${item.name} ${item.type}`}
              </div>
            ))}
          </div>
        </div>
      )}

      {isDropdownOpen && searchTerm && items.length === 0 && !isLoading && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md p-4 text-center text-gray-500">
          No items found
        </div>
      )}
    </div>
  );
};
