import React from 'react';
import { OldGoldSilverItem } from '../../types/bill';
import { ItemSearch } from '../../shared/ItemSearch/components';
import { SelectedItem } from '../../shared/ItemSearch/types/item';
import { isValidDecimalInput } from '../../utils/calculations';
import { OldItemStringFields } from '../../hooks/useBillForm';

interface OldItemsTableProps {
  items: OldGoldSilverItem[];
  onItemChange: (index: number, field: OldItemStringFields, value: string) => void;
  onItemSelect: (index: number, selectedItem: SelectedItem) => void;
  onAddItem: () => void;
}

export const OldItemsTable: React.FC<OldItemsTableProps> = ({
  items,
  onItemChange,
  onItemSelect,
  onAddItem,
}) => {
  const handleWeightChange = (index: number, value: string) => {
    if (isValidDecimalInput(value)) {
      onItemChange(index, 'weight', value);
    }
  };

  const handleRateChange = (index: number, value: string) => {
    if (isValidDecimalInput(value)) {
      onItemChange(index, 'rate', value);
    }
  };

  const getItemId = (itemId: string | { _id: string; name: string; type: string }): string => {
    if (typeof itemId === 'string') return itemId;
    return itemId._id || '';
  };

  const getItemType = (itemId: string | { _id: string; name: string; type: string }): string => {
    if (typeof itemId === 'object' && itemId.type) {
      return itemId.type;
    }
    return '';
  };

  const inputClass = "w-full px-1 border-0 focus:outline-none focus:ring-0 min-h-0 leading-none";

  return (
    <div className="mb-4">
      <h3 className="text-lg font-semibold mb-2">Old Items</h3>
      <div className="overflow-x-auto">
        <table className="w-full mb-4 border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-200">
              <th className="border border-gray-300 p-0 w-10 text-center">Sr.</th>
              <th className="border border-gray-300 p-0 w-24">Type</th>
              <th className="border border-gray-300 p-0 min-w-[300px]">Item name</th>
              <th className="border border-gray-300 p-0 w-16">Pcs</th>
              <th className="border border-gray-300 p-0 w-24">Weight</th>
              <th className="border border-gray-300 p-0 w-24">Rate</th>
              <th className="border border-gray-300 p-0 w-28">Amount</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td className="border border-gray-300 p-0 text-center">{index + 1}</td>
                <td className="border border-gray-300 p-0">
                  <select
                    value={item.type}
                    onChange={(e) => onItemChange(index, 'type', e.target.value)}
                    className={inputClass}
                  >
                    <option value="Old Gold">Old Gold</option>
                    <option value="Old Silver">Old Silver</option>
                  </select>
                </td>
                <td className="border border-gray-300 p-0">
                  <ItemSearch
                    selectedItem={{
                      itemId: getItemId(item.itemId),
                      itemNameWithType: typeof item.itemNameWithType === 'string' ? item.itemNameWithType : '',
                      type: getItemType(item.itemId)
                    }}
                    onItemSelect={(selectedItem) => onItemSelect(index, selectedItem)}
                  />
                </td>
                <td className="border border-gray-300 p-0">
                  <input
                    type="text"
                    value={item.pcs}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || /^\d+$/.test(value)) {
                        onItemChange(index, 'pcs', value);
                      }
                    }}
                    className={inputClass}
                  />
                </td>
                <td className="border border-gray-300 p-0">
                  <input
                    type="text"
                    value={item.weight}
                    onChange={(e) => handleWeightChange(index, e.target.value)}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        handleWeightChange(index, '0');
                      }
                    }}
                    className={inputClass}
                  />
                </td>
                <td className="border border-gray-300 p-0">
                  <input
                    type="text"
                    value={item.rate}
                    onChange={(e) => handleRateChange(index, e.target.value)}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        handleRateChange(index, '0');
                      }
                    }}
                    className={inputClass}
                  />
                </td>
                <td className="border border-gray-300 p-0 text-right px-1">
                  {item.amt.toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button
        type="button"
        onClick={onAddItem}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-0.5 px-2 rounded focus:outline-none focus:shadow-outline"
      >
        +
      </button>
    </div>
  );
};
